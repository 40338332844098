import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { YEAR } from "../../Constants";
import { connect } from "react-redux";
import DepartmentList from "./DepartmentList";
import DepartmentUserList from "./DepartmentUserList";
import DepartmentOrphanList from "./DepartmentOrphanList";
import { Button, Card, Row, Col } from "react-bootstrap";
import { get, post } from "axios";
import {
  loadDepartmentList,
  loadUserList,
  clearDirty,
} from "../../store/DepartmentSlice";
import { postError } from "../../store/ApplicationSlice";
import "./DepartmentForm.css";
import { useNavigate } from "react-router-dom";
const mstp = (state, ownProps) => {
  const dirty = state.departmentSlice.dirty;
  const department = state.departmentSlice.department;
  const departmentSelected =
    department.departmentid === undefined ? false : department.name;
  const departmentList = state.departmentSlice.departmentList;
  return { dirty, departmentSelected, departmentList };
};
const mdtp = (dispatch, ownProps) => {
  const _loadDepartmentList = (a) => dispatch(loadDepartmentList(a));
  const _postError = (v) => dispatch(postError(v));
  const _loadUserList = (a) => dispatch(loadUserList(a));
  const _clearDirty = () => dispatch(clearDirty());
  return {
    loadDepartmentList: _loadDepartmentList,
    postError: _postError,
    loadUserList: _loadUserList,
    clearDirty: _clearDirty,
  };
};

const DepartmentMain = (props) => {
  const navigate = useNavigate();
  const {
    dirty,
    departmentSelected,
    loadDepartmentList,
    loadUserList,
    clearDirty,
    departmentList,
  } = props;
  const [showTip, setShowTip] = useState(false);

  useEffect(() => {
    get("api/users")
      .then((res) => {
        loadUserList(res.data);
      })
      .catch((e) => postError(e));
  }, []);

  useEffect(() => {
    get("api/departments/year/2024")
      .then((res) => {
        loadDepartmentList(res.data);
      })
      .catch((e) => postError(e));
  }, []);
  const handleBack = (ev) => {
    if (dirty) {
      if (window.confirm("Record not saved. Are you sure you want to leave?")) {
        navigate("../main");
      }
    } else {
      navigate("../main");
    }
  };
  const handleUpdate = (ev) => {
    console.log(departmentList);
    const departments = departmentList.map((department) => {
      const staffs = department.staff_doc.map((s) => {
        return { userid: s.userid };
      });
      return {
        departmentid: department.departmentid,
        name: department.name,
        year: department.year,
        staffs: staffs,
      };
    });
    post("api/departments/year/" + YEAR, departments)
      .then((r) => {
        alert("Record Updated");
        clearDirty();
      })
      .catch((e) => {
        postError(e);
      });
  };
  return (
    <Row>
      <Col className="d-none d-md-block col-md-1"></Col>
      <Col className="col-12 col-md-10 col-lg-9">
        <Card
          className="paper"
          style={{ margin: "0", padding: "0", border: "0" }}
        >
          <Card.Body style={{ margin: "0", padding: "0", border: "0" }}>
            <Row style={{ margin: "0" }}>
              <Card style={{ borderBottom: "1px solid black" }}>
                <Card.Body>
                  <div
                    className="d-flex justify-content-between"
                    style={{ margin: "0", padding: "0" }}
                  >
                    <div>
                      <h2>Assign Staff to Department</h2>
                    </div>

                    <div>
                      <button
                        className="btn btn-sm"
                        onClick={() => setShowTip(!showTip)}
                      >
                        ?
                      </button>
                    </div>
                  </div>

                  {showTip && (
                    <>
                      <h3>Instruction</h3>
                      <ol>
                        <li>
                          Use the{" "}
                          <span style={{ backgroundColor: "lightgreen" }}>
                            green panel
                          </span>{" "}
                          on the bottom left to select the department
                        </li>
                        <li>
                          If required, click on{" "}
                          <Button className="btn-sm" variant="primary">
                            <FontAwesomeIcon icon={faPlus} />
                          </Button>
                          to add new department to the system
                        </li>

                        <li>
                          Select the staffs in the
                          <span style={{ backgroundColor: "yellow" }}>
                            Yellow panel
                          </span>{" "}
                          to remove staffs from the department
                        </li>
                        <li>
                          Select the staffs in the{" "}
                          <span style={{ backgroundColor: "lightblue" }}>
                            Blue panel
                          </span>{" "}
                          to add staff into the department
                        </li>

                        <li>
                          Click on the{" "}
                          <button className="btn btn-sm btn-primary">
                            Update
                          </button>{" "}
                          button to save changes.
                        </li>
                        <li>
                          Click on the{" "}
                          <button className="btn btn-sm btn-warning">
                            Back
                          </button>{" "}
                          button to return to the mainpage.
                        </li>
                      </ol>
                      <p className="text-danger">
                        Record will be uploaded to the server <u>only</u> after
                        clicking on the
                        <button className="btn btn-sm btn-primary">
                          Update
                        </button>{" "}
                        button. Any record not saved will be lost.
                      </p>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Row>
            <Row style={{ padding: "1.5em" }}>
              <Col xs={12} md={6} lg={4}>
                <DepartmentList />
              </Col>
              <Col xs={12} md={6} lg={4}>
                {departmentSelected && (
                  <Card style={{ border: "1px solid black" }}>
                    <Card.Header style={{ backgroundColor: "yellow" }}>
                      Staffs in{" "}
                      <span style={{ fontWeight: "500" }}>
                        {departmentSelected}
                      </span>
                    </Card.Header>
                    <Card.Body>
                      <DepartmentUserList />
                    </Card.Body>
                  </Card>
                )}
              </Col>

              <Col xs={12} md={6} lg={4}>
                <DepartmentOrphanList />
              </Col>
            </Row>
            <Row
              style={{
                margin: "0em",
                border: "0em",
                padding: "0.5em",
                backgroundColor: "white",
                marginTop: "1em",
                borderTop: "1px solid black",
              }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <button style={{ fontWeight: "bold" }} className="btn btn-warning" onClick={handleBack}>
                    BACK
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-primary"
                    style={{ fontWeight: "bold", color: "black" }}
                    disabled={!dirty}
                    onClick={handleUpdate}
                  >
                    UPDATE
                  </button>
                </div>
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
export default connect(mstp, mdtp)(DepartmentMain);
