import React, { useRef } from "react";
import html2pdf from "html2pdf.js";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import AppraisalFormHeader from "./AppraisalFormHeader";
import AppraisalPart1 from "./AppraisalPart1";
import AppraisalPart2 from "./AppraisalPart2";
import { connect, useDispatch } from "react-redux";
import { updateList } from "../../store/MyAppraisalListSlice";
import { postError } from "../../store/ApplicationSlice";
import { Status } from "../../Constants";
import { APPTITLE } from "../../Constants";
import { post } from "axios";
import { Row, Col } from "react-bootstrap";
import { get } from "axios";
import { resetAppraisee } from "../../store/AppraisalSlice";
const moment = require("moment");
const mapStateToProps = (state, ownProps) => {
  const appraisal = state.appraisalSlice;
  const appraisalList = state.MyAppraisalListSlice.list;
  const username = state.applicationSlice?.currentUser?.preferred_username;

  return { appraisal, appraisalList, username };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const _postError = (e) => dispatch(postError(e));
  const _updateList = (list) => dispatch(updateList(list));
  return { postError: _postError, updateList: _updateList };
};

const AppraisalForm = (props) => {
  const formRef = useRef(); // Ref for the form container
  const dispatch = useDispatch();
  const {
    username,
    showReviewedByStaff,
    appraisal,
    appraisalList,
    updateList,
    onList,
    postError,
  } = props;
  const readOnly = props.readOnly === undefined ? false : props.readOnly;

  const saveToServer = async (newAppraisal, alertMessage) => {
    return await new Promise((resolve, reject) => {
      const newAppraisal2 = { ...newAppraisal };
      delete newAppraisal2["_id"];

      post("api/appraisals", newAppraisal2)
        .then((res) => {
          // alert("Record Saved as Draft");
          alert(alertMessage);
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const handleSave = (alertMessage, newStatus = undefined) => {
    newStatus = newStatus !== undefined ? newStatus : appraisal?.status;
    let newAppraisal;

    if (newStatus === "STAFFREVIEWED") {
      newAppraisal = { ...appraisal, status: newStatus, staff_date: moment().valueOf()  };
    }
    else if (newStatus === "SUPERVISORSUBMITTED") {
      newAppraisal = { ...appraisal, status: newStatus, supervisor_date: moment().valueOf() };
    }
    else if (newStatus === "PROXYSUBMITTED") {
      newAppraisal = { ...appraisal, status: newStatus, proxy_date: moment().valueOf() };
    }
    else {
      newAppraisal = { ...appraisal, status: newStatus };
    }
    // const newAppraisal = { ...appraisal, status: newStatus };

    saveToServer(newAppraisal, alertMessage)
      .then(() => {
        const appraiseeid = appraisal.appraisee?.userid;
        const appraiserid = appraisal.appraiser?.userid;

        if (appraiseeid !== undefined && appraiserid !== undefined) {
          const newAppraisalList = appraisalList.map((item) => {
            if (
              item.userid === appraiseeid &&
              item.appraisers?.appraiser?.userid === appraiserid
            ) {
              return { ...item, appraisal_doc: { ...newAppraisal } };
            } else {
              return item;
            }
          });

          updateList(newAppraisalList);
        }
        onList && onList();
      })
      .catch((e) => {
        window.scroll(0, 0);
        postError(e);
      });
  };

  const handleBack = () => {
    dispatch(resetAppraisee());
    onList && onList();
  };

  const handleUpdate = (alertMessage) => {
    if (appraisal.inComplete) {
      alert("Please fill up all the required information on this form");
      window.scroll(0, 0);
    } else {
      get("api/users/username/" + username)
        .then((res) => {
          return new Promise((resolve) => {
            resolve(res.data.userid);
          });
        })
        .then((userid) => {
          if (
            userid === appraisal?.appraiser?.userid &&
            [
              Status.MISSING,
              Status.PENDING,
              Status.WAITFORPROXY,
              Status.WAITFORSUPERVISOR,
              Status.PROXYSUBMITTED,
              Status.SUPERVISORSUBMITTED,
            ].includes(appraisal.status)
          ) {
            handleSave(alertMessage, Status.SUPERVISORSUBMITTED);
          } else if (
            userid === appraisal?.proxy?.userid &&
            [
              Status.MISSING,
              Status.PENDING,
              Status.WAITFORPROXY,
              // Status.WAITFORSUPERVISOR, error on proxy submit because button is still available for submit
              Status.PROXYSUBMITTED,
            ].includes(appraisal.status)
          ) {
            handleSave(alertMessage, Status.PROXYSUBMITTED);
          } else {
            postError("Unable to update. Please contract HR Department");
          }
        })
        .catch((e) => {
          postError(e);
        });

      // console.log(username);

      //
      // handleSave(Status.SUPERVISORSUBMITTED);
    }
  };
  const handleSignOff = (alertMessage) => {
    if (appraisal.status === undefined) {
      alert("Thank you for participating in Staff Annual Performance Review");
      handleSave(alertMessage, Status.STAFFREVIEWED);
    } else if (
      [
        Status.MISSING,
        Status.PENDING,
        Status.SUPERVISORSUBMITTED,
        Status.PROXYSUBMITTED,
      ].includes(appraisal.status)
    ) {
      alert("Thank you for participating in Staff Annual Performance Review");
      handleSave(alertMessage, Status.STAFFREVIEWED);
    } else {
      alert("Thank you for participating in Staff Annual Performance Review");
      handleSave(alertMessage, appraisal.status);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  // const handleDownload = () => {
  //   const doc = new jsPDF("landscape", "mm", "a4");

  //   // Add a border around the entire content
  //   const pageWidth = doc.internal.pageSize.width;
  //   const pageHeight = doc.internal.pageSize.height;
  //   const margin = 10;
  //   doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin); // Draw border

  //   // Title
  //   doc.setFont("helvetica", "bold");
  //   doc.setFontSize(20);
  //   const title = "Staff Annual Performance Evaluation for 2024";
  //   const titleWidth = doc.getTextWidth(title);
  //   doc.text(title, (pageWidth - titleWidth) / 2, 20); // Center the title

  //   // Header
  //   doc.setFontSize(12);
  //   doc.setFont("helvetica", "normal");
  //   const headerX = margin + 5;
  //   doc.text(`Staff Name: ${appraisal.appraisee?.fullname}`, headerX, 30);
  //   doc.text(`Position: ${appraisal.appraisee?.position}`, headerX, 35);
  //   doc.text(`Date Joined: ${appraisal.appraisee?.date_joined}`, headerX, 40);

  //   // Objectives Section
  //   doc.setFont("helvetica", "bold");
  //   doc.setFontSize(14);
  //   doc.text("OBJECTIVES", margin + 5, 55);

  //   doc.setFont("helvetica", "normal");
  //   doc.setFontSize(12);
  //   doc.text(
  //       "The purpose of the evaluation is to provide constructive feedback to staff and to " +
  //       "formulate a basis for salary reviews and bonuses. It should assist both the staff " +
  //       "member and his/her supervisor in identifying areas of improvement or competency " +
  //       "gaps which can be addressed.",
  //       margin + 5,
  //       60,
  //       { maxWidth: pageWidth - 2 * margin - 10, align: "justify" }
  //   );

  //   // Instructions Section
  //   doc.setFont("helvetica", "bold");
  //   doc.setFontSize(14);
  //   doc.text("INSTRUCTIONS", margin + 5, 80);

  //   doc.setFont("helvetica", "normal");
  //   doc.setFontSize(12);
  //   doc.text(
  //       "This performance evaluation must be completed honestly and objectively by the relevant " +
  //       "head of department or supervisor. Each criterion is to be rated using the scale " +
  //       "referenced below:",
  //       margin + 5,
  //       85,
  //       { maxWidth: pageWidth - 2 * margin - 10, align: "justify" }
  //   );

  //   // Table Data
  //   const tableData = [
  //       ["******", "100", "Excellent", "No action required"],
  //       ["*****", "100", "Excellent", "No action required"],
  //       ["****", "85", "Good", ""],
  //       ["***", "70", "Satisfactory", "Optional training can be considered"],
  //       ["**", "50", "Fair", ""],
  //       ["*", "30", "Poor", "Performance below expectation and competency gap exists. Relevant training or actions may be considered"],
  //       ["", "0", "", ""],
  //   ];

  //   // Add Table
  //   doc.autoTable({
  //       startY: 100,
  //       head: [["Scale", "Rating", "Remark", "Competency Status"]],
  //       body: tableData,
  //       styles: {
  //           font: "helvetica",
  //           fontSize: 10,
  //           cellPadding: 3,
  //           valign: "middle",
  //           halign: "center", // Center align table content
  //       },
  //       headStyles: {
  //           fillColor: [220, 220, 220],
  //           textColor: 0,
  //           fontStyle: "bold",
  //       },
  //       columnStyles: {
  //           0: { cellWidth: 40 }, // Scale
  //           1: { cellWidth: 30 }, // Rating
  //           2: { cellWidth: 50 }, // Remark
  //           3: { cellWidth: 148 }, // Competency Status
  //       },
  //       tableLineColor: [0, 0, 0], // Black border for table
  //       tableLineWidth: 0.1,
  //   });

  //   // Final Instructions
  //   const instructionsY = doc.lastAutoTable.finalY + 10;
  //   doc.text(
  //       "The appraiser must assess the staff member according to his/her position and level " +
  //       "of experience and against his/her job descriptions. The appraiser should also bear " +
  //       "in mind that the HSL Group expects superior performances from its staff. Specific " +
  //       "examples should be provided to support assessments, particularly for ratings 5 stars " +
  //       "and no stars. It is essential that this evaluation be discussed with the staff member " +
  //       "for development and improvement.",
  //       margin + 5,
  //       instructionsY,
  //       { maxWidth: pageWidth - 2 * margin - 10, align: "justify" }
  //   );

  //   // Add the title and section header for Part I
  //   let yPos = doc.lastAutoTable.finalY + 10;  // Starting y-position after instructions
  //   doc.setFontSize(16);
  //   doc.text("PART I: PROFICIENCY IN CURRENT ROLE", 20, yPos);
  //   yPos += 10;  // Increment y-position after title

  //   // Add the performance factors section title
  //   doc.setFontSize(14);
  //   doc.text("Performance Factors", 20, yPos);
  //   yPos += 10;  // Increment y-position after section title

  //   // Sample data to be included
  //   const performanceData = [
  //     { 
  //       questionTitle: "1.0 WORK QUALITY",
  //       question:
  //         "Effectively completes job assignments including accuracy, responsiveness, follow through, judgement, decision making, reliability, and compliance assurance",
  //       tips: [
  //         "Provides regular, accurate reports to management / directors.",
  //         "Effectively ensures quality control.",
  //         "Maintains efficient information/administration systems.",
  //       ],
  //       grade: appraisal.workQuality.grade, score: appraisal.workQuality.score, remark: appraisal.workQuality.remark 
  //     },
  //     { 
  //       questionTitle: "2.0 WORK EFFICIENCY",
  //       question:
  //         "Effectively produces the required amount of work including priority setting, productivity, and timeline.",
  //       tips: [
  //         "Sets and enforces realistic deadlines/targets, effectively priorities/organises workload ",
  //       ],
  //       grade: appraisal.workEfficiency.grade, score: appraisal.workEfficiency.score, remarks: appraisal.workEfficiency.remark 
  //     },
  //     { 
  //       questionTitle: "3.0 JOB KNOWLEDGE",
  //       question:
  //         "Effectively applies professional/managerial/technical and/or non technical skills and knowledge to the job.",
  //       tips: [
  //         "Has a strong command of relevant technical skills and knowledge",
  //         "Possess the relevant ICT skills",
  //         "Keep abreast of new developments and relevant regulations",
  //         "Understand his role and position in the company / project organisation",
  //       ],
  //       grade: appraisal.jobKnowledge.grade, score: appraisal.jobKnowledge.score, remarks: appraisal.jobKnowledge.remark 
  //     },
  //     { 
  //       questionTitle: "4.0 COMMUNICATION SKILLS",
  //       question:
  //         "Effectively transmits information including confidentiality, facilitation / participation in sharing information, and oral and written expression.",
  //       tips: [
  //         "Provides clear directions and instructions to staffs",
  //         "Effectively presents ideas and obtains acceptance.",

  //         "Takes initiative to inform Management of any problems or potential issue that require action.",
  //         "Writes clearly and concisely",
  //       ],
  //       grade: appraisal.communicationSkills.grade, score: appraisal.communicationSkills.score, remarks: appraisal.communicationSkills.remark 
  //     },
  //     { 
  //       questionTitle: "5.0 INTERPERSONAL SKILLS",
  //       question:
  //         "Effectively interacts with others, including co-workers, supervisor(s), customers, and/or others.",
  //       tips: [
  //         "Able to get along with colleague and others",
  //         "Liaises effectively at all levels, within and outside office.",
  //         "Is approachable by subordinates",
  //       ],
  //       grade: appraisal.interpersonalSkills.grade, score: appraisal.interpersonalSkills.score, remarks: appraisal.interpersonalSkills.remark 
  //     },
  //     { 
  //       questionTitle: "6.0 ISO QUALITY MANAGEMENT SYSTEM (For applicable staff only)",
  //       question:
  //         "Complies to the procedures and Quality Management System within the company and as directed by the supervisor.",
  //       tips: [
  //         "Knows and understands the company's Quality Policy, overall processess and Quality Objectives relevant to own section",
  //         "Promotes and comply with the company's Quality Management System",
  //         "Contributes to the continual improvement process of the company",
  //       ],
  //       grade: appraisal.isoQualityManagementSystem.grade, score: appraisal.isoQualityManagementSystem.score, remarks: appraisal.isoQualityManagementSystem.remark 
  //     },
  //     { 
  //       questionTitle: "7.0 ATTENDANCE",
  //       question: "Responsible attendance and punctuality record.",
  //       tips: [
  //         "Punctual to work",
  //         "Responsible use of leave entitlements",
  //         "Readily participates in company social activities / events",
  //       ],
  //       grade: appraisal.attendance.grade, score: appraisal.attendance.score, remarks: appraisal.attendance.remark 
  //     },
  //     { 
  //       questionTitle: "8.0 SUPERVISORY SKILL (For Positions of Supervisors & Managers only)",
  //       question:
  //         "Effectively manages team, demonstrates leadership, provides direction and development.",
  //       tips: [
  //         "Ensures staff sustain productivity work",
  //         "Manages and motivates staff to maximise team performance",
  //         "Delegates fairly and effectively",
  //         "Identifies and develops staff capabilities",
  //         "Willing to share knowledge, offers guidance and counseling",
  //         "Is approachable by subordinates",
  //       ],
  //       grade: appraisal.supervisorySkills.grade, score: appraisal.supervisorySkills.score, remarks: appraisal.supervisorySkills.remark 
  //     },
  //   ];

  //   performanceData.forEach((item, index) => {
  //     // Add question title and grade
  //     doc.setFontSize(12);
  //     doc.setFont("helvetica", "bold");
  //     doc.text(`${item.questionTitle}`, 20, yPos);
  //     yPos += 8;  // Adjust vertical space between title and content
  //     doc.setFont("helvetica", "normal");
  //     doc.text(item.question, 20, yPos);
  //     yPos += 10;  // Adjust space after the question

  //     // Add grade (display stars)
  //     doc.setFont("helvetica", "bold");
  //     const grade = "*".repeat(item.grade);  // Repeat stars based on grade
  //     doc.text(`Grade: ${grade}`, 20, yPos);
  //     yPos += 8;  // Space between grade and remarks

  //     // Add remarks
  //     doc.setFont("helvetica", "normal");
  //     doc.text(`Comments: ${item.remarks}`, 20, yPos);
  //     yPos += 10;  // Adjust space after remarks

  //     // Add tips
  //     item.tips.forEach((tip, tipIndex) => {
  //         doc.text(`- ${tip}`, 20, yPos);
  //         yPos += 5;  // Adjust space after each tip
  //     });

  //     // Add a small gap before the next question
  //     yPos += 10;
  //   });

  //   // Add Total Score section
  //   doc.setFontSize(14);
  //   doc.text(`Total Score: ${appraisal.totalScore.averageScore}`, 20, yPos);

  //   // Save the PDF
  //   doc.save(`Appraisal_Form_${appraisal.appraisee?.fullname || "N/A"}.pdf`);
  // };

  const handleDownload = () => {
    const doc = new jsPDF("p", "mm", "a4");

    // Add a border around the entire content
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin); // Draw border

    let currentY = 20; // Start Y position for content
    const lineHeight = 6; // Adjust line height as needed

    // Title
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);
    const title = "Staff Annual Performance Evaluation for 2024";
    const titleWidth = doc.getTextWidth(title);
    doc.text(title, (pageWidth - titleWidth) / 2, currentY); // Center the title
    currentY += 15; // Move down after title

    // Header
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    const headerX = margin + 5;
    doc.text(`Staff Name: ${appraisal.appraisee?.fullname}`, headerX, currentY);
    currentY += lineHeight;
    doc.text(`Position: ${appraisal.appraisee?.position}`, headerX, currentY);
    currentY += lineHeight;
    doc.text(`Date Joined: ${appraisal.appraisee?.date_joined}`, headerX, currentY);
    currentY += 15; // Extra space after header

    // Objectives Section
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("OBJECTIVES", margin + 5, currentY);
    currentY += lineHeight;

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(
        "The purpose of the evaluation is to provide constructive feedback to staff and to " +
        "formulate a basis for salary reviews and bonuses. It should assist both the staff " +
        "member and his/her supervisor in identifying areas of improvement or competency " +
        "gaps which can be addressed.",
        margin + 5,
        currentY,
        { maxWidth: pageWidth - 2 * margin - 10, align: "justify" }
    );
    currentY += 25; // Adjust space after objectives

    // Instructions Section
    doc.setFont("helvetica", "bold");
    doc.setFontSize(14);
    doc.text("INSTRUCTIONS", margin + 5, currentY);
    currentY += lineHeight;

    doc.setFont("helvetica", "normal");
    doc.setFontSize(12);
    doc.text(
        "This performance evaluation must be completed honestly and objectively by the relevant " +
        "head of department or supervisor. Each criterion is to be rated using the scale " +
        "referenced below:",
        margin + 5,
        currentY,
        { maxWidth: pageWidth - 2 * margin - 10, align: "justify" }
    );
    currentY += 15; // Adjust space after instructions

    // Table Data
    const tableData = [
        ["*****", "100", "Excellent", "No action required"],
        ["****", "85", "Good", ""],
        ["***", "70", "Satisfactory", "Optional training can be considered"],
        ["**", "50", "Fair", ""],
        ["*", "30", "Poor", "Performance below expectation and competency gap exists. Relevant training or actions may be considered"],
        ["", "0", "", ""],
    ];

    // Add Table
    doc.autoTable({
        startY: currentY,
        head: [["Scale", "Rating", "Remark", "Competency Status"]],
        body: tableData,
        styles: {
            font: "helvetica",
            fontSize: 10,
            cellPadding: 3,
            valign: "middle",
            halign: "center", // Center align table content
        },
        headStyles: {
            fillColor: [220, 220, 220],
            textColor: 0,
            fontStyle: "bold",
        },
        columnStyles: {
            0: { cellWidth: 25 }, // Scale
            1: { cellWidth: 25 }, // Rating
            2: { cellWidth: 40 }, // Remark
            3: { cellWidth: 92 }, // Competency Status
        },
        tableLineColor: [0, 0, 0], // Black border for table
        tableLineWidth: 0.1,
    });

    currentY = doc.lastAutoTable.finalY + 10; // Update Y-position after table

    // Final Instructions
    doc.text(
        "The appraiser must assess the staff member according to his/her position and level " +
        "of experience and against his/her job descriptions. The appraiser should also bear " +
        "in mind that the HSL Group expects superior performances from its staff. Specific " +
        "examples should be provided to support assessments, particularly for ratings 5 stars " +
        "and no stars. It is essential that this evaluation be discussed with the staff member " +
        "for development and improvement.",
        margin + 5,
        currentY,
        { maxWidth: pageWidth - 2 * margin - 10, align: "justify" }
    );
    currentY += 30; // Adjust space after final instructions

    // Function to wrap long text into multiple lines
    function wrapText(doc, text, x, y, maxWidth) {
      const lines = doc.splitTextToSize(text, maxWidth);
      lines.forEach((line, index) => {
        doc.text(line, x, y);
        y += 5; // Adjust Y position for each new line
      });
      return y; // Return the updated Y position
    }

    doc.addPage();
    let currentY1 = 20; 
    const lineHeight1 = 6;
    doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);

    doc.setFontSize(16);
    doc.text("PART I: PROFICIENCY IN CURRENT ROLE", margin + 5, currentY1, { maxWidth: pageWidth - 2 * margin - 10, align: "justify" });
    currentY1 += lineHeight1 * 2; // Adjust Y position

    // Add the performance factors section
    doc.setFontSize(14);
    doc.setFont("helvetica", "italic");
    doc.text("Performance Factors", margin + 5, currentY1, { maxWidth: pageWidth - 2 * margin - 10, align: "justify" });
    currentY1 += lineHeight1 * 2;

    // Data to be included
    const performanceData = [
      { 
        questionTitle: "1.0 WORK QUALITY",
        question:
          "Effectively completes job assignments including accuracy, responsiveness, follow through, judgement, decision making, reliability, and compliance assurance",
        tips: [
          "Provides regular, accurate reports to management / directors.",
          "Effectively ensures quality control.",
          "Maintains efficient information/administration systems.",
        ],
        grade: appraisal.workQuality.grade, score: appraisal.workQuality.score, remarks: appraisal.workQuality?.remark || "-"
      },
      { 
        questionTitle: "2.0 WORK EFFICIENCY",
        question:
          "Effectively produces the required amount of work including priority setting, productivity, and timeline.",
        tips: [
          "Sets and enforces realistic deadlines/targets, effectively priorities/organises workload ",
        ],
        grade: appraisal.workEfficiency.grade, score: appraisal.workEfficiency.score, remarks: appraisal.workEfficiency?.remark || "-"
      },
      { 
        questionTitle: "3.0 JOB KNOWLEDGE",
        question:
          "Effectively applies professional/managerial/technical and/or non technical skills and knowledge to the job.",
        tips: [
          "Has a strong command of relevant technical skills and knowledge",
          "Possess the relevant ICT skills",
          "Keep abreast of new developments and relevant regulations",
          "Understand his role and position in the company / project organisation",
        ],
        grade: appraisal.jobKnowledge.grade, score: appraisal.jobKnowledge.score, remarks: appraisal.jobKnowledge?.remark || "-"
      },
      { 
        questionTitle: "4.0 COMMUNICATION SKILLS",
        question:
          "Effectively transmits information including confidentiality, facilitation / participation in sharing information, and oral and written expression.",
        tips: [
          "Provides clear directions and instructions to staffs",
          "Effectively presents ideas and obtains acceptance.",

          "Takes initiative to inform Management of any problems or potential issue that require action.",
          "Writes clearly and concisely",
        ],
        grade: appraisal.communicationSkills.grade, score: appraisal.communicationSkills.score, remarks: appraisal.communicationSkills?.remark || "-"
      },
      { 
        questionTitle: "5.0 INTERPERSONAL SKILLS",
        question:
          "Effectively interacts with others, including co-workers, supervisor(s), customers, and/or others.",
        tips: [
          "Able to get along with colleague and others",
          "Liaises effectively at all levels, within and outside office.",
          "Is approachable by subordinates",
        ],
        grade: appraisal.interpersonalSkills.grade, score: appraisal.interpersonalSkills.score, remarks: appraisal.interpersonalSkills?.remark || "-"
      },
      { 
        questionTitle: "6.0 ISO QUALITY MANAGEMENT SYSTEM (For applicable staff only)",
        question:
          "Complies to the procedures and Quality Management System within the company and as directed by the supervisor.",
        tips: [
          "Knows and understands the company's Quality Policy, overall processess and Quality Objectives relevant to own section",
          "Promotes and comply with the company's Quality Management System",
          "Contributes to the continual improvement process of the company",
        ],
        grade: appraisal.isoQualityManagementSystem.grade, score: appraisal.isoQualityManagementSystem.score, remarks: appraisal.isoQualityManagementSystem?.remark || "-"
      },
      { 
        questionTitle: "7.0 ATTENDANCE",
        question: "Responsible attendance and punctuality record.",
        tips: [
          "Punctual to work",
          "Responsible use of leave entitlements",
          "Readily participates in company social activities / events",
        ],
        grade: appraisal.attendance.grade, score: appraisal.attendance.score, remarks: appraisal.attendance?.remark || "-"
      },
      { 
        questionTitle: "8.0 SUPERVISORY SKILL (For Positions of Supervisors & Managers only)",
        question:
          "Effectively manages team, demonstrates leadership, provides direction and development.",
        tips: [
          "Ensures staff sustain productivity work",
          "Manages and motivates staff to maximise team performance",
          "Delegates fairly and effectively",
          "Identifies and develops staff capabilities",
          "Willing to share knowledge, offers guidance and counseling",
          "Is approachable by subordinates",
        ],
        grade: appraisal.supervisorySkills.grade, score: appraisal.supervisorySkills.score, remarks: appraisal.supervisorySkills?.remark || "-"
      },
    ];

    // Function to check remaining space on the current page
    function hasEnoughSpace(doc, currentY1, lineHeight, requiredLines) {
      const remainingSpace = pageHeight - currentY1 - 40; // Reserve some space for footer
      return remainingSpace >= requiredLines * lineHeight;
    }

    // Add performance data
    performanceData.forEach((item, index) => {
        // if (currentY1 > pageHeight - 40) {
        //   doc.addPage();
        //   doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);
        //   currentY1 = 20;
        // }

        // Check if we have enough space for the full section (including comments)
        let requiredLines = 3 + item.tips.length + 1; // 3 lines: title, question, grade + number of tips + 1 for remarks
        if (!hasEnoughSpace(doc, currentY1, lineHeight1, requiredLines)) {
            doc.addPage();
            doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);
            currentY1 = 20;
        }

        // Add question title
        doc.setFontSize(12);
        doc.setFont("helvetica", "bold");
        currentY1 = wrapText(doc, item.questionTitle, margin + 5, currentY1, pageWidth - 2 * margin - 10); // Wrap text
        currentY1 += lineHeight1; // Adjust Y position

        // Add question
        doc.setFont("helvetica", "normal");
        currentY1 = wrapText(doc, item.question, margin + 5, currentY1, pageWidth - 2 * margin - 10); // Wrap text
        currentY1 += lineHeight1; // Adjust Y position

        // Add grade (display stars)
        doc.setFont("helvetica", "bold");
        const grade = "*".repeat(item.grade);  // Repeat stars based on grade
        currentY1 = wrapText(doc, `Grade: ${item.score} (${grade})`, margin + 5, currentY1, pageWidth - 2 * margin - 10); // Wrap text
        currentY1 += lineHeight1; // Adjust Y position

        // Add tips
        item.tips.forEach((tip, tipIndex) => {
            currentY1 = wrapText(doc, `- ${tip}`, margin + 5, currentY1, pageWidth - 2 * margin - 10); // Wrap text
            currentY1 += lineHeight1; // Adjust Y position
        });

        // Check if we have space for the remarks (comments) before adding
        // if (!hasEnoughSpace(doc, currentY1, lineHeight1, 1)) {
        //   doc.addPage();
        //   doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);
        //   currentY1 = 20;
        // }

        // Add remarks
        doc.setFont("helvetica", "normal");
        doc.setTextColor(0, 0, 255);  // Set text color to blue
        currentY1 = wrapText(doc, `Comments: ${item.remarks}`, margin + 5, currentY1, pageWidth - 2 * margin - 10); // Wrap text
        currentY1 += lineHeight1 * 3; // Add extra space after comments

        doc.setTextColor(0, 0, 0);
    });

    // Add Total Score section
    // if (currentY1 > pageHeight - 40) {
    //   doc.addPage();
    //   doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);
    //   currentY1 = 20;
    // }

    // Add Total Score section
    doc.setFontSize(14);
    doc.setFont("helvetica", "bold");
    doc.text(`Total Score: ${appraisal.totalScore.averageScore}`, margin + 5, currentY1, { maxWidth: pageWidth - 2 * margin - 10, align: "justify" });
    currentY1 += lineHeight1 * 2; // Adjust Y position

    // Add a line after the total score
    doc.setDrawColor(0, 0, 0);  // Set line color (black)
    doc.setLineWidth(0.1);       // Set line width
    doc.line(margin, currentY1, pageWidth - margin, currentY1);  // Draw line
    currentY1 += lineHeight1 * 3; // Add some space after the line

    doc.setFontSize(16);
    doc.setFont("helvetica", "normal");
    doc.text("PART II: COMPETENCY STATUS", margin + 5, currentY1, { maxWidth: pageWidth - 2 * margin - 10, align: "justify" });
    currentY1 += lineHeight1; // Adjust Y position

    doc.setFontSize(12);
    doc.text("Any action / training needs for work performance improvement and career development?", margin + 5, currentY1, { maxWidth: pageWidth - 2 * margin - 10, align: "justify" });
    currentY1 += lineHeight1; // Adjust Y position

    doc.setFontSize(12);
    doc.setFont("helvetica", "bolditalic");
    doc.text("Remarks:", margin + 5, currentY1, { maxWidth: pageWidth - 2 * margin - 10, align: "justify" });
    currentY1 += lineHeight1; // Adjust Y position

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(0, 0, 255);  // Set text color to blue
    doc.text(`${appraisal.remark || "-"}`, margin + 5, currentY1, { maxWidth: pageWidth - 2 * margin - 10, align: "justify" });
    currentY1 += lineHeight1 * 4; // Adjust Y position

    doc.setTextColor(0, 0, 0);

    // Check if we have space for the remarks (comments) before adding
    if (!hasEnoughSpace(doc, currentY1, lineHeight1, 1)) {
      doc.addPage();
      doc.rect(margin, margin, pageWidth - 2 * margin, pageHeight - 2 * margin);
      currentY1 = 20;
    }

    doc.setFontSize(16);
    doc.text("PART III : OVERALL COMMENTS / AREA FOR IMPROVEMENT", margin + 5, currentY1, { maxWidth: pageWidth - 2 * margin - 10, align: "justify" });
    currentY1 += lineHeight1; // Adjust Y position

    // Add remarks
    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");
    doc.setTextColor(0, 0, 255);  // Set text color to blue
    currentY1 = wrapText(doc, `${appraisal.overallComment || "-"}`, margin + 5, currentY1, pageWidth - 2 * margin - 10); // Wrap text

    // Save the PDF
    doc.save(`Appraisal_Form_${appraisal.appraisee?.fullname || "N/A"}.pdf`);
  };

  return (
    <Row>
      <Col md={1} className="d-none d-md-block"></Col>
      <Col xs={12} md={10} lg={9}>
        <div className="card" ref={formRef}>
          {/* Form Content */}
          <div className="card-body" style={{ pageBreakInside: "avoid" }}>
            <h1>{APPTITLE}</h1>

            <AppraisalFormHeader readOnly={readOnly} />
            <AppraisalPart1 readOnly={readOnly} />
            <AppraisalPart2 readOnly={readOnly} />
            <div className="card-footer">
              <div className="d-flex justify-content-between">
                <div>
                  <button className="btn btn-warning" style={{ fontWeight: "bold" }} onClick={handleBack}>
                    BACK
                  </button>
                </div>
                <div>
                  <button
                    className="btn btn-primary me-1"
                    style={{ fontWeight: "bold", color: "black" }}
                    onClick={handleDownload}
                  >
                    DOWNLOAD AS PDF
                  </button>
                  {/* <button
                    className="btn btn-primary me-1"
                    style={{ fontWeight: "bold", color: "black" }}
                    onClick={handlePrint}
                  >
                    PRINT
                  </button> */}
                  {showReviewedByStaff === true && (
                    <button
                      className="btn btn-primary me-1"
                      style={{ fontWeight: "bold", color: "black" }}
                      onClick={(ev) => handleSignOff("STAFF REVIEWED")}
                    >
                      ACKNOWLEDGED
                    </button>
                  )}
                  {readOnly === true && <></>}
                  {readOnly === false && (appraisal.status === "PROXYSUBMITTED" || appraisal.status === "PENDING" || appraisal.status === "MISSING") && (
                    <>
                      <button
                        className="btn btn-primary me-1"
                        style={{ fontWeight: "bold", color: "black" }}
                        onClick={(ev) => handleSave("Record Saved as Draft")}
                      >
                        SAVE AS DRAFT
                      </button>
                      <button
                        className="btn btn-primary"
                        style={{ fontWeight: "bold", color: "black" }}
                        onClick={(ev) => handleUpdate("Record Submitted")}
                      >
                        SUBMIT
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AppraisalForm);