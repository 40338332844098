import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { Row, Col, Card, Button } from "react-bootstrap";
import AppraiserList from "./AppraiserList";
import { useNavigate } from "react-router-dom";
import StaffList from "./StaffList";
import { post } from "axios";
import {
  updateSearch,
  updateOrphanOnly,
  updateStaffs,
} from "../../store/AppraiseeListSlice";
import { postError } from "../../store/ApplicationSlice";
import axios from "axios";
import "./AssignAppraiserForm.css";
const mstp = (state, ownProps) => {
  const appraisee = state.appraiseeListSlice.appraisee;
  const staffs = state.appraiseeListSlice.staffs;
  return { appraisee, staffs };
};
const mdtp = (dispatch, ownProps) => {
  const _updateSearch = (v) => dispatch(updateSearch(v));

  const _updateOrphanOnly = () => dispatch(updateOrphanOnly());
  const _updateStaffs = (v) => dispatch(updateStaffs(v));
  const _postError = (e) => dispatch(postError(e));
  return {
    updateSearch: _updateSearch,
    updateOrphanOnly: _updateOrphanOnly,
    updateStaffs: _updateStaffs,
    postError: _postError,
  };
};

export default connect(
  mstp,
  mdtp
)((props) => {
  const { appraisee, staffs } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showTip, setShowTip] = useState(false);
  useEffect(() => {
    axios
      .get("https://appraisal.hslnet.my/api/appraisees")
      .then((res) => {
        dispatch(updateStaffs(res.data));
      })
      .catch((e) => console.log(e));
  }, []);

  const handleUpdate = () => {
    let appraisees = staffs.map((staff) => {
      const e = { userid: staff.userid, appraisers: staff.appraisers };
      return e;
    });

    post("api/appraisees", appraisees)
      .then((res) => {
        alert("Record Uploaded");
      })
      .catch((e) => {
        postError(e);
      });
  };
  const handleBack = (ev) => {
    navigate("../main");
  };
  return (
    <Row>
      <Col className="d-none d-md-block col-md-1"></Col>
      <Col className="col-12 col-md-10 col-lg-9">
        <Card
          className="paper"
          style={{ margin: "0", padding: "0", border: "0" }}
        >
          <Card.Body style={{ margin: "0", padding: "0", border: "0" }}>
            <Row style={{ margin: "0" }}>
              <Card style={{ borderBottom: "1px solid black" }}>
                <Card.Body>
                  <div
                    className="d-flex justify-content-between"
                    style={{ margin: "0", padding: "0" }}
                  >
                    <div>
                      <h2>Assign Appraiser to Staff</h2>
                    </div>

                    <div>
                      <button
                        className="btn btn-sm"
                        onClick={() => setShowTip(!showTip)}
                      >
                        ?
                      </button>
                    </div>
                  </div>

                  {showTip && (
                    <>
                      <h3>Instruction</h3>
                      <ol>
                        <li>
                          Use the{" "}
                          <span style={{ backgroundColor: "lightgreen" }}>
                            green panel
                          </span>{" "}
                          on the bottom left to select the staff to be appraised
                        </li>
                        <li>
                          You can use the search input in the{" "}
                          <span style={{ backgroundColor: "lightgreen" }}>
                            green panel
                          </span>{" "}
                          to narrow your list
                        </li>
                        <li>
                          Check on the <input type="checkbox" />{" "}
                          <u>Orphan Only</u> checkbox to view staffs without any
                          appraiser
                        </li>
                        <li>
                          Use the{" "}
                          <span style={{ backgroundColor: "yellow" }}>
                            yellow panel
                          </span>{" "}
                          on the bottom right to assign an appraiser to him
                        </li>
                        <li>
                          Click on{" "}
                          <button className="btn btn-sm btn-primary">+</button>{" "}
                          button in the{" "}
                          <span style={{ backgroundColor: "yellow" }}>
                            yellow panel
                          </span>{" "}
                          to assign an appraiser to him
                        </li>
                        <li>
                          Use the search input in the{" "}
                          <span style={{ backgroundColor: "lightblue" }}>
                            blue panel
                          </span>{" "}
                          to search for an appraiser
                        </li>
                        <li>
                          You can assigned a proxy for each appraisal if so
                          desired
                        </li>
                        <li>
                          Click on the <b>X</b> button on the corresponding
                          panel to remove the appraiser
                        </li>
                        <li>
                          Click on the{" "}
                          <button className="btn btn-sm btn-primary">
                            Update
                          </button>{" "}
                          button to save changes.
                        </li>
                        <li>
                          Click on the{" "}
                          <button className="btn btn-sm btn-warning">
                            Back
                          </button>{" "}
                          button to return to the mainpage.
                        </li>
                      </ol>
                      <p className="text-danger">
                        Record will be uploaded to the server <u>only</u> after
                        clicking on the
                        <button className="btn btn-sm btn-primary">
                          Update
                        </button>{" "}
                        button. Any record not saved will be lost.
                      </p>
                    </>
                  )}
                </Card.Body>
              </Card>
            </Row>
            <Row style={{ padding: "1.5em" }}>
              <Col xs={12} md={6}>
                <Card style={{ border: "1px solid black" }}>
                  <Card.Header style={{ backgroundColor: "lightgreen" }}>
                    Imported List
                  </Card.Header>
                  <Card.Body>
                    <StaffList />
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                {appraisee && <AppraiserList />}
              </Col>
            </Row>
            <Row
              style={{
                margin: "0em",
                border: "0em",
                padding: "0.5em",
                backgroundColor: "white",
                marginTop: "1em",
                borderTop: "1px solid black",
              }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <button style={{ fontWeight: "bold" }}className="btn btn-warning" onClick={handleBack}>
                    BACK
                  </button>
                </div>
                <div>
                  <button style={{ fontWeight: "bold", color: "black" }} className="btn btn-primary" onClick={handleUpdate}>
                    UPDATE
                  </button>
                </div>
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
});
