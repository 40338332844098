import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Row, Col, Table } from "react-bootstrap";

import { LISTVIEW, YEAR } from "../../Constants";
import { postError } from "../../store/ApplicationSlice";
import { get } from "axios";
import { useNavigate } from "react-router-dom";
import SubmittedList from "./SubmittedList";

// import "./DepartmentForm.css";

const DepartmentSubmitted = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [deptRecords, setDeptRecords] = useState([]);
  const [coRecords, setCoRecords] = useState([]);

  const [listRecord, setListRecord] = useState([]);
  const [listTitle, setListTitle] = useState("");

  const [mode, setMode] = useState(LISTVIEW.LIST);

  useEffect(() => {
    get("api/departments/stats/submitted/summary/year/" + YEAR)
      .then((res) => {
        console.log(res.data);
        setDeptRecords(res.data);
      })
      .catch((e) => {
        dispatch(postError(e));
      });
  }, []);
  useEffect(() => {
    get("api/companies/stats/submitted/summary/year/" + YEAR)
      .then((res) => {
        console.log(res.data);
        setCoRecords(res.data);
      })
      .catch((e) => {
        dispatch(postError(e));
      });
  }, []);
  const handleBack = (ev) => {
    navigate("../main");
  };
  const handleClickDepartment = (item) => {
    const departmentid = item._id.departmentid;
    setListTitle(item._id.name);

    new Promise((resolve, reject) => {
      get(
        "api/departments/stats/submitted/department/" +
          departmentid +
          "/year/" +
          YEAR
      )
        .then((res) => {
          console.log(res.data);
          setListRecord(res.data);
          return;
        })
        .then(() => {
          setMode(LISTVIEW.VIEW);
          resolve();
        })
        .catch((e) => {
          dispatch(postError(e));
        });
    });
  };
  const handleClickCo = (item) => {
    console.log(item);
    const companyid = item._id.companyid;
    setListTitle(item._id.name);
    console.log(
      "api/companies/stats/submitted/company/" + companyid + "/year/" + YEAR
    );
    new Promise((resolve, reject) => {
      get(
        "api/companies/stats/submitted/company/" + companyid + "/year/" + YEAR
      )
        .then((res) => {
          console.log(res.data);
          setListRecord(res.data);
          return;
        })
        .then(() => {
          setMode(LISTVIEW.VIEW);
          resolve();
        })
        .catch((e) => {
          dispatch(postError(e));
        });
    });
  };
  const handleListMode = () => {
    setMode(LISTVIEW.LIST);
  };
  return (
    <>
      {mode === LISTVIEW.VIEW && (
        <SubmittedList
          name2={listTitle}
          records={listRecord}
          onBack={handleListMode}
        />
      )}
      {mode === LISTVIEW.LIST && (
        <Row>
          <Col md={3} className="d-none d-md-block"></Col>
          <Col xs={12} md={8} lg={6}>
            <Card style={{ border: "1px solid black", padding: "20px" }}>
              <Table striped style={{ border: "1px solid black" }}>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th className="h3" colSpan={2}>
                      Company
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {coRecords.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        style={{ cursor: "pointer" }}
                        onClick={(ev) => handleClickCo(item)}
                      >
                        <td>{i + 1}</td>
                        <td className="h4">{item._id?.name}</td>
                        <td className="h4">
                          {item.done}/{item.total}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
            <Card style={{ border: "1px solid black", padding: "20px" }}>
              <Table striped style={{ border: "1px solid black" }}>
                <thead>
                  <tr>
                    <th>&nbsp;</th>
                    <th className="h3" colSpan={2}>
                      Department
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {deptRecords.map((item, i) => {
                    return (
                      <tr
                        key={i}
                        style={{ cursor: "pointer" }}
                        onClick={(ev) => handleClickDepartment(item)}
                      >
                        <td>{i + 1}</td>
                        <td className="h4">{item._id?.name}</td>
                        <td className="h4">
                          {item.done}/{item.total}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
            <Card>
              <Card.Body>
                <div className="d-flex justify-content-between">
                  <div>
                    <button style={{ fontWeight: "bold" }} className="btn btn-warning" onClick={handleBack}>
                      BACK
                    </button>
                  </div>
                  <div></div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DepartmentSubmitted;
