import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { triggerBase64Download } from "common-base64-downloader-react";

// import { addDepartment, selectDepartment } from "../../store/DepartmentSlice";
import { Button, Card, Row, Col, Table } from "react-bootstrap";
import { get } from "axios";
import Score from "../../components/Score";
import { YESNO, NOTRELEVANT, Status, LISTVIEW } from "../../Constants";

import { useDispatch } from "react-redux";
import AppraisalForm from "../AppraisalForm/AppraisalForm";
import { updateAppraisal } from "../../store/AppraisalSlice";
import ReactPaginate from "react-paginate";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate} from "react-router-dom";
import Pic from "../../components/Pic";
import StatusBall from "../../components/StatusBall";

const AppraiseeScore = (props) => {
    const navigate = useNavigate();
  const [records, setRecords] = useState([]);
  const [currentItems, setCurrentItems] = useState(records);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);
  const [search, setSearch] = useState("");
  const [mode, setMode] = useState(LISTVIEW.LIST);

  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const handleClick = (item) => {
    // console.log(item);
    dispatch(updateAppraisal(item.appraisal_doc));
    setMode(LISTVIEW.VIEW);
  };
  const handleOnList = () => {
    setMode(LISTVIEW.LIST);
  };
    const handleBack = (ev) => {
        navigate('../main');
    }

    const handleDownload = () => {
      get("api/appraisees/export/year/2024")
        .then((res) => {
          const base64EXCEL =
            "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," + res.data;
          triggerBase64Download(base64EXCEL, "Appraisal2024");
        })
    }
  useEffect(() => {
    get("api/appraisees/year/2024")
      .then((res) => {
        return new Promise((resolve) => {
          const newArr = res.data?.map((i) => {
            return {
              date_joined: i.date_joined,
              fullname: i.fullname,
              sid: i.sid,
              status: i.status,
              userid: i.userid,
              username: i.username,
              year: i.year,
              appraisal_doc:
                i.appraisal_doc === undefined
                  ? {
                      workQuality: { relevance: YESNO.YES, score: 0 },
                      workEfficiency: { relevance: YESNO.YES, score: 0 },
                      jobKnowledge: { relevance: YESNO.YES, score: 0 },
                      communicationSkills: { relevance: YESNO.YES, score: 0 },
                      interpersonalSkills: { relevance: YESNO.YES, score: 0 },
                      isoQualityManagementSystem: {
                        relevance: YESNO.YES,
                        score: 0,
                      },
                      attendance: { relevance: YESNO.YES, score: 0 },
                      supervisorySkills: { relevance: YESNO.YES, score: 0 },
                      totalScore: { averageScore: 0 },
                      status: Status.MISSING,
                    }
                  : i.appraisal_doc,
            };
          });
          resolve(newArr);
        });
      })
      .then((arr) => {
        const newArr = arr.map((i) => {
          return {
            date_joined: i.date_joined,
            fullname: i.fullname,
            sid: i.sid,
            status: i.appraisal_doc.status,
            userid: i.userid,
            username: i.username,
            year: i.year,
            appraisal_doc: i.appraisal_doc,
            workQuality:
              i.appraisal_doc.workQuality.relevance === YESNO.YES
                ? +i.appraisal_doc.workQuality.score
                : NOTRELEVANT,
            workEfficiency:
              i.appraisal_doc.workEfficiency.relevance === YESNO.YES
                ? +i.appraisal_doc.workEfficiency.score
                : NOTRELEVANT,
            jobKnowledge:
              i.appraisal_doc.jobKnowledge.relevance === YESNO.YES
                ? +i.appraisal_doc.jobKnowledge.score
                : NOTRELEVANT,
            communicationSkills:
              i.appraisal_doc.communicationSkills.relevance === YESNO.YES
                ? +i.appraisal_doc.communicationSkills.score
                : NOTRELEVANT,
            interpersonalSkills:
              i.appraisal_doc.interpersonalSkills.relevance === YESNO.YES
                ? +i.appraisal_doc.interpersonalSkills.score
                : NOTRELEVANT,
            isoQualityManagementSystem:
              i.appraisal_doc.isoQualityManagementSystem.relevance === YESNO.YES
                ? +i.appraisal_doc.isoQualityManagementSystem.score
                : NOTRELEVANT,
            attendance:
              i.appraisal_doc.attendance.relevance === YESNO.YES
                ? +i.appraisal_doc.attendance.score
                : NOTRELEVANT,
            supervisorySkills:
              i.appraisal_doc.supervisorySkills.relevance === YESNO.YES
                ? +i.appraisal_doc.supervisorySkills.score
                : NOTRELEVANT,
            totalScore: +i.appraisal_doc.totalScore.averageScore,
          };
        });

        setRecords(newArr);
      });
  }, []);

  useEffect(() => {
    // Fetch items from another resources.

    let filteredList = [];

    if (search === "") {
      filteredList = [...records];
    } else {
      filteredList = records.filter((item) => {
        return item.fullname?.toUpperCase().match(search.toUpperCase());
      });
    }

    const endOffset = itemOffset + itemsPerPage;
    // console.log(`Loading items from ${itemOffset} to ${endOffset}`);

    setCurrentItems(filteredList.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(filteredList.length / itemsPerPage));
  }, [search, itemOffset, itemsPerPage, records]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % records.length;
    // console.log(
    //   `User requested page number ${event.selected}, which is offset ${newOffset}`
    // );
    setItemOffset(newOffset);
  };
  const handleChangeSearch = (ev) => {
    const newSearch = ev.target.value;
    setSearch(newSearch.replace(/[^a-z0-9 ]/gi, ""));
    setItemOffset(0);
  };
  const blankSegment = () => {
    return (
      <>
        <td colSpan="9" style={{ textAlign: "center" }}>
          APPRAISAL NOT AVAILABLE
        </td>
      </>
    );
  };
  const scoreSegment = (item) => {
    return (
      <>
        {" "}
        <td>
          <Score score={item.workQuality} />
        </td>
        <td>
          <Score score={item.workEfficiency} />
        </td>
        <td>
          <Score score={item.jobKnowledge} />
        </td>
        <td>
          <Score score={item.communicationSkills} />
        </td>
        <td>
          <Score score={item.interpersonalSkills} />
        </td>
        <td>
          <Score score={item.isoQualityManagementSystem} />
        </td>
        <td>
          <Score score={item.attendance} />
        </td>
        <td>
          <Score score={item.supervisorySkills} />
        </td>
        <td>
          <Score score={item.totalScore} />
        </td>
      </>
    );
  };
  return (
    <>
      {mode === LISTVIEW.VIEW && (
        <AppraisalForm readOnly={true} onList={handleOnList} />
      )}
      {mode === LISTVIEW.LIST && (
        <Row>
          <Card style={{ border: "1px solid black", padding: "20px" }}>
            <Card.Body>
              <div className="form-group row">
                <label className="col-4 h4">Search</label>
                <div className="col-8">
                  <input
                    type="text"
                    className="form-control"
                    onChange={handleChangeSearch}
                    value={search}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
          <Card style={{ border: "1px solid black", padding: "20px" }}>
            {pageCount > 1 && (
              <div style={{ fontSize: "small" }}>
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<FontAwesomeIcon icon={faArrowRight} />}
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={<FontAwesomeIcon icon={faArrowLeft} />}
                  renderOnZeroPageCount={null}
                  className="paginate"
                />
              </div>
            )}

            <Table striped style={{ border: "1px solid black" }}>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th className="ten  ">Name</th>
                  <th className="ten tip">A1<span className="hint">Appraiser 1</span></th>
                  <th className="ten tip">A2<span className="hint">Appraiser 2</span></th>

                  <th>
                    <span>Status</span>
                  </th>                     

                  <th className="five tip">
                    Qual<span className="hint">Work Quality</span>
                  </th>
                  <th className="five tip">
                    Efficent<span className="hint">Work Efficiency</span>
                  </th>
                  <th className="five tip">
                    Know<span className="hint">Job Knowledge</span>
                  </th>
                  <th className="five tip">
                    Comm<span className="hint">Communication Skills</span>
                  </th>
                  <th className="five tip">
                    Person<span className="hint">Interpersonal Skills</span>
                  </th>
                  <th className="five tip">
                    QMS
                    <span className="hint">ISO Quality Management Skills</span>
                  </th>
                  <th className="five tip">
                    Attend<span className="hint">Attendance</span>
                  </th>
                  <th className="five tip">
                    Sup<span className="hint">Supervisory Skills</span>
                  </th>

                  <th>Total</th>
                 
                </tr>
              </thead>
              <tbody>
                {currentItems.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      style={{ cursor: "pointer" }}
                      onClick={(ev) => {
                        handleClick(item);
                      }}
                    >
                      <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                      <td className="h4" style={{ verticalAlign: "middle" }}>
                        <Pic userid={item.userid} round={true} width="2em" />{" "}
                        {item.fullname}
                      </td>
                      <td className="h5" style={{ verticalAlign: "middle" }}>{item?.appraisal_doc?.proxy?.fullname}</td>
                      <td className="h5" style={{ verticalAlign: "middle" }}>{item?.appraisal_doc?.appraiser?.fullname}</td>

                      <td className="h4" style={{ verticalAlign: "middle" }}>
                      <StatusBall status={item.status}/>
                    </td>                      

                      {![Status.MISSING, Status.PENDING].includes(
                        item.status
                      ) && scoreSegment(item)}
                      {[Status.MISSING, Status.PENDING].includes(item.status) &&
                        blankSegment(item)}

     
                  
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
          <Card>
            <Card.Body>
                <div className="d-flex justify-content-between">
                <div><Button onClick={handleDownload}>Download</Button></div>
                    <div><Button onClick={handleBack}>Back</Button></div>
                </div>
                <div/>
            </Card.Body>

          </Card>
        </Row>
      )}
    </>
  );
};
export default AppraiseeScore;
