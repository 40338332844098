import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

// import { addDepartment, selectDepartment } from "../../store/DepartmentSlice";
import { Button, Card, Row, Col, Table } from "react-bootstrap";
import { get } from "axios";
import { Score2 as Score } from "../../components/Score";
import { YESNO, NOTRELEVANT, Status, LISTVIEW, YEAR } from "../../Constants";

import { useDispatch, useSelector } from "react-redux";
import AppraisalForm from "../AppraisalForm/AppraisalForm";
import { updateList } from "../../store/MyAppraisalListSlice";
import { updateAppraisal } from "../../store/AppraisalSlice";
import { postError } from "../../store/ApplicationSlice";
import ReactPaginate from "react-paginate";

import { useNavigate } from "react-router-dom";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Pic from "../../components/Pic";

const AppraiseeReview = (props) => {
  const list = useSelector((state) => {
    return state.MyAppraisalListSlice.list;
  });
  const username = useSelector(
    (state) => state.applicationSlice?.currentUser?.preferred_username
  );
  const navigate = useNavigate();
  const [mode, setMode] = useState(LISTVIEW.LIST);

  const itemsPerPage = 10;
  const dispatch = useDispatch();
  const handleClick = (item) => {
    // console.log(item);
    dispatch(updateAppraisal(item.appraisal_doc));
    setMode(LISTVIEW.VIEW);
  };
  const handleOnList = () => {
    setMode(LISTVIEW.LIST);
  };
  useEffect(() => {
    get("api/users/username/" + username)
      .then((res) => {
        return new Promise((resolve) => {
          resolve(res.data.userid);
        });
      })
      .then((userid) => {
        console.log(userid);
        get("api/appraisals/user/" + userid + "/year/" + YEAR).then((res) => {
          const arr = res.data;
          // console.log(arr);
          dispatch(updateList(arr));
        });
      })
      .catch((e) => {
        dispatch(postError(e));
      });
  }, []);

  const handleHome = (ev) => {
    navigate("../main");
  };
  const blankSegment = () => {
    return (
      <>
        <td colSpan="9" style={{ textAlign: "center" }}>
          APPRAISAL NOT AVAILABLE
        </td>
      </>
    );
  };

  const scoreSegment = (item) => {
    return (
      <>
        {" "}
        <td>
          <Score score={item.appraisal_doc?.workQuality} />
        </td>
        <td>
          <Score score={item.appraisal_doc?.workEfficiency} />
        </td>
        <td>
          <Score score={item.appraisal_doc?.jobKnowledge} />
        </td>
        <td>
          <Score score={item.appraisal_doc?.communicationSkills} />
        </td>
        <td>
          <Score score={item.appraisal_doc?.interpersonalSkills} />
        </td>
        <td>
          <Score score={item.appraisal_doc?.isoQualityManagementSystem} />
        </td>
        <td>
          <Score score={item.appraisal_doc?.attendance} />
        </td>
        <td>
          <Score score={item.appraisal_doc?.supervisorySkills} />
        </td>
        <td>
          <Score score={item.appraisal_doc?.totalScore} />
        </td>
      </>
    );
  };
  return (
    <>
      {mode === LISTVIEW.VIEW && (
        <AppraisalForm
          readOnly={true}
          onList={handleOnList}
          showReviewedByStaff={true}
        />
      )}
      {mode === LISTVIEW.LIST && (
        <Row>
          <Card style={{ border: "1px solid black", padding: "20px" }}>
            <Table striped style={{ border: "1px solid black" }}>
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th className="ten  ">Name</th>

                  <th className="five tip">
                    Qual<span className="hint">Work Quality</span>
                  </th>
                  <th className="five tip">
                    Efficent<span className="hint">Work Efficiency</span>
                  </th>
                  <th className="five tip">
                    Know<span className="hint">Job Knowledge</span>
                  </th>
                  <th className="five tip">
                    Comm<span className="hint">Communication Skills</span>
                  </th>
                  <th className="five tip">
                    Person<span className="hint">Interpersonal Skills</span>
                  </th>
                  <th className="five tip">
                    QMS
                    <span className="hint">ISO Quality Management Skills</span>
                  </th>
                  <th className="five tip">
                    Attend<span className="hint">Attendance</span>
                  </th>
                  <th className="five tip">
                    Sup<span className="hint">Supervisory Skills</span>
                  </th>

                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td colSpan="11">
                      {" "}
                      <span
                        style={{
                          fontSize: "large",
                          color: "green",
                          fontWeight: "500",
                        }}
                      >
                        Woohoo! Your inbox is empty.
                      </span>
                    </td>
                  </tr>
                )}
                {list.map((item, i) => {
                  return (
                    <tr
                      key={i}
                      style={{ cursor: "pointer" }}
                      onClick={(ev) => {
                        handleClick(item);
                      }}
                    >
                      <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                      <td className="h4" style={{ verticalAlign: "middle" }}>
                        <Pic userid={item.userid} round={true} width="3em" />{" "}
                        {item.fullname}
                      </td>

                      {![Status.MISSING, Status.PENDING].includes(
                        item.status
                      ) && scoreSegment(item)}
                      {[Status.MISSING, Status.PENDING].includes(item.status) &&
                        blankSegment(item)}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card>
          <Card>
            <Card.Body>
              <div className="d-flex justify-content-between">
                <Button style={{ fontWeight: "bold", color: "black" }} onClick={handleHome}>HOME</Button>
              </div>
            </Card.Body>
          </Card>
        </Row>
      )}
    </>
  );
};
export default AppraiseeReview;
