import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AppraisalListItemSmall from "./AppraisalListItemSmall";
import AppraisalListItemRow from "./AppraisalListItemRow";
import { Table, Button } from "react-bootstrap";
import { APPTITLE } from "../../Constants";
import { get } from "axios";
import { useDispatch } from "react-redux";
import { postError } from "../../store/ApplicationSlice";

const mstp = (state, ownProps) => {
  const list2 = state.MyAppraisalListSlice.list || [];
  const username = state.applicationSlice.currentUser.preferred_username;
  // console.log(list);
  const list = list2.filter(a =>{ 
    var cond1 = a?.appraisers?.proxy?.username == username;
    var cond2 = a?.appraisers?.appraiser?.username == username;
    var cond3 =  a?.appraisal_doc == null;
    var cond4 =  a?.appraisal_doc?.status != 'PENDING';
    var cond5 = a?.appraisers?.proxy == null;
   
    // cond1 im the proxy
    // cond2 im the main
    // cond3 no appraisal doc
    // cond4 appraisal_doc status pending
    // cond5 no proxy
    return cond1 || (cond2 && cond5) || !(cond2 && cond3) || !(cond2 && cond4);
    
    // return cond1
  });
  
   console.log(list);



  return { list, username };
};

const mdtp = (dispatch, ownProps) => {
  const _postError = (e) => dispatch(postError(e));
  return { postError: _postError };
};

export default connect(
  mstp,
  mdtp
)((props) => {
  const dispatch = useDispatch();
  const { onList, list, username, onView, postError } = props;
  const [userid, setUserid] = useState(null);
  useEffect(() => {
    get("api/users/username/" + username)
      .then((res) => {
        setUserid(res.data.userid);
      })
      .catch((e) => {
        postError(e);
      });
  }, [username]);
  const handleOnList = (ev) => {
    onList && onList();
  };
  const handleView = (row_no, record) => {
    onView && onView(row_no, record);
  };
  return (
    // <div style={{ minHeight: "100vh" }}>
    <>
      <div className="card title">
        <div className="card-body">
          <h1>{APPTITLE}</h1>
        </div>
      </div>
      <div className="d-none d-md-block">
        <div className="card " style={{ margin: "1em", padding: "0" }}>
          <div className="card-body">
            <Table className="t100 ">
              <thead>
                <tr>
                  <th>No.</th>
                  <th className="ten">Name</th>
                  <th className="five tip">
                    Qual<span className="hint">Work Quality</span>
                  </th>
                  <th className="five tip">
                    Efficent<span className="hint">Work Efficiency</span>
                  </th>
                  <th className="five tip">
                    Know<span className="hint">Job Knowledge</span>
                  </th>
                  <th className="five tip">
                    Comm<span className="hint">Communication Skills</span>
                  </th>
                  <th className="five tip">
                    Person<span className="hint">Interpersonal Skills</span>
                  </th>
                  <th className="five tip">
                    QMS
                    <span className="hint">ISO Quality Management Skills</span>
                  </th>
                  <th className="five tip">
                    Attend<span className="hint">Attendance</span>
                  </th>
                  <th className="five tip">
                    Sup<span className="hint">Supervisory Skills</span>
                  </th>

                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {list.length === 0 && (
                  <tr>
                    <td colSpan="10">
                      <span
                        style={{
                          fontSize: "large",
                          color: "green",
                          fontWeight: "500",
                        }}
                      >
                        Woohoo! You have nothing to fill.
                      </span>
                    </td>
                  </tr>
                )}
                {list.map((item, i) => {
                  return (
                    <AppraisalListItemRow
                      key={i}
                      row_no={i}
                      record={item}
                      iam={userid}
                      onView={handleView}
                    />
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="d-block d-md-none">
        <div className="card">
          <div className="card-body">
            {list.map((item, i) => {
              return (
                <AppraisalListItemSmall
                  key={i}
                  row_no={i}
                  record={item}
                  iam={userid}
                  onView={handleView}
                />
              );
            })}
          </div>
        </div>
      </div>

      <div className="card" style={{ margin: "1em" }}>
        <div className="card-footer">
          <Button onClick={handleOnList}>Back</Button>
        </div>
      </div>
    </>
    // </div>
  );
});
