import { createSlice } from "@reduxjs/toolkit";
import { YESNO, GRADES, Status, QuestionNos, scores } from "../Constants";
import moment from "moment";
const initialState = {
  _id: "",
  year: "2024",
  appraiser: {},
  appraisee: {},
  department: {},
  proxy: {},
  position: "",
  dateJoined: moment().valueOf(),
  workQuality: {
    grade: 0,
    relevance: YESNO.YES,
    score: 0,
    remark: "",
    remarkValid: false,
    remarkOptional: false,
    extraRemark: "",
    actionRequired: YESNO.NO,
    weight: 1,
  },
  workEfficiency: {
    grade: 0,
    relevance: YESNO.YES,
    score: 0,
    remark: "",
    remarkValid: false,
    remarkOptional: false,
    extraRemark: "",
    actionRequired: YESNO.NO,
    weight: 1,
  },
  jobKnowledge: {
    grade: 0,
    relevance: YESNO.YES,
    score: 0,
    remark: "",
    remarkValid: false,
    remarkOptional: false,
    extraRemark: "",
    actionRequired: YESNO.NO,
    weight: 1,
  },
  communicationSkills: {
    grade: 0,
    relevance: YESNO.YES,
    score: 0,
    remark: "",
    remarkValid: false,
    remarkOptional: false,
    extraRemark: "",
    actionRequired: YESNO.NO,
    weight: 1,
  },
  interpersonalSkills: {
    grade: 0,
    relevance: YESNO.YES,
    score: 0,
    remark: "",
    remarkValid: false,
    remarkOptional: false,
    extraRemark: "",
    actionRequired: YESNO.NO,
    weight: 1,
  },
  isoQualityManagementSystem: {
    grade: 0,
    relevance: YESNO.YES,
    score: 0,
    remark: "",
    remarkValid: false,
    remarkOptional: false,
    extraRemark: "",
    actionRequired: YESNO.NO,
    weight: 1,
  },
  attendance: {
    grade: 2,
    relevance: YESNO.YES,
    score: 0,
    remark: "",
    remarkValid: false,
    remarkOptional: false,
    extraRemark: "",
    actionRequired: YESNO.NO,
    weight: 1,
  },
  supervisorySkills: {
    grade: 0,
    relevance: YESNO.YES,
    score: 0,
    remark: "",
    remarkValid: false,
    remarkOptional: false,
    extraRemark: "",
    actionRequired: YESNO.NO,
    weight: 1,
  },
  totalScore: { total: 0, averageScore: 0, denominator: 0 },
  trainingRequired: YESNO.NO,
  trainingNeeded: [],
  remark: "",
  overallComment: "",
  inComplete: true,
  status: Status.PENDING,
  supervisor_date: "",
  proxy_date: "",
  staff_date: "",
};

const slice = createSlice({
  name: "appraisalSlice",
  initialState: initialState,
  reducers: {
    createNewAppraisal: (state, action) => {
      return {
        ...state,
        year: action.payload.year,
        appraisee: action.payload.appraisee,
        appraiser: action.payload.appraiser,
        status: action.payload.status,
        trainingRequired: action.payload.trainingRequired,
        date_joined: action.payload?.appraisee?.date_joined,
        position: action.payload?.appraisee?.position,
        workQuality: {
          grade: 0,
          relevance: YESNO.YES,
          score: 0,
          remark: "",
          remarkValid: false,
          remarkOptional: false,
          extraRemark: "",
          actionRequired: YESNO.NO,
          weight: 1,
        },
        workEfficiency: {
          grade: 0,
          relevance: YESNO.YES,
          score: 0,
          remark: "",
          remarkValid: false,
          remarkOptional: false,
          extraRemark: "",
          actionRequired: YESNO.NO,
          weight: 1,
        },
        jobKnowledge: {
          grade: 0,
          relevance: YESNO.YES,
          score: 0,
          remark: "",
          remarkValid: false,
          remarkOptional: false,
          extraRemark: "",
          actionRequired: YESNO.NO,
          weight: 1,
        },
        communicationSkills: {
          grade: 0,
          relevance: YESNO.YES,
          score: 0,
          remark: "",
          remarkValid: false,
          remarkOptional: false,
          extraRemark: "",
          actionRequired: YESNO.NO,
          weight: 1,
        },
        interpersonalSkills: {
          grade: 0,
          relevance: YESNO.YES,
          score: 0,
          remark: "",
          remarkValid: false,
          remarkOptional: false,
          extraRemark: "",
          actionRequired: YESNO.NO,
          weight: 1,
        },
        isoQualityManagementSystem: {
          grade: 0,
          relevance: YESNO.YES,
          score: 0,
          remark: "",
          remarkValid: false,
          remarkOptional: false,
          extraRemark: "",
          actionRequired: YESNO.NO,
          weight: 1,
        },
        attendance: {
          grade: 2,
          relevance: YESNO.YES,
          score: 0,
          remark: "",
          remarkValid: false,
          remarkOptional: false,
          extraRemark: "",
          actionRequired: YESNO.NO,
          weight: 1,
        },
        supervisorySkills: {
          grade: 0,
          relevance: YESNO.YES,
          score: 0,
          remark: "",
          remarkValid: false,
          remarkOptional: false,
          extraRemark: "",
          actionRequired: YESNO.NO,
          weight: 1,
        },
        totalScore: { total: 0, averageScore: 0, denominator: 0 },
        trainingRequired: YESNO.NO,
        trainingNeeded: [],
        remark: "",
        overallComment: "",
        inComplete: true,
        status: Status.PENDING,        
      };
    },
    updateAppraisal: (state, action) => {
      console.log(action.payload);
      let newState = action.payload;
      newState = {
        ...newState,
        workQuality: { ...newState.workQuality, remarkValid: true },
        workEfficiency: { ...newState.workEfficiency, remarkValid: true },
        jobKnowledge: {
          ...newState.jobKnowledge,
          remarkValid: true,
        },
        communicationSkills: {
          ...newState.communicationSkills,
          remarkValid: true,
        },
        interpersonalSkills: {
          ...newState.interpersonalSkills,
          remarkValid: true,
        },
        isoQualityManagementSystem: {
          ...newState.isoQualityManagementSystem,
          remarkValid: true,
        },
        attendance: { ...newState.attendance, remarkValid: true },
        supervisorySkills: { ...newState.supervisorySkills, remarkValid: true },
      };
      return { ...state, ...newState };
    },

    updateScore: (state, action) => {
      const {
        questionNo,
        grade,
        relevance,
        remark,
        remarkValid,
        remarkOptional,
        extraRemark,
        actionRequired,
      } = action.payload;
      let newState = {};
      const score = scores
        .filter((score) => {
          return score.grade === grade;
        })
        ?.shift()?.score;
      const shard = {
        grade: grade,
        relevance: relevance,
        score: score,
        remark: remark,
        remarkValid: remarkValid,
        remarkOptional: remarkOptional,
        extraRemark: extraRemark,
        actionRequired: actionRequired,
      };

      switch (questionNo) {
        case QuestionNos.One:
          newState = {
            ...state,
            workQuality: { ...state.workQuality, ...shard },
          };
          break;
        case QuestionNos.Two:
          newState = {
            ...state,
            workEfficiency: { ...state.workEfficiency, ...shard },
          };
          break;
        case QuestionNos.Three:
          newState = {
            ...state,
            jobKnowledge: { ...state.jobKnowledge, ...shard },
          };
          break;
        case QuestionNos.Four:
          newState = {
            ...state,
            communicationSkills: { ...state.communicationSkills, ...shard },
          };
          break;
        case QuestionNos.Five:
          newState = {
            ...state,
            interpersonalSkills: { ...state.interpersonalSkills, ...shard },
          };
          break;
        case QuestionNos.Six:
          newState = {
            ...state,
            isoQualityManagementSystem: {
              ...state.isoQualityManagementSystem,
              ...shard,
            },
          };
          break;
        case QuestionNos.Seven:
          newState = {
            ...state,
            attendance: { ...state.attendance, ...shard },
          };
          break;
        case QuestionNos.Eight:
          newState = {
            ...state,
            supervisorySkills: { ...state.supervisorySkills, ...shard },
          };
          break;
        default:
          newState = { ...state };
      }
      let totalScore = 0;
      let totalDenominator = 0;
      totalScore +=
        newState.workQuality.relevance === YESNO.YES
          ? +newState.workQuality.score
          : 0;
      totalDenominator += newState.workQuality.relevance === YESNO.YES ? 1 : 0;

      totalScore +=
        newState.workEfficiency.relevance === YESNO.YES
          ? +newState.workEfficiency.score
          : 0;
      totalDenominator +=
        newState.workEfficiency.relevance === YESNO.YES ? 1 : 0;

      totalScore +=
        newState.jobKnowledge.relevance === YESNO.YES
          ? +newState.jobKnowledge.score
          : 0;
      totalDenominator += newState.jobKnowledge.relevance === YESNO.YES ? 1 : 0;

      totalScore +=
        newState.communicationSkills.relevance === YESNO.YES
          ? +newState.communicationSkills.score
          : 0;
      totalDenominator +=
        newState.communicationSkills.relevance === YESNO.YES ? 1 : 0;

      totalScore +=
        newState.interpersonalSkills.relevance === YESNO.YES
          ? +newState.interpersonalSkills.score
          : 0;
      totalDenominator +=
        newState.interpersonalSkills.relevance === YESNO.YES ? 1 : 0;

      totalScore +=
        newState.isoQualityManagementSystem.relevance === YESNO.YES
          ? +newState.isoQualityManagementSystem.score
          : 0;
      totalDenominator +=
        newState.isoQualityManagementSystem.relevance === YESNO.YES ? 1 : 0;

      totalScore +=
        newState.attendance.relevance === YESNO.YES
          ? +newState.attendance.score
          : 0;
      totalDenominator += newState.attendance.relevance === YESNO.YES ? 1 : 0;

      totalScore +=
        newState.supervisorySkills.relevance === YESNO.YES
          ? +newState.supervisorySkills.score
          : 0;
      totalDenominator +=
        newState.supervisorySkills.relevance === YESNO.YES ? 1 : 0;
      const averageScore =
        totalDenominator > 0
          ? Math.ceil(totalScore / totalDenominator)
          : undefined;

      const inComplete = !(
        newState.workQuality.remarkValid &&
        newState.workEfficiency.remarkValid &&
        newState.jobKnowledge.remarkValid &&
        newState.communicationSkills.remarkValid &&
        newState.interpersonalSkills.remarkValid &&
        newState.isoQualityManagementSystem.remarkValid &&
        newState.attendance.remarkValid &&
        newState.supervisorySkills.remarkValid
      );
      newState = { ...newState, inComplete: inComplete };
      // console.log(inComplete);
      return {
        ...newState,
        totalScore: {
          total: totalScore,
          averageScore: averageScore,
          denominator: totalDenominator,
        },
      };
    },
    updateTrainingRequired: (state, action) => {
      return { ...state, trainingRequired: action.payload };
    },
    updateTrainingNeeded: (state, action) => {
      return { ...state, trainingNeeded: action.payload };
    },
    updateOverallComment: (state, action) => {
      return { ...state, overallComment: action.payload };
    },
    updateRemark: (state, action) => {
      return { ...state, remark: action.payload };
    },

    setApplicationFormIncomplete: (state, action) => {
      return { ...state, applicationFormIncomplete: action.payload };
    },

    resetAppraisee(state) {
      state.appraisee = {}; // Reset appraisee to an empty object
      state.overallComment = "";
      state.remark = "";
      state.supervisor_date = "";
      state.staff_date = "";
      state.proxy_date = "";
    },
  },
});

export const {
  createNewAppraisal,
  updateAppraisal,
  updateScore,
  updateTrainingRequired,
  updateTrainingNeeded,
  updateOverallComment,
  updateRemark,
  resetAppraisee
} = slice.actions;
export default slice.reducer;
