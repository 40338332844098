import React from "react";
import AppraisalFormHeader from "./AppraisalFormHeader";
import AppraisalPart1 from "./AppraisalPart1";
import AppraisalPart2 from "./AppraisalPart2";
import { connect, useDispatch } from "react-redux";
import { updateList } from "../../store/MyAppraisalListSlice";
import { postError } from "../../store/ApplicationSlice";
import { Status } from "../../Constants";
import { APPTITLE } from "../../Constants";
import { post } from "axios";
import { Row, Col } from "react-bootstrap";
import { get } from "axios";
import { resetAppraisee } from "../../store/AppraisalSlice";
const mapStateToProps = (state, ownProps) => {
  const appraisal = state.appraisalSlice;
  const appraisalList = state.MyAppraisalListSlice.list;
  const username = state.applicationSlice?.currentUser?.preferred_username;

  return { appraisal, appraisalList, username };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const _postError = (e) => dispatch(postError(e));
  const _updateList = (list) => dispatch(updateList(list));
  return { postError: _postError, updateList: _updateList };
};

const AppraisalForm = (props) => {
  const dispatch = useDispatch();
  const {
    username,
    showReviewedByStaff,
    appraisal,
    appraisalList,
    updateList,
    onList,
    postError,
  } = props;
  const readOnly = props.readOnly === undefined ? false : props.readOnly;
  const saveToServer = async (newAppraisal, alertMessage) => {
    return await new Promise((resolve, reject) => {
      const newAppraisal2 = { ...newAppraisal };
      delete newAppraisal2["_id"];

      post("api/appraisals", newAppraisal2)
        .then((res) => {
          // alert("Record Saved as Draft");
          alert(alertMessage);
          resolve();
        })
        .catch((e) => {
          reject(e);
        });
    });
  };

  const handleSave = (alertMessage, newStatus = undefined) => {
    newStatus = newStatus !== undefined ? newStatus : appraisal?.status;
    const newAppraisal = { ...appraisal, status: newStatus };

    saveToServer(newAppraisal, alertMessage)
      .then(() => {
        const appraiseeid = appraisal.appraisee?.userid;
        const appraiserid = appraisal.appraiser?.userid;

        if (appraiseeid !== undefined && appraiserid !== undefined) {
          const newAppraisalList = appraisalList.map((item) => {
            if (
              item.userid === appraiseeid &&
              item.appraisers?.appraiser?.userid === appraiserid
            ) {
              return { ...item, appraisal_doc: { ...newAppraisal } };
            } else {
              return item;
            }
          });

          updateList(newAppraisalList);
        }
        onList && onList();
      })
      .catch((e) => {
        window.scroll(0, 0);
        postError(e);
      });
  };
  const handleBack = () => {
    dispatch(resetAppraisee());
    onList && onList();
  };
  const handleUpdate = (alertMessage) => {
    if (appraisal.inComplete) {
      alert("Please fill up all the required information on this form");
      window.scroll(0, 0);
    } else {
      get("api/users/username/" + username)
        .then((res) => {
          return new Promise((resolve) => {
            resolve(res.data.userid);
          });
        })
        .then((userid) => {
          if (
            userid === appraisal?.appraiser?.userid &&
            [
              Status.MISSING,
              Status.PENDING,
              Status.WAITFORPROXY,
              Status.WAITFORSUPERVISOR,
              Status.PROXYSUBMITTED,
              Status.SUPERVISORSUBMITTED,
            ].includes(appraisal.status)
          ) {
            handleSave(alertMessage, Status.SUPERVISORSUBMITTED);
          } else if (
            userid === appraisal?.proxy?.userid &&
            [
              Status.MISSING,
              Status.PENDING,
              Status.WAITFORPROXY,
              // Status.WAITFORSUPERVISOR, error on proxy submit because button is still available for submit
              Status.PROXYSUBMITTED,
            ].includes(appraisal.status)
          ) {
            handleSave(alertMessage, Status.PROXYSUBMITTED);
          } else {
            postError("Unable to update. Please contract HR Department");
          }
        })
        .catch((e) => {
          postError(e);
        });

      // console.log(username);

      //
      // handleSave(Status.SUPERVISORSUBMITTED);
    }
  };
  const handleSignOff = () => {
    if (appraisal.status === undefined) {
      alert("Thank you for participating in Staff Annual Performance Review");
      handleSave(Status.STAFFREVIEWED);
    } else if (
      [
        Status.MISSING,
        Status.PENDING,
        Status.SUPERVISORSUBMITTED,
        Status.PROXYSUBMITTED,
      ].includes(appraisal.status)
    ) {
      alert("Thank you for participating in Staff Annual Performance Review");
      handleSave(Status.STAFFREVIEWED);
    } else {
      alert("Thank you for participating in Staff Annual Performance Review");
      handleSave(appraisal.status);
    }
  };
  return (
    <Row>
      <Col md={1} className="d-none d-md-block"></Col>
      <Col xs={12} md={10} lg={9}>
        <div className="card">
          <div className="card-body">
            <h1>{APPTITLE}</h1>

            <AppraisalFormHeader readOnly={readOnly} />
            <AppraisalPart1 readOnly={readOnly} />
            <AppraisalPart2 readOnly={readOnly} />
            <div className="card-footer">
              <div className="d-flex justify-content-between">
                <div>
                  <button className="btn btn-warning" onClick={handleBack}>
                    BACK
                  </button>
                </div>
                <div>
                  {showReviewedByStaff === true && (
                    <button
                      className="btn btn-primary me-1"
                      onClick={(ev) => handleSignOff()}
                    >
                      Acknowledged
                    </button>
                  )}
                  {readOnly === true && <></>}
                  {readOnly === false && (
                    <>
                      <button
                        className="btn btn-primary me-1"
                        onClick={(ev) => handleSave("Record Saved as Draft")}
                      >
                        SAVE AS DRAFT
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={(ev) => handleUpdate("Record Submitted")}
                      >
                        SUBMIT
                      </button>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(AppraisalForm);
