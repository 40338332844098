import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Row, Col, Table } from "react-bootstrap";
import { updateList } from "../../store/MyAppraisalListSlice";
import { LISTVIEW } from "../../Constants";
import { postError } from "../../store/ApplicationSlice";
import { get } from "axios";
import Score from "../../components/Score";
import DepartmentScoreSummary from "./DepartmentScoreSummary";
import DepartmentScoreDetail from "./DepartmentScoreDetail";
// import "./DepartmentForm.css";
// import "./stats.css";

const DepartmentScore = (props) => {
  const dispatch = useDispatch();

  const [records, setRecords] = useState([]);
  const [mode, setMode] = useState(LISTVIEW.LIST);
  useEffect(() => {
    get("api/departments/stats/score/2024")
      .then((res) => {
        console.log(res.data);
        setRecords(res.data);
      })
      .catch((e) => {
        dispatch(postError(e));
      });
  }, []);
  const handleView = (ev) => {
    get("api/departments/details/department/IT/year/2024")
      .then((res) => {
        const arr = res.data;
        console.log(arr);
        dispatch(updateList(arr));
      })
      .catch((e) => {
        dispatch(postError(e));
      });

    setMode(LISTVIEW.VIEW);
  };
  const handleList = (ev) => {
    setMode(LISTVIEW.LIST);
  };
  return (
    <>
      {mode === LISTVIEW.LIST && <DepartmentScoreSummary onView={handleView} />}
      {mode === LISTVIEW.VIEW && <DepartmentScoreDetail onList={handleList} />}
    </>
  );
};

export default DepartmentScore;
