import React, { useState, useEffect, useCallback } from "react";
import { YESNO, Trainings, Status } from "../../Constants";
import { connect } from "react-redux";
import { get } from "axios";

import {
  updateRemark,
  updateTrainingRequired,
  updateTrainingNeeded,
  updateOverallComment,
} from "../../store/AppraisalSlice";
const mapStateToProps = (state, ownProps) => {
  const _appraisal = state.appraisalSlice;
  const _appraisee = state.appraisalSlice.appraisee;
  const _trainingRequired = state.appraisalSlice.trainingRequired;
  const _trainingNeeded = state.appraisalSlice.trainingNeeded;
  const _overallComment = state.appraisalSlice.overallComment;
  const _remark = state.appraisalSlice.remark;
  // console.log(state.appraisalSlice);
  const _proxyid = state.appraisalSlice.proxy.userid;
  const _appraiserid = state.appraisalSlice.appraiser.userid;
  const _status = state.appraisalSlice.status;
  return {
    appraisal: _appraisal,
    appraisee: _appraisee,
    trainingRequired: _trainingRequired,
    trainingNeeded: _trainingNeeded,
    overallComment: _overallComment,
    remark: _remark,
    proxyid: _proxyid,
    appraiserid: _appraiserid,
    status: _status,
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  const _updateRemark = (value) => dispatch(updateRemark(value));
  const _updateTrainingRequired = (value) =>
    dispatch(updateTrainingRequired(value));
  const _updateTrainingNeeded = (value) =>
    dispatch(updateTrainingNeeded(value));
  const _updateOverallComment = (value) =>
    dispatch(updateOverallComment(value));
  return {
    updateRemark: _updateRemark,
    updateTrainingRequired: _updateTrainingRequired,
    updateTrainingNeeded: _updateTrainingNeeded,
    updateOverallComment: _updateOverallComment,
  };
};

const AppraisalPart2 = (props) => {
  const {
    appraisal,
    appraisee,
    readOnly,
    trainingRequired,
    overallComment,
    remark,
    updateTrainingRequired,
    updateOverallComment,
    updateRemark,
    appraiserid,
    proxyid,
    status,
  } = props;
  const trainings = props.trainingNeeded;

  console.log(status);

  const [appraiserName, setAppraiserName] = useState("");
  const [proxyName, setProxyName] = useState(false);

  const getName = useCallback((userid, setName, isMounted) => {
    get("api/users/user/" + userid)
      .then((res) => {
        if (isMounted) {
          const user = res.data.shift();
          setName(user.fullname);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  });

  useEffect(() => {
    let isMounted = true;
    getName(appraiserid, setAppraiserName, isMounted);
    getName(proxyid, setProxyName, isMounted);
    return () => {
      isMounted = false;
    };
  }, []);
  const fnTrainingNeeded = (training) => {
    return (
      trainings.filter((t) => {
        return training === t;
      }).length > 0
    );
  };
  const updateTrainingNeeded = props.updateTrainingNeeded;
  const handleUpdateTrainingNeeded = (training) => {
    if (readOnly) return;
    // console.log(51);
    // console.log(trainings);
    const cnt = trainings.filter((t) => {
      return training === t;
    }).length;
    if (cnt === 0) {
      const newTrainings = [...trainings, training];
      updateTrainingNeeded(newTrainings);
    } else {
      const newTrainings = trainings.filter((t) => {
        return training !== t;
      });
      updateTrainingNeeded(newTrainings);
    }
  };

  const handleTrainingRequired = () => {
    if (readOnly) return;
    updateTrainingRequired(
      trainingRequired === YESNO.YES ? YESNO.NO : YESNO.YES
    );
  };
  const InputCheckBox = (props) => {
    const training = props.training;

    return (
      <div className="form-check">
        <input
          type="checkbox"
          onChange={() => handleUpdateTrainingNeeded(training)}
          checked={fnTrainingNeeded(training)}
        ></input>
        <label>{training}</label>
      </div>
    );
  };
  const handleChangeRemark = (ev) => {
    if (readOnly) return;
    updateRemark(ev.target.value);
  };
  const handleChangeOverallComment = (ev) => {
    if (readOnly) return;
    updateOverallComment(ev.target.value);
  };

  return (
    <>
      <div
        className="card"
        style={{
          border: "1px solid black",
          boxShadow: "5px 5px 10px gray",
          backgroundColor: "GhostWhite",
          marginBottom: "1.5em",
        }}
      >
        <div className="card-body mt-5">
          <h4>PART II: COMPETENCY STATUS</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-8">
              Any action / training needs for work performance improvement and
              career development?
            </div>
            <div className="col-4" style={{ fontSize: "small" }}>
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  id="ActionRequired"
                  className="form-check-input"
                  onChange={handleTrainingRequired}
                  checked={trainingRequired === YESNO.YES}
                ></input>
                <label className="form-check-label">Training Required</label>
              </div>
            </div>
          </div>
          {trainingRequired === YESNO.YES && (
            <div
              className="row"
              style={trainingRequired === YESNO.YES ? {} : {}}
            >
              <div className="col-12 col-md-6">
                <div className="card">
                  <div className="card-header">
                    <h5>Analytical/Conceptual Skills</h5>
                  </div>
                  <div className="card-body">
                    <InputCheckBox training={Trainings.ProblemSolving} />
                    <InputCheckBox
                      training={Trainings.CriticalThinkingSkills}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="card">
                  <div className="card-header">
                    <h5>Personal Productivity</h5>
                  </div>
                  <div className="card-body">
                    <InputCheckBox
                      training={Trainings.MasteringEmotionalIntelligence}
                    />
                    <InputCheckBox
                      training={Trainings.EffectiveInterpersonalSkill}
                    />
                    <InputCheckBox training={Trainings.SupervisorySkill} />
                    <InputCheckBox training={Trainings.LeadershipSkill} />
                    <InputCheckBox
                      training={Trainings.QualityManagementSystem}
                    />
                    <InputCheckBox training={Trainings.StressManagement} />
                    <InputCheckBox training={Trainings.OrganizationSkill} />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="card">
                  <div className="card-header">
                    <h5>Communication Skills</h5>
                  </div>
                  <div className="card-body">
                    <InputCheckBox training={Trainings.BusinessEnglishSkill} />
                    <InputCheckBox training={Trainings.PresentationSkill} />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="card">
                  <div className="card-header">
                    <h5>ICT Skills</h5>
                  </div>
                  <div className="card-body">
                    <InputCheckBox training={Trainings.ComputerLiteracy} />
                    <InputCheckBox
                      training={Trainings.OfficeProductivitySpreadsheet}
                    />
                    <InputCheckBox
                      training={Trainings.OfficeProductivityDocument}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-12">
              <h5>Remarks</h5>
              <textarea
                rows={4}
                className="form-control"
                onChange={handleChangeRemark}
                value={remark}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div
        className="card"
        style={{
          border: "1px solid black",
          boxShadow: "5px 5px 10px gray",
          backgroundColor: "GhostWhite",
          marginBottom: "1.5em",
        }}
      >
        <div className="card-body mt-5">
          <div className="row">
            <div className="col-12">
              <h4>PART III : OVERALL COMMENTS / AREA FOR IMPROVEMENT </h4>
              <textarea
                rows={4}
                className="form-control"
                onChange={handleChangeOverallComment}
                value={overallComment}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="d-flex justify-content-end">
            {/* <div /> */}
            {/* <div>
              {status === "STAFFREVIEWED" && (
                <>
                  <div
                    className="card"
                    style={{
                      margin: "1em",
                      borderRadius: "1em",
                      border: "1px solid maroon",
                    }}
                  >
                    {[
                      Status.SUPERVISORSUBMITTED,
                      Status.WAITFORSTAFF,
                      Status.STAFFREVIEWED,
                      Status.CLOSED,
                    ].includes(status) && (
                      <div className="card-body">
                        <div style={{ fontSize: "small", fontWeight: "100" }}>
                          <div><span style={{ fontWeight: "bold" }}>Staff Reviewed</span></div>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
              <div>
                <div
                  className="card"
                  style={{
                    margin: "1em",
                    borderRadius: "1em",
                    border: "1px solid maroon",
                  }}
                >
                  {[
                    Status.SUPERVISORSUBMITTED,
                    Status.WAITFORSTAFF,
                    Status.STAFFREVIEWED,
                    Status.CLOSED,
                  ].includes(status) && (
                    <div className="card-body">
                      <div style={{ fontSize: "small", fontWeight: "100" }}>
                        {proxyName && <div>Prepared by: {proxyName} </div>}
                        {appraiserName && (
                          // <div><strong>Appraised by: {appraiserName}</strong></div>
                          <div><span style={{ fontWeight: "bold" }}>Appraised by: {appraiserName}</span></div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div> */}
          {/* </div> */}

          <div className="d-flex justify-content-end">
            {/* Right-aligned Staff Reviewed card */}
            {/* <div>
              {status === "STAFFREVIEWED" && (
                <div
                  className="card"
                  style={{
                    margin: "1em",
                    borderRadius: "1em",
                    border: "1px solid maroon",
                  }}
                >
                  {[
                    Status.SUPERVISORSUBMITTED,
                    Status.WAITFORSTAFF,
                    Status.STAFFREVIEWED,
                    Status.CLOSED,
                  ].includes(status) && (
                    <div className="card-body">
                      <div style={{ fontSize: "small", fontWeight: "100" }}>
                        <div>
                          <span style={{ fontWeight: "bold" }}>Staff Reviewed</span>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div> */}

            {/* Right-aligned Appraised by card */}
            <div>
              <div
                className="card"
                style={{
                  margin: "1em",
                  borderRadius: "1em",
                  border: "1px solid maroon",
                }}
              >
                {[
                  Status.SUPERVISORSUBMITTED,
                  Status.WAITFORSTAFF,
                  Status.STAFFREVIEWED,
                  Status.CLOSED,
                ].includes(status) && (
                  <div className="card-body">
                    <div style={{ fontSize: "small", fontWeight: "100" }}>
                      {proxyName && <div>Prepared by: {proxyName} </div>}
                      {/* {appraiserName && (
                        <div>
                          <span style={{ fontWeight: "bold" }}>Appraised by: {appraiserName} ({new Date(appraisal.supervisor_date).toLocaleDateString('en-GB')})</span>
                        </div>
                      )}
                      {status === "STAFFREVIEWED" && (
                        <div>
                          <span style={{ fontWeight: "bold" }}>Acknowledged by: {appraisee.fullname} ({new Date(appraisal.staff_date).toLocaleDateString('en-GB')})</span>
                        </div>
                      )} */}
                      {appraiserName && (
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Appraised by: {appraiserName}
                            {appraisal.supervisor_date && ` (${new Date(appraisal.supervisor_date).toLocaleDateString('en-GB')})`}
                          </span>
                        </div>
                      )}

                      {status === "STAFFREVIEWED" && appraisee.fullname && (
                        <div>
                          <span style={{ fontWeight: "bold" }}>
                            Acknowledged by: {appraisee.fullname}
                            {appraisal.staff_date && ` (${new Date(appraisal.staff_date).toLocaleDateString('en-GB')})`}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppraisalPart2);
