import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Row, Col, Table } from "react-bootstrap";
import { updateList } from "../../store/MyAppraisalListSlice";
import { useNavigate } from "react-router-dom";
import { postError } from "../../store/ApplicationSlice";
import { get } from "axios";
import Score from "../../components/Score";
// import "./DepartmentForm.css";
// import "./stats.css";

const DepartmentScoreSummary = (props) => {
  const navigate = useNavigate();
  const { onView } = props;
  const dispatch = useDispatch();
  const postError = useSelector((state) => state.applicationSlice.postError);

  const [records, setRecords] = useState([]);
  useEffect(() => {
    get("api/departments/stats/score/2024")
      .then((res) => {
        console.log(res.data);
        setRecords(res.data);
      })
      .catch((e) => {
        dispatch(postError(e));
      });
  }, []);
  const handleClick = (item) => {
    console.log(item);
    get("api/appraisers/user/4ca94a9e-dd50-4d72-b6db-1038756c8c0f")
      .then((res) => {
        console.log(res);
        return new Promise((resolve) => {
          const arr = res.data;
          console.log(arr);
          dispatch(updateList(arr));
          resolve();
        });
      })
      .then(() => {
        console.log(38);
        onView && onView();
      })
      .catch((e) => {
        dispatch(postError(e));
      });
  };
  const handleBack = (ev) => {
    navigate("../main");
  };
  return (
    <Row>
      {/* <Col md={1} className="d-none d-md-block"></Col>
      <Col xs={12} md={10} lg={9}> */}
      <Card style={{ border: "1px solid black", padding: "20px" }}>
        <Table striped style={{ border: "1px solid black" }}>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th className="ten  ">Department</th>
              <th className="five tip  ">
                No<span className="hint"># of Appraisals</span>
              </th>
              <th className="five tip">
                Qual<span className="hint">Work Quality</span>
              </th>
              <th className="five tip">
                Efficent<span className="hint">Work Efficiency</span>
              </th>
              <th className="five tip">
                Know<span className="hint">Job Knowledge</span>
              </th>
              <th className="five tip">
                Comm<span className="hint">Communication Skills</span>
              </th>
              <th className="five tip">
                Person<span className="hint">Interpersonal Skills</span>
              </th>
              <th className="five tip">
                QMS
                <span className="hint">ISO Quality Management Skills</span>
              </th>
              <th className="five tip">
                Attend<span className="hint">Attendance</span>
              </th>
              <th className="five tip">
                Sup<span className="hint">Supervisory Skills</span>
              </th>

              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {records.map((item, i) => {
              return (
                <tr
                  key={i}
                  style={{ cursor: "pointer" }}
                  onClick={(ev) => {
                    handleClick(item);
                  }}
                >
                  <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                  <td className="h4" style={{ verticalAlign: "middle" }}>
                    {item._id?.name}
                  </td>
                  <td
                    className="h4"
                    style={{
                      verticalAlign: "middle",
                      paddingLeft: "1vw",
                      paddingRight: "1vw",
                    }}
                  >
                    {item.cnt}
                  </td>
                  <td>
                    <Score score={item.workQuality} />
                  </td>
                  <td>
                    <Score score={item.workEfficiency} />
                  </td>
                  <td>
                    <Score score={item.jobKnowledge} />
                  </td>
                  <td>
                    <Score score={item.communicationSkills} />
                  </td>
                  <td>
                    <Score score={item.interpersonalSkills} />
                  </td>
                  <td>
                    <Score score={item.isoQualityManagementSystem} />
                  </td>
                  <td>
                    <Score score={item.attendance} />
                  </td>
                  <td>
                    <Score score={item.supervisorySkills} />
                  </td>
                  <td>
                    <Score score={item.totalScore} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Card>
      <Card>
        <Card.Body>
          <div className="d-flex justify-content-between">
            <div>
              <button style={{ fontWeight: "bold" }} className="btn btn-warning" onClick={handleBack}>
                BACK
              </button>
            </div>
            <div />
          </div>
        </Card.Body>
      </Card>
      {/* </Col> */}
    </Row>
  );
};

export default DepartmentScoreSummary;
