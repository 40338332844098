import React, { useState, useCallback } from "react";
import {
  STAFFS,
  DEPARTMENTS,
  DEPARTMENT_STAFFS,
  APPRAISERS,
  Status,
  COMPANY_STAFFS,
  COMPANIES
} from "../Constants";
import moment from "moment";
import { get, post } from "axios";

const Foobar = (props) => {
  const [done, setDone] = useState(false);
  const [badNames, setBadnames] = useState([]);

    const handleClick4444 = (ev) => {
      // const test = APPRAISERS.splice(0, 5);
      const n1 = APPRAISERS.map((a) => {
        if (a.proxy === "") {
          const b = { ...a };
          delete b.proxy;
          return b;
        } else {
          return a;
        }
      });

      const n2 = n1.map((a) => {
        //   console.log(a);
        const fullname = a.staff;
        return post("api/users/findByName/year/2024", {
          fullname: fullname,
        }).then((response) => {
          const user = response.data;
          return { ...a, ...user, appraisers: [] };
        });
      });
      Promise.all(n2)
        .then((a) => {
          return a.map((b) => {
            const appraiser = b.appraiser;
            return post("api/users/findByName/year/2024", {
              fullname: appraiser,
            }).then((response) => {
              if (response.data === "") {
                return appraiser;
              } else {
                return null;
              }
            });
          });
        }).then((d)=>{
          return Promise.all(d);
        })
        .then((c) => {
          const newArr = c.filter((value, index, self) => {
            return self.indexOf(value) === index;
          });
          console.log(newArr);
        });
    };

  const handleClick3 = async (ev) => {
    let companies = [];
    await get("api/companies/year/2024").then((response) => {
      companies = response.data.map((r) => {
        let s = r;
        delete s.staff_doc;
        return s;
      });
    });
    const promises = COMPANY_STAFFS.map((ds) => {
      const fullname = ds.staff;
      return post("api/users/findByName/year/2024", {
        fullname: fullname,
      }).then((res) => {
        if (res.data !== "") {
          return { ...ds, userid: res.data.userid };
        } else {
          console.warn(fullname);
          return ds;
        }
      });
    });
    Promise.all(promises).then((result) => {
      const newCompanies = companies.map((company) => {
        const filtered = result
          .filter((r) => {
            return r.company === company.name;
          })
          .map((s) => {
            delete s.company;
            delete s.staff;
            return s;
          });
        return { ...company, staffs: filtered };
      });
      post("api/companies/year/2024", newCompanies);
      console.log(newCompanies);
    });
  };

  const handleAssignAppraiserClick = (ev) => {
    // const test = APPRAISERS.filter((a) => {
    //   return a.appraiser === "YONG SERN PIAT";
    // });
    const n1 = APPRAISERS.map((a) => {
      if (a.proxy === "") {
        const b = { ...a };
        delete b.proxy;
        console.log(b)
        return b;
      } else {
        console.log(a)
        return a;
      }
    });

    const n2 = n1.map((a) => {
      console.log(a);
      const fullname = a.staff;
      return post("api/users/findByName/year/2024", {
        fullname: fullname,
      }).then((response) => {
        const user = response.data;
        console.log(user);
        return { ...a, ...user, appraisers: [] };
      });
    });
    Promise.all(n2)
      .then((a) => {
        return a.map((b) => {
          const appraiser = b.appraiser;
          return post("api/users/findByName/year/2024", {
            fullname: appraiser,
          }).then((response) => {
            const appraiser2 = response.data;
            console.log(appraiser2)
            return {
              ...b,
              appraisers: [
                { appraiser: appraiser2, score: 0, status: Status.PENDING },
              ],
            };
          });
        });
      })
      .then((c) => {
        return Promise.all(c);
      })
      .then((e) => {
        console.log(e);
        return e.map((f) => {
          const proxy = f.proxy;
          if (proxy === undefined) {
            return f;
          } else {
            console.log(proxy);
            return post("api/users/findByName/year/2024", {
              fullname: proxy,
            }).then((response) => {
              const appraiser = f.appraisers[0];
              const proxy = response.data;
              return {
                ...f,
                appraisers: [{ ...appraiser, proxy: proxy }],
              };
            });
          }
        });
      })
      .then((g) => {
        console.log(g);
        return Promise.all(g);
      })
      .then((h) => {
        console.log(h);
        const newArr = h.map((i) => {
          const j = i;
          delete j.appraiser;
          delete j.proxy;
          delete j.staff;
          return { ...j, status: Status.PENDING };
        });
        console.log(138);
        console.log(newArr);
        return post("api/appraisees", newArr);
        // post("api/appraisees", newArr);
      })
      .then((x) => {
        console.log(x);
      });
  };

    const handleClick4 = async (ev) => {
      let departments = [];
      await get("api/departments/year/2024").then((response) => {
        departments = response.data.map((r) => {
          let s = r;
          delete s.staff_doc;
          return s;
        });
      });
      const promises = DEPARTMENT_STAFFS.map((ds) => {
        const fullname = ds.staff;
        return post("api/users/findByName/year/2024", {
          fullname: fullname,
        }).then((res) => {
          if (res.data !== "") {
            return { ...ds, userid: res.data.userid };
          } else {
            console.warn(fullname);
            return ds;
          }
        });
      });
      Promise.all(promises).then((result) => {
        const newDepartments = departments.map((department) => {
          const filtered = result
            .filter((r) => {
              return r.department === department.name;
            })
            .map((s) => {
              delete s.department;
              delete s.staff;
              return s;
            });
          return { ...department, staffs: filtered };
        });
        post("api/departments/year/2024", newDepartments);
        console.log(newDepartments);
      });

    };

    const handleClick5 = (ev) => {
      post("api/departments/year/2024", DEPARTMENTS);
    };
    
    const handleClick6 = (ev) => {
      const staffs = STAFFS.map((staff) => {
        return {
          ...staff,
          position: staff.position || "",
          job_grade: staff.job_grade || "",
          // date_joined: moment(staff.date_joined, "DD/MM/YYYY").valueOf() || "",
        };
      });
      // const promises = staffs.slice(0, 30).map((staff) => {
      const promises = staffs.map((staff) => {
        const fullname = staff.fullname;
        return post("api/users/findByName/year/2024/", {
          fullname: fullname,
        })
          .then((response) => {
            const existingRecord = response.data;
            if (existingRecord === "") {
              console.warn(fullname);
              return null;
            }
            const newRecord = { ...existingRecord, ...staff };
            return newRecord;
          })
          .then((newRecord) => {
            if (newRecord === null) return null;
            return post("api/users", newRecord);
          })
          .then((result) => {
            if (result === null) {
              console.log(fullname);
              return fullname;
            } else {
              return null;
            }
          });
      });
      Promise.all(promises).then((r) => {
        const newArr = r.filter((i) => {
          return i !== null;
        });
        setBadnames(newArr);
      });
    };

    const handleClick7 = (ev) => {
      const staffs = STAFFS.map((staff) => {
        return {
          ...staff,
          position: staff.position || "",
          job_grade: staff.job_grade || "",
          // date_joined: moment(staff.date_joined, "DD/MM/YYYY").valueOf() || "",
        };
      });
      const bar = new Promise((resolve, reject) => {
        staffs.slice(20, 22).forEach((staff) => {
          const fullname = staff.fullname;
            post("api/users/findByName/year/2024/", {
            fullname: fullname,
          })
            .then((response) => {

              const existingRecord = response.data;
              const newRecord = { ...existingRecord, ...staff };
              return newRecord;
            })
            .then((newRecord) => {
              return post("api/users", newRecord);
            })
            .then((result) => {
              console.log(result.data);
              resolve(result);
            });
        });
      });
      bar.then((r) => {
        console.log(r);
      });
    };

    const handleClick8 = (ev) => {
      const staffs = STAFFS.map((staff) => {
        return {
          ...staff,
          position: staff.position || "",
          job_grade: staff.job_grade || "",
          // date_joined: moment(staff.date_joined, "DD/MM/YYYY").valueOf() || "",
        };
      });

      const promises = [];

      let b = [];
      let a = 0;
      staffs.forEach(async (staff) => {
        const fullname = staff.fullname;

        promises.push(
          post("api/users/findByName/year/2024/", {
            fullname: fullname,
          }).then((response) => {
            const data = response.data;
            if (data === "") {
              return new Promise((resolve) => resolve(fullname));
            }
          })
        );
      });
      Promise.all(promises).then((names) => {
        console.log(names);
        const newbadNames = names.filter((n) => {
          return (n !== undefined)
        });
        console.log(newbadNames);
        setBadnames(newbadNames);
      });
    };

    const handleUploadCompanies = (ev) => {
      const body = COMPANIES.map((company) => {
        const companyid = company.replace(/\s+/g, "").toLowerCase();
        const body = {
          companyid: companyid,
          name: company,
        };
        return body;
      });

      post("api/Companies/year/2024", body)
        .then((res) => {
          console.log(res);
          setDone(true);
        })
        .catch((e) => console.log(e));
    };

    const handleClick9 = (ev) => {
      const staffs = STAFFS.map((staff) => {
        return {
          ...staff
        };
      });
      const promises = staffs.map((staff) => {
        const fullname = staff.fullname;
        return post("api/users/findByName/year/2024/", {
          fullname: fullname,
        })
          .then((response) => {
            const existingRecord = response.data;

            if (existingRecord === "") {
              // if no data give warning
              console.warn(`No record found for: ${fullname}`);
              return null;
            }

            const newRecord = { ...existingRecord, ...staff };
            return newRecord;
          })
          .then((newRecord) => {
            if (newRecord === null) return null;
            return post("api/users", newRecord);
          })
      });
      Promise.all(promises).then(() => {
        console.log("All staff positions and job grades updated");
      });
    };

    const handleClick10 = (ev) => {
      const n1 = APPRAISERS.map((a) => {
        if (a.proxy === "") {
          const b = { ...a };
          delete b.proxy;
          console.log(b)
          return b;
        } else {
          console.log(a)
          return a;
        }
      });
  
      const n2 = n1.map((a) => {
        console.log(a);
        const fullname = a.staff;
        return post("api/users/findByName/year/2024", {
          fullname: fullname,
        }).then((response) => {
          const user = response.data;
          console.log(user);
          return { ...a, ...user, appraisers: [] };
        });
      });

      Promise.all(n2)
        .then((a) => {
          return a.map((b) => {
            const appraiser = b.appraiser;
            return post("api/users/findByName/year/2024", {
              fullname: appraiser,
            }).then((response) => {
              const appraiser2 = response.data;
              console.log(appraiser2)
              return {
                ...b,
                appraisers: [
                  { appraiser: appraiser2, score: 0, status: Status.PENDING },
                ],
              };
            });
          });
        })
        .then((c) => {
          return Promise.all(c);
        })
        .then((e) => {
          console.log(e);
          return e.map((f) => {
            const proxy = f.proxy;
            if (proxy === undefined) {
              return f;
            } else {
              console.log(proxy);
              return post("api/users/findByName/year/2024", {
                fullname: proxy,
              }).then((response) => {
                const appraiser = f.appraisers[0];
                const proxy = response.data;
                return {
                  ...f,
                  appraisers: [{ ...appraiser, proxy: proxy }],
                };
              });
            }
          });
        })
        .then((g) => {
          console.log(g);
          return Promise.all(g);
        })
        .then((h) => {
          console.log(h);
          const newArr = h.map((i) => {
            const j = i;
            delete j.appraiser;
            delete j.proxy;
            delete j.staff;
            return { ...j, status: Status.PENDING };
          });
          console.log(138);
          console.log(newArr);
          return post("api/appraisees", newArr);
          // post("api/appraisees", newArr);
        })
        .then((x) => {
          console.log(x);
        });
    };

    const handleClick11 = async (ev) => {
      let departments = [];
    
      // Fetch the list of departments
      await get("api/departments/year/2024").then((response) => {
        departments = response.data.map((r) => {
          let s = { ...r };
          delete s.staff_doc; // Removing staff_doc from each department
          return s;
        });
      });
    
      // Map through DEPARTMENT_STAFFS and fetch user data by fullname
      const promises = DEPARTMENT_STAFFS.map((ds) => {
        const fullname = ds.staff;
        return post("api/users/findByName/year/2024", {
          fullname: fullname,
        })
          .then((res) => {
            if (res.data && res.data.userid) {
              // If user is found, return the staff data with userid
              return { ...ds, userid: res.data.userid };
            } else {
              // If user is not found, log a warning and return null to filter later
              console.warn(`User not found: ${fullname}`);
              return null;
            }
          })
          .catch((err) => {
            console.error(`Error fetching user: ${fullname}`, err);
            return null; // Return null in case of error as well
          });
      });
    
      // Wait for all promises to resolve
      const result = await Promise.all(promises);
    
      // Filter out any null values (unmatched or errored users)
      const validStaffs = result.filter((r) => r !== null);
    
      // Prepare the new departments array
      const newDepartments = departments.map((department) => {
        const filteredStaffs = validStaffs
          .filter((staff) => staff.department === department.name)
          .map((staff) => {
            delete staff.department;
            delete staff.staff; // Remove unwanted fields
            return staff;
          });
    
        return { ...department, staffs: filteredStaffs }; // Add the valid staff to each department
      });
    
      // Post the new departments data to the server
      post("api/departments/year/2024", newDepartments)
        .then((response) => {
          console.log("Departments successfully updated", response);
        })
        .catch((err) => {
          console.error("Error updating departments", err);
        });
    
      console.log(newDepartments);
    };    

  return (
    <div className="card">
      <div className="card-body">
        <ol>
          {badNames.map((badName, i) => {
            return <li key={i}>{badName}</li>;
          })}
        </ol>
      </div>
      {/* <div className="card-footer">
        <button onClick={handleClick4444}>Sik Sure</button>
      </div>
      <br/>
      <div className="card-footer">
        <button onClick={handleAssignAppraiserClick}>Assign Appraiser</button>
      </div>
      <br/>
      <div className="card-footer">
        <button onClick={handleClick6}>Date Joined?</button>
      </div>
      <br/>
      <div className="card-footer">
        <button onClick={handleClick7}>Check user?</button>
      </div>
      <br/>
      <div className="card-footer">
        <button onClick={handleClick8}>Badnames?</button>
      </div>
      <br/> */}
      <div className="card-footer">
        <button onClick={handleClick9}>Step 1: Update Position & Grade</button>
      </div>
      <br/>
      <div className="card-footer">
        <button onClick={handleClick10}>Step 2: Assign Appraiser</button>
      </div>
      <br/>
      <div className="card-footer">
        <button onClick={handleUploadCompanies}>Step 3: Upload Companies</button>
      </div>
      <br/>
      <div className="card-footer">
        <button onClick={handleClick3}>Step 4: Assign Company Staff</button>
      </div>     
      <br/>
      <div className="card-footer">
        <button onClick={handleClick5}>Step 5: Upload Department</button>
      </div>
      <br/>   
      {/* <div className="card-footer">
        <button onClick={handleClick4}>Step 6: Assign Department Staff</button>
      </div> */}
      <div className="card-footer">
        <button onClick={handleClick11}>Step 6: Assign Department Staff</button>
      </div>
      <br/>                
    </div>
  );
};
export default Foobar;
