export const YEAR = 2024;
export const Status = {
  MISSING: "MISSING",
  PENDING: "PENDING",
  WAITFORPROXY: "WAITFORPROXY",
  WAITFORSUPERVISOR: "WAITFORSUPERVISOR",
  PROXYSUBMITTED: "PROXYSUBMITTED",
  SUPERVISORSUBMITTED: "SUPERVISORSUBMITTED",
  WAITFORSTAFF: "WAITFORSTAFF",
  STAFFREVIEWED: "STAFFREVIEWED",
  CLOSED: "CLOSED",
};

export const YESNOPARTIAL = {
  YES: "YES",
  NO: "NO",
  PARTIAL: "PARTIAL",
};

export const YESNO = {
  YES: "YES",
  NO: "NO",
};

export const scores = [
  { grade: 0, score: 0 },
  { grade: 1, score: 30 },
  { grade: 2, score: 50 },
  { grade: 3, score: 70 },
  { grade: 4, score: 85 },
  { grade: 5, score: 100 },
];
export const QuestionNos = {
  One: "1.0",
  Two: "2.0",
  Three: "3.0",
  Four: "4.0",
  Five: "5.0",
  Six: "6.0",
  Seven: "7.0",
  Eight: "8.0",
};
export const Trainings = {
  ProblemSolving: "Problem Solving",
  DecisionMaking: "Decision Making",
  CriticalThinkingSkills: "Critical Thinking Skills",
  MasteringEmotionalIntelligence: "Mastering Emotional Intelligence",
  EffectiveInterpersonalSkill: "Effective Interpersonal Skill",
  SupervisorySkill: "Supervisory Skill",
  LeadershipSkill: "Leadership Skill",
  QualityManagementSystem: "Quality Management System",
  StressManagement: "Stress Management",
  OrganizationSkill: "Organization Skill",
  BusinessEnglishSkill: "Business English Skill",
  PresentationSkill: "Presentation Skill",
  ComputerLiteracy: "Computer Literacy",
  OfficeProductivitySpreadsheet: "Office Productivity – Spreadsheet",
  OfficeProductivityDocument: "Office Productivity – Document",
};
export const SupervisorFormMode = {
  FINDUSER: "FINDUSER",
  ASSIGNSUPERVISOR: "ASSIGNSUPERVISOR",
};
export const SCORECOLORS = {
  Lime: "#00FF00",
  LawnGreen: "#7CFC00",
  GreenYellow: "#ADFF2F",
  Yellow: "#FFFF00",
  Gold: "#FFD700",
  LightSalmon: "#FFA07A",
};

export const StatusColor = {
  MISSING: "Gray",
  PENDING: "LightYellow",
  WAITFORSUPERVISOR: "Yellow",
  WAITFORPROXY: "Yellow" /* suspected unused */,
  PROXYSUBMITTED: "Red",
  SUPERVISORSUBMITTED: "Blue",
  WAITFORSTAFF: "LightCyan",
  STAFFREVIEWED: "Green",
  CLOSED: "LightGray",
};
export const StatusColor2 = {
  MISSING: "Gray",
  PENDING: "LightYellow",
  WAITFORSUPERVISOR: "Yellow",
  PROXYSUBMITTED: "Red",
  SUPERVISORSUBMITTED: "LightCyan",
  WAITFORSTAFF: "LightCyan",
  STAFFREVIEWED: "LawnGreen",
  CLOSED: "LightGray",
};
export const LISTVIEW = {
  LIST: "LIST",
  VIEW: "VIEW",
};
export const LISTCOMPANYDEPARTMENT = {
  LIST: "LIST",
  COMPANY: "COMPANY",
  DEPARTMENT: "DEPARTMENT",
};
export const APPTITLE = "Staff Annual Performance Evaluation for 2024";
export const NOTRELEVANT = "NOTRELEVANT";

export const APPRAISERS = [
  {staff: `JONG SUK FANG`, proxy: ``, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `NGO KEH TOH, ANNIE`, proxy: ``, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `LIEW SZE YAH, NICOLE`, proxy: ``, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `LENOR JOYCE SASOY LEGASPI-BANAWA`, proxy: ``, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `FREDERICK CABUNGAN BANAWA`, proxy: ``, appraiser: `ALVIN CHONG TZE HUI` },
{staff: `SITI HAJAR BINTI MOHD YUSOP`, proxy: ``, appraiser: `ARCHIBAL CATIBOG MANANSALA` },
{staff: `DORIS ANAK WILSON ELAH`, proxy: ``, appraiser: `ARCHIBAL CATIBOG MANANSALA` },
{staff: `NORSAMSUDDIN ANAK BANGUN`, proxy: ``, appraiser: `ARCHIBAL CATIBOG MANANSALA` },
{staff: `HO AI CHIN`, proxy: ``, appraiser: `ARCHIBAL CATIBOG MANANSALA` },
{staff: `HENRY ANAK ATA`, proxy: ``, appraiser: `ARCHIBAL CATIBOG MANANSALA` },
{staff: `TAN KOK SAN, NICOLE`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `STEPHANIE KHO EE FUN`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `YONG SERN PIAT`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `TIONG TECK YIENG`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `JENNY YU PICK HAI`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `TANG LING PING, JENNIFER`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `TAY CHIOK KEE`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `TAY CHIOK KEE`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `STEPHEN TAN SOON HONG`, proxy: ``, appraiser: `AUGUSTINE LAW SEK HIAN` },
{staff: `SABREE BIN SABU`, proxy: ``, appraiser: `BONG IT FIAN, IVAN` },
{staff: `ELLY ANAK WILFRED`, proxy: ``, appraiser: `BONG IT FIAN, IVAN` },
{staff: `VERONICA A/P NOED`, proxy: ``, appraiser: `BONG IT FIAN, IVAN` },
{staff: `MARCOS ANAK WARREN SAOH`, proxy: ``, appraiser: `BONG IT FIAN, IVAN` },
{staff: `AZALIZA BINTI AMIN`, proxy: ``, appraiser: `BONG IT FIAN, IVAN` },
{staff: `HELMI BIN PUTEH`, proxy: ``, appraiser: `BONG IT FIAN, IVAN` },
{staff: `NORBAYU BT MUKU`, proxy: ``, appraiser: `BONG IT FIAN, IVAN` },
{staff: `NGUANG KIM KIONG`, proxy: ``, appraiser: `BONG IT FIAN, IVAN` },
{staff: `JUDIRIC ALCAZAR RECIO`, proxy: ``, appraiser: `CHAI TZE LIK` },
{staff: `SIM YUH SHIUN`, proxy: ``, appraiser: `CHAI TZE LIK` },
{staff: `JENNIFFER JAWAI ANAK ANTHONEY LIM`, proxy: ``, appraiser: `CHAI TZE LIK` },
{staff: `KATHERINE YII JOU PEI`, proxy: ``, appraiser: `CHAN HOOD SOO` },
{staff: `LIM WEI SING`, proxy: ``, appraiser: `CHAN HOOD SOO` },
{staff: `LAI CHEAU NEE`, proxy: ``, appraiser: `CHAN HOOD SOO` },
{staff: `HASNIYATI HII BINTI ABDULLAH`, proxy: ``, appraiser: `CHAN HOOD SOO` },
{staff: `MAGDALYNE LIZA UBANG`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `CAMILLA MARCIA KEDUAK ANAK UNYUK`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `MUHAMMAD BIN SARIP`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `TIFFANY TAMBONG ANAK MORGAN`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `NICHOLAS VALEN ANAK RAGIB`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `SITI ZALEHA BINTI ZEN`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `WELLINGTON ANAK JACK`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `CALVIN ANAK MAWAR`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `SO HU WEI`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `MOHAMAD HATTA BIN ROSIDI`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `ALVIN LOH`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `COLIN MATHEW`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `ERIC UNTAMD ANAK MARK`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `IAN WALTER JUNIT`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `TANG KIE CHIONG`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `GARY MADANG ANAK UNDOM`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `AMIRUL SYAZWAN BIN SAMRA`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `PHILIBERT ROGES`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `ANGELINE ONG CHEAH JOO`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `CLINTON JAWA ANAK RICHARDLY`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `STEPHEN SUNNY ANAK RICHMOND`, proxy: ``, appraiser: `CHARLES LEE WEE TECK` },
{staff: `LAU SIE CHUNG`, proxy: ``, appraiser: `CHEE SIAW PAW` },
{staff: `TING SII KIONG`, proxy: ``, appraiser: `CHEE SIAW PAW` },
{staff: `LEE JIE LING`, proxy: ``, appraiser: `CHIN YIAN CHON, ALSON` },
{staff: `CHIN CHEE HAO`, proxy: ``, appraiser: `CHIN YIAN CHON, ALSON` },
{staff: `LIM KIAN BENG`, proxy: ``, appraiser: `CHIN YIAN CHON, ALSON` },
{staff: `MUHAMMAD ALFIAN WIRA SEPTIAN`, proxy: ``, appraiser: `CHIN YIAN CHON, ALSON` },
{staff: `SHIRLEY WONG LEE SAN`, proxy: ``, appraiser: `FREDERICK CABUNGAN BANAWA` },
{staff: `LESTINLEY LUIS ANAK LAWAI`, proxy: ``, appraiser: `FREDERICK CABUNGAN BANAWA` },
{staff: `CHUA SIAW TED`, proxy: ``, appraiser: `FREDERICK CABUNGAN BANAWA` },
{staff: `OW WAN FOO, EDDIE`, proxy: ``, appraiser: `FREDERICK CABUNGAN BANAWA` },
{staff: `MENISSA ANAK BUYONG`, proxy: ``, appraiser: `FREDERICK CABUNGAN BANAWA` },
{staff: `CHANG FU BOON`, proxy: ``, appraiser: `GOH CHIN SING` },
{staff: `EVELYN ANAK ANAU`, proxy: ``, appraiser: `HENG MUI KEE` },
{staff: `ELSIE LEE SUK CHER`, proxy: ``, appraiser: `HENG MUI KEE` },
{staff: `LUI RUI YING`, proxy: ``, appraiser: `HENG MUI KEE` },
{staff: `AU WAN SEE, YVONNE`, proxy: ``, appraiser: `HENG MUI KEE` },
{staff: `KO LIAN CHUENG, KENNETH`, proxy: ``, appraiser: `HON JUN KHIONG` },
{staff: `MAC AUBREY ANAK MORIS @ MICHAEL`, proxy: ``, appraiser: `HON JUN KHIONG` },
{staff: `ALBERT CHUNG SWEE HENG`, proxy: ``, appraiser: `HON JUN KHIONG` },
{staff: `JOHAN ANAK JAWA`, proxy: ``, appraiser: `HON JUN KHIONG` },
{staff: `NUR ASFIA BIN MOHAMAD DOLLAH`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `NG WAN LI, WENDY`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `YUSUF BIN BAHARI`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `DAUD BIN MAHMOR`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `WEI SZE NEI`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `LU KA LANG @ LU KAH NANG`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `LAW DUEN SIONG`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `WEE GUEK YIAN`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `TAY GUAN PHING`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `BONG JOO CHEE`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `NANCY THU MEI SZE`, proxy: ``, appraiser: `JENNY YU PICK HAI` },
{staff: `MELISA ANAK SURING`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `LEE PING CHIONG`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `ARIF BIN CHIK`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `TING JIT JIE`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `TING YING SIEW`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `TIBU ANAK DAUD`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `MUHAMMAD AMIRUL RIDHZUAN STANNY`, proxy: ``, appraiser: `JIMENO JR. LUGUE JERUSALEM` },
{staff: `SYLVIA TANG CHUNG TIEN`, proxy: ``, appraiser: `JONG SIAW FOONG, STEVEN` },
{staff: `CALVIN GARAN ANAK GOHAN`, proxy: ``, appraiser: `JONG SIAW FOONG, STEVEN` },
{staff: `ABDUL MAJID BIN KIPLI`, proxy: ``, appraiser: `KATHERINE SONG` },
{staff: `CHRISTOPHER CHAI KOH WEE`, proxy: ``, appraiser: `KATHERINE SONG` },
{staff: `VELDA CHANG CHIA YIN`, proxy: ``, appraiser: `KATHERINE SONG` },
{staff: `ABDUL WARITH BIN MOHAMMAD PHILIP`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `BONG KWONG NYAP`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `LAI SIEW LIEN`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `LIM KAI HENG`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `CHRIS HUDSON ANAK BUJANG`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `VIVIANA ONG ZHI JINN`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `ROSELIND IKAH ANAK ROBERT @ KELIMBANG`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `SIM POH KIAW, EMELYN`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `TOH JAG HUI`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `KELVIN CHAI HOCK JOI`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `CHIANG HENG HO`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `TULIS ANAK CHANDANG`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `SELINBIT ANAK MOS`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `CHIN WEI KING, ERIC`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `JESSICA ERIN ANAK BARING`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `ARCHIBAL CATIBOG MANANSALA`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `ROBICA ANAK KALONG`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `DAPHNE DONNA BINTI MARTIN`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `CHONG YU CHIAU`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `LEE KUET LUNG`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `CHAI CHANG LECK, ERIC`, proxy: ``, appraiser: `KELVIN KANG KIAN LAI` },
{staff: `JIMENO JR. LUGUE JERUSALEM`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `SIM KAY CHIAN, STEPHEN`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `CHAI TZE LIK`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `LIM KHIN YONG, JOHN`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `ALVIN CHONG TZE HUI`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `JONG SIAW FOONG, STEVEN`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `CHAN HOOD SOO`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `LAU YUK SIONG, PHILIP`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `KELVIN KANG KIAN LAI`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `CHIN YIAN CHON, ALSON`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `SIAW WEN YIAP, MICHEAL`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `HON JUN KHIONG`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `TING SING HWA`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `HENG MUI KEE`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `CHINDAI ANAK JOSEPH`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `WONG ING SIU, THOMAS`, proxy: ``, appraiser: `LAU KIING KANG, SIMON` },
{staff: `LEE MING SWEE, JUSTIN`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `DIANA LEE MEI MEI`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `SIM YIK CHWIN`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `GOH CHIN SING`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `VINCENT TAN KIM POH`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `SYLVESTER MIKE LIEW CHO KEAT`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `LAU KIEW HING`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `SIEW UO SIONG`, proxy: ``, appraiser: `LAU YUK SIONG, PHILIP` },
{staff: `ANDYANNA ANAK ADRIS`, proxy: ``, appraiser: `LAWRENCE KONG` },
{staff: `MOHAMMAD AZEIR BIN UMIN`, proxy: ``, appraiser: `LEE MING SWEE, JUSTIN` },
{staff: `JASCICA ANAK BAJAK`, proxy: ``, appraiser: `LESLIE LIM CHUNG LEONG` },
{staff: `RONIE LAU HUI LIONG`, proxy: ``, appraiser: `LIM KHIN YONG, JOHN` },
{staff: `KELVIN YII SENG HUA`, proxy: ``, appraiser: `LIM KHIN YONG, JOHN` },
{staff: `LIEW HOCK RUI`, proxy: ``, appraiser: `LIM KHIN YONG, JOHN` },
{staff: `KUNJAN ANAK ENGGONG`, proxy: ``, appraiser: `LIM KHIN YONG, JOHN` },
{staff: `ANGELA KATHY ANAK KINTANG`, proxy: ``, appraiser: `NGO KEH TOH` },
{staff: `LESLEY JENTANG ANAK JOHNNY`, proxy: `JOSEPHINE FOO SIEW PHENG`, appraiser: `SIA SOON GUEI` },
{staff: `THON HUI HUI`, proxy: `TIONG SIEW LING`, appraiser: `SIA SOON GUEI` },
{staff: `FOO HUI HUI, GRACE`, proxy: `TIONG SIEW LING`, appraiser: `SIA SOON GUEI` },
{staff: `HELY ANAK SOTIN`, proxy: `TIONG SIEW LING`, appraiser: `SIA SOON GUEI` },
{staff: `WEE MOI CHOO`, proxy: `TIONG SIEW LING`, appraiser: `SIA SOON GUEI` },
{staff: `FOO HUI SIEN`, proxy: `YONG SIEW TING, CONNIE`, appraiser: `SIA SOON GUEI` },
{staff: `JOSEPHINE FOO SIEW PHENG`, proxy: ``, appraiser: `SIA SOON GUEI` },
{staff: `ALIAA SYAMIMI BINTI JAMALI`, proxy: ``, appraiser: `SIA SOON GUEI` },
{staff: `OLIZARENA ANAK ENA`, proxy: ``, appraiser: `SIA SOON GUEI` },
{staff: `WAN MOHAMAD BIN WAN ALLI`, proxy: ``, appraiser: `SIA SOON GUEI` },
{staff: `YONG SIEW TING, CONNIE`, proxy: ``, appraiser: `SIA SOON GUEI` },
{staff: `TIONG SIEW LING`, proxy: ``, appraiser: `SIA SOON GUEI` },
{staff: `MOHD FIRAS BIN JUANDA`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `PERNANDEZ TULIS`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `TANG SING HUI`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `JACKLYN ANAK JUGAH`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `FLONY MEREH ANAK DAVID`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `DAVIDSON AK DAYUT`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `CHIENG SHAW YAW`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `TEO TAI WEE, STEPHEN`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `CHIENG CHING KUOK`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `JONG JIA XIN, LYDIA`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `CHONG CHIA WEI`, proxy: ``, appraiser: `SIAW WEN YIAP, MICHEAL` },
{staff: `HA HAW DING`, proxy: ``, appraiser: `SIEW MING HUA` },
{staff: `PRESCILLIA ANAK PHILIP`, proxy: ``, appraiser: `SIM YIK CHWIN` },
{staff: `EMILY ANAK USAM`, proxy: ``, appraiser: `SYLVESTER MIKE LIEW CHO KEAT` },
{staff: `GIBSON BANYING ANAK KALIP`, proxy: ``, appraiser: `SYLVIA ANAK CHAONG` },
{staff: `FIODA LEW SHIN YII`, proxy: ``, appraiser: `SYLVIA ANAK CHAONG` },
{staff: `TING SIAW WEE`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `MONA KEDAYA ANAK STORY`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `SONIA VIVIANI ANAK JANIS`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `SANDRA ANAK JAWING`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `DAYANG NUR AININA BINTI ABG MOHAMAD`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `LING FUNG ENG`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `HII CHUI YEN, IVY`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `VOON KHENG HUA, VALERIE`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `CHRISTIANA ROMINA ANAK JANIS`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `FADILLAH BTE ABDULLAH`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `NAJA ANAK NYAUN`, proxy: ``, appraiser: `TAN KOK SAN, NICOLE` },
{staff: `LEE SHAW HIENG`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `TANG KUI SIEW, DEBBY`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `IRWAN BIN BUJANG`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `JOYCE LIM FAYE`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `VALERIE JEE JIA JIA`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `ADRIAN CHAI YOU QING`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `HO SIAW LEE, LILY`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `IRENE KHO AI LING`, proxy: ``, appraiser: `TANG LING PING, JENNIFER` },
{staff: `ANDRESS LANTUK ANAK MAJING`, proxy: `ALEXANDER LAI HORNG WEN`, appraiser: `TAY CHIOK KEE` },
{staff: `WINNIE LAI EE FEI`, proxy: `ALEXANDER LAI HORNG WEN`, appraiser: `TAY CHIOK KEE` },
{staff: `EFFA HELENA BINTI MOHD ARIFFIN`, proxy: `ANGIE JOAN ANAK DANIEL`, appraiser: `TAY CHIOK KEE` },
{staff: `SHARON NG KHE SWYN`, proxy: `ANGIE JOAN ANAK DANIEL`, appraiser: `TAY CHIOK KEE` },
{staff: `PHANG MING JING, RAINIE`, proxy: `ANGIE JOAN ANAK DANIEL`, appraiser: `TAY CHIOK KEE` },
{staff: `FARAHANI BINTI HAINI`, proxy: `ANGIE JOAN ANAK DANIEL`, appraiser: `TAY CHIOK KEE` },
{staff: `TOMMY LEE SOON MING`, proxy: `CHIENG CHING KUOK`, appraiser: `TAY CHIOK KEE` },
{staff: `HII YII SHIN`, proxy: `LESLIE LIM CHUNG LEONG`, appraiser: `TAY CHIOK KEE` },
{staff: `BRIAN CHIN YUNG LIANG`, proxy: `LING SHEAU WEI`, appraiser: `TAY CHIOK KEE` },
{staff: `TIE SHIE LING`, proxy: `NGO JIN JIN`, appraiser: `TAY CHIOK KEE` },
{staff: `ABDUL AZIZ BIN HIPENI`, proxy: `TAN YIEN LIN`, appraiser: `TAY CHIOK KEE` },
{staff: `LINDA ANAK BUYONG`, proxy: `VICTORIA ANAK UMPANG`, appraiser: `TAY CHIOK KEE` },
{staff: `VICTORIA ANAK UMPANG`, proxy: `YEO TANG TANG`, appraiser: `TAY CHIOK KEE` },
{staff: `TAN YIEN LIN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `CHARLES REYNOLD ANAK NIGEL`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `CHARLES LEE WEE TECK`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `TING KWAN HEE`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `NGO JIN JIN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `LESLIE LIM CHUNG LEONG`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `ANGIE JOAN ANAK DANIEL`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `JOSEPH CHAI MING KIEN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `TIEO SEOW LING, LYN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `CHONG SIAW HUA`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `AARON AU SAU SIANG`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `LING SHEAU WEI`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `ALEXANDER LAI HORNG WEN`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `LAI SIAW LING`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `YEO TANG TANG`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `THIAN FUI CHIUN, KERRY`, proxy: ``, appraiser: `TAY CHIOK KEE` },
{staff: `ALBINUS PETRUS RATIM NGIBAN`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `BONNIE ANAK ANTHONY`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `TUNI ANAK OUI JUAN`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `ALI BIN SABRI`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `HAIDI BIN MARANI`, proxy: ``, appraiser: `TING KWAN HEE` },
{staff: `ESTHER CH'NG PEI YAN`, proxy: `CHAI SIN HWEI`, appraiser: `TIONG TECK YIENG` },
{staff: `CHUA YONG TIEN, EUNICE`, proxy: `CHONG SIAW HUA`, appraiser: `TIONG TECK YIENG` },
{staff: `NG KHOON CHIENG`, proxy: `LIM EK HUA`, appraiser: `TIONG TECK YIENG` },
{staff: `AMANDA LAW LI KHING`, proxy: `SIA SOON GUEI`, appraiser: `TIONG TECK YIENG` },
{staff: `BELINDA TAN CHAI YING`, proxy: `SIA SOON GUEI`, appraiser: `TIONG TECK YIENG` },
{staff: `CHRISTABELLA DUIE ANAK CHRISTOPHER NYENGGANG`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHIN JIUN HUAH, ANGEL`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `TAN MIAW ENG, ANGELINE`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `WONG WOAN MIING, RACHELLE`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `YONG TING`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `SIM SIAT FUNG, ALICE`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `TAY YAI YING`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `ANNIE LEE SIEW PHIN`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHAI LI SWAN`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `JI CHOU KING`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `JOYCE TEH YEE`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `SIM PEI CHUN`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHAI SIN HWEI`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `SIA SOON GUEI`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `JACKSON CHAI KEN NAM`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `KHO BOON POH`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `ONG CHIEW LING`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `ANG SOO HUI, SOPHIA`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHEN ANN NEE, ANNIE`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `CHAI PEI SHAN`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `ONG CHIAW SIAN`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `JACKUELINE ONG YUEN CHING`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `LIM EK HUA`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `JONG PEI PEI, ANNA`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `TERRY THAM TZE PING`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `LAW SIEW NI, TIFFANY`, proxy: ``, appraiser: `TIONG TECK YIENG` },
{staff: `SIEW MING HUA`, proxy: ``, appraiser: `TONY YU YUONG WEE` },
{staff: `CHEE SIAW PAW`, proxy: ``, appraiser: `TONY YU YUONG WEE` },
{staff: `WONG MING`, proxy: ``, appraiser: `TONY YU YUONG WEE` },
{staff: `DARREN JOSHUA LUNA ANAK MARVIN @ IMPI`, proxy: `JOEL SU TAI TUNG`, appraiser: `WONG ING SIU, THOMAS` },
{staff: `CHONG UU LEONG, ZACK`, proxy: `LIM WEN CHUNG, ALEX`, appraiser: `WONG ING SIU, THOMAS` },
{staff: `LIM SOO HUI`, proxy: `LIM WEN CHUNG, ALEX`, appraiser: `WONG ING SIU, THOMAS` },
{staff: `BILLY JONG HUNG ENG`, proxy: `LIM WEN CHUNG, ALEX`, appraiser: `WONG ING SIU, THOMAS` },
{staff: `TEO KEE HUA, DORIS`, proxy: `LIM WEN CHUNG, ALEX`, appraiser: `WONG ING SIU, THOMAS` },
{staff: `VOON SAY NYUK, SHIRLEY`, proxy: ``, appraiser: `WONG ING SIU, THOMAS` },
{staff: `BONG IT FIAN, IVAN`, proxy: ``, appraiser: `WONG ING SIU, THOMAS` },
{staff: `CELESTINE TANG CHUNG KUI`, proxy: `BONG IT FIAN, IVAN`, appraiser: `WONG ING SIU, THOMAS` },
{staff: `LING SHI HING, ERIC`, proxy: ``, appraiser: `WONG ING SIU, THOMAS` },
{staff: `MARIA SU`, proxy: ``, appraiser: `WONG ING SIU, THOMAS` },
{staff: `MARIA JATTI ANAK ENA`, proxy: ``, appraiser: `WONG ING SIU, THOMAS` },
{staff: `LIM WEN CHUNG, ALEX`, proxy: ``, appraiser: `WONG ING SIU, THOMAS` },
{staff: `JOEL SU TAI TUNG`, proxy: ``, appraiser: `WONG ING SIU, THOMAS` },
{staff: `REMY ANAK JOHEL`, proxy: `JOEL SU TAI TUNG`, appraiser: `WONG MING` },
{staff: `LIEGAN ANAK MANGGUH`, proxy: `KHO HO SIA`, appraiser: `WONG MING` },
{staff: `MACCOLLIN DEE DANNY ERICK`, proxy: `KHO HO SIA`, appraiser: `WONG MING` },
{staff: `PETRUS SCHENKER ANAK RABES`, proxy: `KHO HO SIA`, appraiser: `WONG MING` },
{staff: `FABIANA ANAK CHAN`, proxy: `KHO HO SIA`, appraiser: `WONG MING` },
{staff: `NOOR MARLIDAH HAI`, proxy: `KHO HO SIA`, appraiser: `WONG MING` },
{staff: `JOHNNY HO KOK SIAM`, proxy: ``, appraiser: `WONG MING` },
{staff: `SYLVESTER ANAK KENETH KANYAN`, proxy: ``, appraiser: `WONG MING` },
{staff: `BRIAN JOVY ANAK AHMEW`, proxy: ``, appraiser: `WONG MING` },
{staff: `MOHD. HAZRUL ALEX BIN ABDULLAH`, proxy: ``, appraiser: `WONG MING` },
{staff: `VORNICA NAYAN ANAK NANANG`, proxy: ``, appraiser: `WONG MING` },
{staff: `DIANA BIDAI ANAK MENDALI`, proxy: ``, appraiser: `WONG MING` },
{staff: `CHENDANG ANAK THOMAS ALI`, proxy: ``, appraiser: `WONG MING` },
{staff: `JULLYANA GORILA ANAK JENOS`, proxy: ``, appraiser: `WONG MING` },
{staff: `NURUL HURAIRAH BINTI SARIP`, proxy: ``, appraiser: `WONG MING` },
{staff: `BRIGETTE BRENDA BASE`, proxy: ``, appraiser: `WONG MING` },
{staff: `CHIN LEE HUA`, proxy: ``, appraiser: `WONG MING` },
{staff: `KHO HO SIA, GEORGE`, proxy: ``, appraiser: `WONG MING` },
{staff: `LESTER LUNA ANAK ULOK`, proxy: ``, appraiser: `WONG MING` },
{staff: `MAINA NICKOLA ANAK MICHILE`, proxy: ``, appraiser: `WONG MING` },
{staff: `JONG KIM WONG`, proxy: ``, appraiser: `WONG MING` },
{staff: `VERONICA SULAN ANAK VINCENT`, proxy: ``, appraiser: `WONG MING` },
{staff: `NELVIN BRIAN ANAK NAWOT`, proxy: ``, appraiser: `WONG MING` },
{staff: `EDWARD TENLEY AK FRANCIS KETIR`, proxy: ``, appraiser: `YEO TANG TANG` },
{staff: `YUNG MEI HUA, MANDY`, proxy: ``, appraiser: `YEO TANG TANG` },
{staff: `AUDREY STECCY ANAK DOUGLAS`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `GASTON QION EMMANUEL AK GEORGE`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `FAMELIA ROLLANIE ANAK RUFIE`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `YONG WEI TING`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `LO JAK FATT, EVENCE`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `TAN KEENG LING`, proxy: ``, appraiser: `YONG SERN PIAT` },
{staff: `YVONNE SHARON ANAK MIKOLDEN`, proxy: ``, appraiser: `YU JI` },
{staff: `ABRAHAM CHUANG-CHYI TIONG`, proxy: ``, appraiser: `YU JI` },
{staff: `ROCHESTER BAGOL ANAK NICHOLAS NYALAU`, proxy: ``, appraiser: `YU JI` },
{staff: `KATHERINE SONG`, proxy: ``, appraiser: `YU JI` },
{staff: `SYLVIA ANAK CHAONG`, proxy: ``, appraiser: `YU JI` },
]

export const DEPARTMENTS = [
  { name: "ACCOUNTS", departmentid: "ACCOUNTS" },
  { name: "ADMINISTRATION", departmentid: "ADMINISTRATION" },
  { name: "BPL", departmentid: "BPL" },
  { name: "CORPORATION", departmentid: "CORPORATION" },
  { name: "HR & CORPORATE COMM", departmentid: "HR" },
  { name: "IT", departmentid: "IT" },
  { name: "PA", departmentid: "PA" },
  { name: "PROJECT ADMIN", departmentid: "PROJECT ADMIN" },
  { name: "PROJECTS", departmentid: "PROJECTS" },
  { name: "PROPERTY", departmentid: "PROPERTY" },
  { name: "QS & CONTRACT MGT", departmentid: "QS" },
  { name: "SAFETY & HEALTH", departmentid: "SH" },
  { name: "TREASURY", departmentid: "TREASURY" },
];

export const DEPARTMENT_STAFFS = [
  { department: "ACCOUNTS", staff: "AMANDA LAW LI KHING" },
  { department: "ACCOUNTS", staff: "ANG SOO HUI, SOPHIA" },
  { department: "ACCOUNTS", staff: "ANNIE LEE SIEW PHIN" },
  { department: "ACCOUNTS", staff: "BELINDA TAN CHAI YING" },
  { department: "ACCOUNTS", staff: "CHAI LI SWAN" },
  { department: "ACCOUNTS", staff: "CHAI PEI SHAN" },
  { department: "ACCOUNTS", staff: "CHAI SIN HWEI" },
  { department: "ACCOUNTS", staff: "CHEN ANN NEE, ANNIE" },
  { department: "ACCOUNTS", staff: "CHIN JIUN HUAH, ANGEL" },
  { department: "ACCOUNTS", staff: "CHRISTABELLA DUIE ANAK CHRISTOPHER NYENGGANG" },
  { department: "ACCOUNTS", staff: "ESTHER CH'NG PEI YAN" },
  { department: "ACCOUNTS", staff: "FOO HUI HUI, GRACE" },
  { department: "ACCOUNTS", staff: "FOO HUI SIEN" },
  { department: "ACCOUNTS", staff: "HELY ANAK SOTIN" },
  { department: "ACCOUNTS", staff: "JACKSON CHAI KEN NAM" },
  { department: "ACCOUNTS", staff: "JACKUELINE ONG YUEN CHING" },
  { department: "ACCOUNTS", staff: "JI CHOU KING" },
  { department: "ACCOUNTS", staff: "JONG PEI PEI, ANNA" },
  { department: "ACCOUNTS", staff: "JOSEPHINE FOO SIEW PHENG" },
  { department: "ACCOUNTS", staff: "JOYCE TEH YEE" },
  { department: "ACCOUNTS", staff: "KHO BOON POH" },
  { department: "ACCOUNTS", staff: "LAW SIEW NI, TIFFANY" },
  { department: "ACCOUNTS", staff: "LESLEY JENTANG ANAK JOHNNY" },
  { department: "ACCOUNTS", staff: "LIM EK HUA" },
  { department: "ACCOUNTS", staff: "NG KHOON CHIENG" },
  { department: "ACCOUNTS", staff: "ONG CHIAW SIAN" },
  { department: "ACCOUNTS", staff: "ONG CHIEW LING" },
  { department: "ACCOUNTS", staff: "SIA SOON GUEI" },
  { department: "ACCOUNTS", staff: "SIM PEI CHUN" },
  { department: "ACCOUNTS", staff: "SIM SIAT FUNG, ALICE" },
  { department: "ACCOUNTS", staff: "STEPHANIE KHO EE FUN" },
  { department: "ACCOUNTS", staff: "TAN MIAW ENG, ANGELINE" },
  { department: "ACCOUNTS", staff: "TAY YAI YING" },
  { department: "ACCOUNTS", staff: "TERRY THAM TZE PING" },
  { department: "ACCOUNTS", staff: "THON HUI HUI" },
  { department: "ACCOUNTS", staff: "TIONG SIEW LING" },
  { department: "ACCOUNTS", staff: "TIONG TECK YIENG" },
  { department: "ACCOUNTS", staff: "WEE MOI CHOO" },
  { department: "ACCOUNTS", staff: "WONG WOAN MIING, RACHELLE" },
  { department: "ACCOUNTS", staff: "YONG SIEW TING, CONNIE" },
  { department: "ACCOUNTS", staff: "YONG TING" },
  { department: "ADMINISTRATION", staff: "ABDUL AZIZ BIN HIPENI" },
  { department: "ADMINISTRATION", staff: "ALBINUS PETRUS RATIM NGIBAN" },
  { department: "ADMINISTRATION", staff: "ALI BIN SABRI" },
  { department: "ADMINISTRATION", staff: "BONNIE ANAK ANTHONY" },
  { department: "ADMINISTRATION", staff: "CHARLES REYNOLD ANAK NIGEL" },
  { department: "ADMINISTRATION", staff: "CHINDAI ANAK JOSEPH" },
  { department: "ADMINISTRATION", staff: "EDWARD TENLEY AK FRANCIS KETIR" },
  { department: "ADMINISTRATION", staff: "HAIDI BIN MARANI" },
  { department: "ADMINISTRATION", staff: "LAI SIAW LING" },
  { department: "ADMINISTRATION", staff: "LINDA ANAK BUYONG" },
  { department: "ADMINISTRATION", staff: "NGO JIN JIN" },
  { department: "ADMINISTRATION", staff: "OLIZARENA ANAK ENA" },
  { department: "ADMINISTRATION", staff: "TAN YIEN LIN" },
  { department: "ADMINISTRATION", staff: "TAY CHIOK KEE" },
  { department: "ADMINISTRATION", staff: "THIAN FUI CHIUN, KERRY" },
  { department: "ADMINISTRATION", staff: "TIE SHIE LING" },
  { department: "ADMINISTRATION", staff: "TING KWAN HEE" },
  { department: "ADMINISTRATION", staff: "TUNI ANAK OUI JUAN" },
  { department: "ADMINISTRATION", staff: "VICTORIA ANAK UMPANG" },
  { department: "ADMINISTRATION", staff: "WAN MOHAMAD BIN WAN ALLI" },
  { department: "ADMINISTRATION", staff: "YEO TANG TANG" },
  { department: "ADMINISTRATION", staff: "YUNG MEI HUA, MANDY" },
  { department: "BPL", staff: "HII YII SHIN" },
  { department: "BPL", staff: "LESLIE LIM CHUNG LEONG" },
  { department: "CORPORATION", staff: "AZALIZA BINTI AMIN" },
  { department: "CORPORATION", staff: "BILLY JONG HUNG ENG" },
  { department: "CORPORATION", staff: "BONG IT FIAN, IVAN" },
  { department: "CORPORATION", staff: "BRIAN JOVY ANAK AHMEW" },
  { department: "CORPORATION", staff: "BRIGETTE BRENDA BASE" },
  { department: "CORPORATION", staff: "CELESTINE TANG CHUNG KUI" },
  { department: "CORPORATION", staff: "CHEE SIAW PAW" },
  { department: "CORPORATION", staff: "CHENDANG ANAK THOMAS ALI" },
  { department: "CORPORATION", staff: "CHIN LEE HUA" },
  { department: "CORPORATION", staff: "CHONG UU LEONG, ZACK" },
  { department: "CORPORATION", staff: "DARREN JOSHUA LUNA ANAK MARVIN @ IMPI" },
  { department: "CORPORATION", staff: "DIANA BIDAI ANAK MENDALI" },
  { department: "CORPORATION", staff: "ELLY ANAK WILFRED" },
  { department: "CORPORATION", staff: "FABIANA ANAK CHAN" },
  { department: "CORPORATION", staff: "HELMI BIN PUTEH" },
  { department: "CORPORATION", staff: "JOEL SU TAI TUNG" },
  { department: "CORPORATION", staff: "JOHNNY HO KOK SIAM" },
  { department: "CORPORATION", staff: "JONG KIM WONG" },
  { department: "CORPORATION", staff: "JULLYANA GORILA ANAK JENOS" },
  { department: "CORPORATION", staff: "KHO HO SIA, GEORGE" },
  { department: "CORPORATION", staff: "LAU SIE CHUNG" },
  { department: "CORPORATION", staff: "LESTER LUNA ANAK ULOK" },
  { department: "CORPORATION", staff: "LIEGAN ANAK MANGGUH" },
  { department: "CORPORATION", staff: "LIM SOO HUI" },
  { department: "CORPORATION", staff: "LIM WEN CHUNG, ALEX" },
  { department: "CORPORATION", staff: "LING SHI HING, ERIC" },
  { department: "CORPORATION", staff: "MACCOLLIN DEE DANNY ERICK" },
  { department: "CORPORATION", staff: "MAINA NICKOLA ANAK MICHILE" },
  { department: "CORPORATION", staff: "MARCOS ANAK WARREN SAOH" },
  { department: "CORPORATION", staff: "MARIA JATTI ANAK ENA" },
  { department: "CORPORATION", staff: "MARIA SU" },
  { department: "CORPORATION", staff: "MOHD. HAZRUL ALEX BIN ABDULLAH" },
  { department: "CORPORATION", staff: "NELVIN BRIAN ANAK NAWOT" },
  { department: "CORPORATION", staff: "NGUANG KIM KIONG" },
  { department: "CORPORATION", staff: "NOOR MARLIDAH HAI" },
  { department: "CORPORATION", staff: "NORBAYU BT MUKU" },
  { department: "CORPORATION", staff: "NURUL HURAIRAH BINTI SARIP" },
  { department: "CORPORATION", staff: "PETRUS SCHENKER ANAK RABES" },
  { department: "CORPORATION", staff: "REMY ANAK JOHEL" },
  { department: "CORPORATION", staff: "SABREE BIN SABU" },
  { department: "CORPORATION", staff: "SYLVESTER ANAK KENETH KANYAN" },
  { department: "CORPORATION", staff: "TEO KEE HUA, DORIS" },
  { department: "CORPORATION", staff: "TING SII KIONG" },
  { department: "CORPORATION", staff: "VERONICA A/P NOED" },
  { department: "CORPORATION", staff: "VERONICA SULAN ANAK VINCENT" },
  { department: "CORPORATION", staff: "VOON SAY NYUK, SHIRLEY" },
  { department: "CORPORATION", staff: "VORNICA NAYAN ANAK NANANG" },
  { department: "CORPORATION", staff: "WONG ING SIU, THOMAS" },
  { department: "CORPORATION", staff: "WONG MING" },
  { department: "HR & CORPORATE COMM", staff: "ADRIAN CHAI YOU QING" },
  { department: "HR & CORPORATE COMM", staff: "HO SIAW LEE, LILY" },
  { department: "HR & CORPORATE COMM", staff: "IRENE KHO AI LING" },
  { department: "HR & CORPORATE COMM", staff: "IRWAN BIN BUJANG" },
  { department: "HR & CORPORATE COMM", staff: "JOYCE LIM FAYE" },
  { department: "HR & CORPORATE COMM", staff: "LEE SHAW HIENG" },
  { department: "HR & CORPORATE COMM", staff: "TANG KUI SIEW, DEBBY" },
  { department: "HR & CORPORATE COMM", staff: "TANG LING PING, JENNIFER" },
  { department: "HR & CORPORATE COMM", staff: "VALERIE JEE JIA JIA" },
  { department: "IT", staff: "AUDREY STECCY ANAK DOUGLAS" },
  { department: "IT", staff: "FAMELIA ROLLANIE ANAK RUFIE" },
  { department: "IT", staff: "GASTON QION EMMANUEL AK GEORGE" },
  { department: "IT", staff: "LO JAK FATT, EVENCE" },
  { department: "IT", staff: "STEPHEN TAN SOON HONG" },
  { department: "IT", staff: "TAN KEENG LING" },
  { department: "IT", staff: "YONG SERN PIAT" },
  { department: "IT", staff: "YONG WEI TING" },
  { department: "PA", staff: "AU WAN SEE, YVONNE" },
  { department: "PA", staff: "ELSIE LEE SUK CHER" },
  { department: "PA", staff: "HENG MUI KEE" },
  { department: "PA", staff: "LUI RUI YING" },
  { department: "PROJECT ADMIN", staff: "ALIAA SYAMIMI BINTI JAMALI" },
  { department: "PROJECT ADMIN", staff: "CHRISTIANA ROMINA ANAK JANIS" },
  { department: "PROJECT ADMIN", staff: "DAPHNE DONNA BINTI MARTIN" },
  { department: "PROJECT ADMIN", staff: "DAYANG NUR AININA BINTI ABG MOHAMAD" },
  { department: "PROJECT ADMIN", staff: "DORIS ANAK WILSON ELAH" },
  { department: "PROJECT ADMIN", staff: "EMILY ANAK USAM" },
  { department: "PROJECT ADMIN", staff: "EVELYN ANAK ANAU" },
  { department: "PROJECT ADMIN", staff: "FADILLAH BTE ABDULLAH" },
  { department: "PROJECT ADMIN", staff: "HASNIYATI HII BINTI ABDULLAH" },
  { department: "PROJECT ADMIN", staff: "HII CHUI YEN, IVY" },
  { department: "PROJECT ADMIN", staff: "JACKLYN ANAK JUGAH" },
  { department: "PROJECT ADMIN", staff: "JENNIFFER JAWAI ANAK ANTHONEY LIM" },
  { department: "PROJECT ADMIN", staff: "JESSICA ERIN ANAK BARING" },
  { department: "PROJECT ADMIN", staff: "KATHERINE YII JOU PEI" },
  { department: "PROJECT ADMIN", staff: "LAI SIEW LIEN" },
  { department: "PROJECT ADMIN", staff: "LAU KIEW HING" },
  { department: "PROJECT ADMIN", staff: "LEE JIE LING" },
  { department: "PROJECT ADMIN", staff: "LIEW SZE YAH, NICOLE" },
  { department: "PROJECT ADMIN", staff: "LING FUNG ENG" },
  { department: "PROJECT ADMIN", staff: "MELISA ANAK SURING" },
  { department: "PROJECT ADMIN", staff: "MONA KEDAYA ANAK STORY" },
  { department: "PROJECT ADMIN", staff: "NAJA ANAK NYAUN" },
  { department: "PROJECT ADMIN", staff: "ROBICA ANAK KALONG" },
  { department: "PROJECT ADMIN", staff: "ROSELIND IKAH ANAK ROBERT @ KELIMBANG" },
  { department: "PROJECT ADMIN", staff: "SANDRA ANAK JAWING" },
  { department: "PROJECT ADMIN", staff: "SHIRLEY WONG LEE SAN" },
  { department: "PROJECT ADMIN", staff: "SIM POH KIAW, EMELYN" },
  { department: "PROJECT ADMIN", staff: "SONIA VIVIANI ANAK JANIS" },
  { department: "PROJECT ADMIN", staff: "TAN KOK SAN, NICOLE" },
  { department: "PROJECT ADMIN", staff: "TING SIAW WEE" },
  { department: "PROJECT ADMIN", staff: "TING YING SIEW" },
  { department: "PROJECT ADMIN", staff: "VOON KHENG HUA, VALERIE" },
  { department: "PROJECTS", staff: "ABDUL WARITH BIN MOHAMMAD PHILIP" },
  { department: "PROJECTS", staff: "ALBERT CHUNG SWEE HENG" },
  { department: "PROJECTS", staff: "ANDYANNA ANAK ADRIS" },
  { department: "PROJECTS", staff: "ARCHIBAL CATIBOG MANANSALA" },
  { department: "PROJECTS", staff: "ARIF BIN CHIK" },
  { department: "PROJECTS", staff: "BONG KWONG NYAP" },
  { department: "PROJECTS", staff: "CHAI CHANG LECK, ERIC" },
  { department: "PROJECTS", staff: "CHANG FU BOON" },
  { department: "PROJECTS", staff: "CHIANG HENG HO" },
  { department: "PROJECTS", staff: "CHIENG SHAW YAW" },
  { department: "PROJECTS", staff: "CHIN CHEE HAO" },
  { department: "PROJECTS", staff: "CHIN WEI KING, ERIC" },
  { department: "PROJECTS", staff: "CHIN YIAN CHON, ALSON" },
  { department: "PROJECTS", staff: "CHONG YU CHIAU" },
  { department: "PROJECTS", staff: "CHRIS HUDSON ANAK BUJANG" },
  { department: "PROJECTS", staff: "CHUA SIAW TED" },
  { department: "PROJECTS", staff: "DAVIDSON AK DAYUT" },
  { department: "PROJECTS", staff: "FLONY MEREH ANAK DAVID" },
  { department: "PROJECTS", staff: "FREDERICK CABUNGAN BANAWA" },
  { department: "PROJECTS", staff: "GOH CHIN SING" },
  { department: "PROJECTS", staff: "HA HAW DING" },
  { department: "PROJECTS", staff: "HENRY ANAK ATA" },
  { department: "PROJECTS", staff: "HO AI CHIN" },
  { department: "PROJECTS", staff: "HON JUN KHIONG" },
  { department: "PROJECTS", staff: "JIMENO JR. LUGUE JERUSALEM" },
  { department: "PROJECTS", staff: "JOHAN ANAK JAWA" },
  { department: "PROJECTS", staff: "JUDIRIC ALCAZAR RECIO" },
  { department: "PROJECTS", staff: "KELVIN CHAI HOCK JOI" },
  { department: "PROJECTS", staff: "KELVIN KANG KIAN LAI" },
  { department: "PROJECTS", staff: "KELVIN YII SENG HUA" },
  { department: "PROJECTS", staff: "KO LIAN CHUENG, KENNETH" },
  { department: "PROJECTS", staff: "KUNJAN ANAK ENGGONG" },
  { department: "PROJECTS", staff: "LAU YUK SIONG, PHILIP" },
  { department: "PROJECTS", staff: "LEE KUET LUNG" },
  { department: "PROJECTS", staff: "LEE MING SWEE, JUSTIN" },
  { department: "PROJECTS", staff: "LEE PING CHIONG" },
  { department: "PROJECTS", staff: "LENOR JOYCE SASOY LEGASPI-BANAWA" },
  { department: "PROJECTS", staff: "LESTINLEY LUIS ANAK LAWAI" },
  { department: "PROJECTS", staff: "LIEW HOCK RUI" },
  { department: "PROJECTS", staff: "LIM KAI HENG" },
  { department: "PROJECTS", staff: "LIM KHIN YONG, JOHN" },
  { department: "PROJECTS", staff: "LIM KIAN BENG" },
  { department: "PROJECTS", staff: "MAC AUBREY ANAK MORIS @ MICHAEL" },
  { department: "PROJECTS", staff: "MAGDALYNE LIZA UBANG" },
  { department: "PROJECTS", staff: "MENISSA ANAK BUYONG" },
  { department: "PROJECTS", staff: "MOHAMMAD AZEIR BIN UMIN" },
  { department: "PROJECTS", staff: "MOHD FIRAS BIN JUANDA" },
  { department: "PROJECTS", staff: "MUHAMMAD ALFIAN WIRA SEPTIAN" },
  { department: "PROJECTS", staff: "MUHAMMAD AMIRUL RIDHZUAN STANNY" },
  { department: "PROJECTS", staff: "NORSAMSUDDIN ANAK BANGUN" },
  { department: "PROJECTS", staff: "OW WAN FOO, EDDIE" },
  { department: "PROJECTS", staff: "PERNANDEZ TULIS" },
  { department: "PROJECTS", staff: "PRESCILLIA ANAK PHILIP" },
  { department: "PROJECTS", staff: "SELINBIT ANAK MOS" },
  { department: "PROJECTS", staff: "SIAW WEN YIAP, MICHEAL" },
  { department: "PROJECTS", staff: "SIEW MING HUA" },
  { department: "PROJECTS", staff: "SIEW UO SIONG" },
  { department: "PROJECTS", staff: "SIM KAY CHIAN, STEPHEN" },
  { department: "PROJECTS", staff: "SIM YIK CHWIN" },
  { department: "PROJECTS", staff: "SIM YUH SHIUN" },
  { department: "PROJECTS", staff: "SITI HAJAR BINTI MOHD YUSOP" },
  { department: "PROJECTS", staff: "SYLVESTER MIKE LIEW CHO KEAT" },
  { department: "PROJECTS", staff: "TANG SING HUI" },
  { department: "PROJECTS", staff: "TIBU ANAK DAUD" },
  { department: "PROJECTS", staff: "TING JIT JIE" },
  { department: "PROJECTS", staff: "TING SING HWA" },
  { department: "PROJECTS", staff: "TOH JAG HUI" },
  { department: "PROJECTS", staff: "TULIS ANAK CHANDANG" },
  { department: "PROJECTS", staff: "VINCENT TAN KIM POH" },
  { department: "PROJECTS", staff: "VIVIANA ONG ZHI JINN" },
  { department: "PROJECTS", staff: "WELLINGTON ANAK JACK" },
  { department: "PROPERTY", staff: "AARON AU SAU SIANG" },
  { department: "PROPERTY", staff: "ABDUL MAJID BIN KIPLI" },
  { department: "PROPERTY", staff: "ABRAHAM CHUANG-CHYI TIONG" },
  { department: "PROPERTY", staff: "ALEXANDER LAI HORNG WEN" },
  { department: "PROPERTY", staff: "AMIRUL SYAZWAN BIN SAMRA" },
  { department: "PROPERTY", staff: "ANDRESS LANTUK ANAK MAJING" },
  { department: "PROPERTY", staff: "ANGELINE ONG CHEAH JOO" },
  { department: "PROPERTY", staff: "ANGIE JOAN ANAK DANIEL" },
  { department: "PROPERTY", staff: "CHIENG CHING KUOK" },
  { department: "PROPERTY", staff: "CHONG CHIA WEI" },
  { department: "PROPERTY", staff: "CHONG SIAW HUA" },
  { department: "PROPERTY", staff: "CHRISTOPHER CHAI KOH WEE" },
  { department: "PROPERTY", staff: "CHUA YONG TIEN, EUNICE" },
  { department: "PROPERTY", staff: "CLINTON JAWA ANAK RICHARDLY" },
  { department: "PROPERTY", staff: "EFFA HELENA BINTI MOHD ARIFFIN" },
  { department: "PROPERTY", staff: "FARAHANI BINTI HAINI" },
  { department: "PROPERTY", staff: "FIODA LEW SHIN YII" },
  { department: "PROPERTY", staff: "GARY MADANG ANAK UNDOM" },
  { department: "PROPERTY", staff: "GIBSON BANYING ANAK KALIP" },
  { department: "PROPERTY", staff: "JASCICA ANAK BAJAK" },
  { department: "PROPERTY", staff: "JONG JIA XIN, LYDIA" },
  { department: "PROPERTY", staff: "JOSEPH CHAI MING KIEN" },
  { department: "PROPERTY", staff: "KATHERINE SONG" },
  { department: "PROPERTY", staff: "PHANG MING JING, RAINIE" },
  { department: "PROPERTY", staff: "PHILIBERT ROGES" },
  { department: "PROPERTY", staff: "ROCHESTER BAGOL ANAK NICHOLAS NYALAU" },
  { department: "PROPERTY", staff: "SHARON NG KHE SWYN" },
  { department: "PROPERTY", staff: "STEPHEN SUNNY ANAK RICHMOND" },
  { department: "PROPERTY", staff: "SYLVIA ANAK CHAONG" },
  { department: "PROPERTY", staff: "TAY CHIOK KEE" },
  { department: "PROPERTY", staff: "TEO TAI WEE, STEPHEN" },
  { department: "PROPERTY", staff: "TIEO SEOW LING, LYN" },
  { department: "PROPERTY", staff: "TOMMY LEE SOON MING" },
  { department: "PROPERTY", staff: "VELDA CHANG CHIA YIN" },
  { department: "PROPERTY", staff: "WINNIE LAI EE FEI" },
  { department: "PROPERTY", staff: "YVONNE SHARON ANAK MIKOLDEN" },
  { department: "QS & CONTRACT MGT", staff: "ALVIN CHONG TZE HUI" },
  { department: "QS & CONTRACT MGT", staff: "ANGELA KATHY ANAK KINTANG" },
  { department: "QS & CONTRACT MGT", staff: "BRIAN CHIN YUNG LIANG" },
  { department: "QS & CONTRACT MGT", staff: "CALVIN GARAN ANAK GOHAN" },
  { department: "QS & CONTRACT MGT", staff: "CHAI TZE LIK" },
  { department: "QS & CONTRACT MGT", staff: "CHAN HOOD SOO" },
  { department: "QS & CONTRACT MGT", staff: "DIANA LEE MEI MEI" },
  { department: "QS & CONTRACT MGT", staff: "JONG SIAW FOONG, STEVEN" },
  { department: "QS & CONTRACT MGT", staff: "JONG SUK FANG" },
  { department: "QS & CONTRACT MGT", staff: "LAI CHEAU NEE" },
  { department: "QS & CONTRACT MGT", staff: "LIM WEI SING" },
  { department: "QS & CONTRACT MGT", staff: "LING SHEAU WEI" },
  { department: "QS & CONTRACT MGT", staff: "NGO KEH TOH, ANNIE" },
  { department: "QS & CONTRACT MGT", staff: "RONIE LAU HUI LIONG" },
  { department: "QS & CONTRACT MGT", staff: "SYLVIA TANG CHUNG TIEN" },
  { department: "SAFETY & HEALTH", staff: "ALVIN LOH" },
  { department: "SAFETY & HEALTH", staff: "CALVIN ANAK MAWAR" },
  { department: "SAFETY & HEALTH", staff: "CAMILLA MARCIA KEDUAK ANAK UNYUK" },
  { department: "SAFETY & HEALTH", staff: "CHARLES LEE WEE TECK" },
  { department: "SAFETY & HEALTH", staff: "COLIN MATHEW" },
  { department: "SAFETY & HEALTH", staff: "ERIC UNTAMD ANAK MARK" },
  { department: "SAFETY & HEALTH", staff: "IAN WALTER JUNIT" },
  { department: "SAFETY & HEALTH", staff: "MOHAMAD HATTA BIN ROSIDI" },
  { department: "SAFETY & HEALTH", staff: "MUHAMMAD BIN SARIP" },
  { department: "SAFETY & HEALTH", staff: "NICHOLAS VALEN ANAK RAGIB" },
  { department: "SAFETY & HEALTH", staff: "SITI ZALEHA BINTI ZEN" },
  { department: "SAFETY & HEALTH", staff: "SO HU WEI" },
  { department: "SAFETY & HEALTH", staff: "TANG KIE CHIONG" },
  { department: "SAFETY & HEALTH", staff: "TIFFANY TAMBONG ANAK MORGAN" },
  { department: "TREASURY", staff: "BONG JOO CHEE" },
  { department: "TREASURY", staff: "DAUD BIN MAHMOR" },
  { department: "TREASURY", staff: "JENNY YU PICK HAI" },
  { department: "TREASURY", staff: "LAW DUEN SIONG" },
  { department: "TREASURY", staff: "LU KA LANG @ LU KAH NANG" },
  { department: "TREASURY", staff: "NANCY THU MEI SZE" },
  { department: "TREASURY", staff: "NG WAN LI, WENDY" },
  { department: "TREASURY", staff: "NUR ASFIA BIN MOHAMAD DOLLAH" },
  { department: "TREASURY", staff: "TAY GUAN PHING" },
  { department: "TREASURY", staff: "WEE GUEK YIAN" },
  { department: "TREASURY", staff: "WEI SZE NEI" },
  { department: "TREASURY", staff: "YUSUF BIN BAHARI" },
];

export const STAFFS = [
  { fullname: "JONG SUK FANG", position: "SENIOR QS", job_grade: "E3", date_joined: "08/04/2019",  },
  { fullname: "NGO KEH TOH, ANNIE", position: "ASSISTANT MANAGER - CONTRACTS", job_grade: "E3", date_joined: "01/11/2005",  },
  { fullname: "LIEW SZE YAH, NICOLE", position: "ADMIN ASSISTANT - PROJECTS", job_grade: "NE2", date_joined: "13/11/2017",  },
  { fullname: "LENOR JOYCE SASOY LEGASPI-BANAWA", position: "PROJECT ENGINEER", job_grade: "E2", date_joined: "08/05/2014",  },
  { fullname: "FREDERICK CABUNGAN BANAWA", position: "PROJECT MANAGER", job_grade: "M1", date_joined: "05/03/2012",  },
  { fullname: "SITI HAJAR BINTI MOHD YUSOP", position: "PROJECT ENGINEER", job_grade: "E2", date_joined: "01/09/2016",  },
  { fullname: "DORIS ANAK WILSON ELAH", position: "GENERAL CLERK", job_grade: "NE2", date_joined: "16/06/2016",  },
  { fullname: "NORSAMSUDDIN ANAK BANGUN", position: "SURVEYOR CUM SUPERVISOR", job_grade: "NES5", date_joined: "15/08/2008",  },
  { fullname: "HO AI CHIN", position: "SENIOR PROJECT ENGINEER", job_grade: "E2", date_joined: "05/12/2011",  },
  { fullname: "HENRY ANAK ATA", position: "ASSISTANT SURVEYOR", job_grade: "NES4", date_joined: "02/12/1993",  },
  { fullname: "TAN KOK SAN, NICOLE", position: "SENIOR OFFICER - PROJECT ADMIN CUM ASSISTANT QMR", job_grade: "E2", date_joined: "15/02/2008",  },
  { fullname: "STEPHANIE KHO EE FUN", position: "ASSISTANT ACCOUNTANT", job_grade: "E3", date_joined: "01/01/2024",  },
  { fullname: "YONG SERN PIAT", position: "IT MANAGER", job_grade: "M2", date_joined: "11/04/2005",  },
  { fullname: "JENNY YU PICK HAI", position: "SENIOR MANAGER - TREASURY", job_grade: "SM1", date_joined: "01/03/1995",  },
  { fullname: "TANG LING PING, JENNIFER", position: "SENIOR MANAGER - HR & CORP COMM", job_grade: "SM1", date_joined: "17/10/1995",  },
  { fullname: "TIONG TECK YIENG", position: "ASSISTANT GENERAL MANAGER - FINANCE", job_grade: "SM2", date_joined: "12/05/1997",  },
  { fullname: "TAY CHIOK KEE", position: "SENIOR MANAGER - PROCUREMENT & ADMIN", job_grade: "SM2", date_joined: "19/08/1996",  },
  { fullname: "TAY CHIOK KEE", position: "GENERAL MANAGER - PROPERTY DEVELOPMENT", job_grade: "GM1", date_joined: "19/08/1996",  },
  { fullname: "STEPHEN TAN SOON HONG", position: "MANAGER - NETWORK ADMIN", job_grade: "M1", date_joined: "05/10/1995",  },
  { fullname: "SABREE BIN SABU", position: "QA TECHNICIAN", job_grade: "NE2", date_joined: "09/08/2017",  },
  { fullname: "ELLY ANAK WILFRED", position: "ADMIN ASSISTANT", job_grade: "NE2", date_joined: "04/08/2014",  },
  { fullname: "VERONICA A/P NOED", position: "ADMIN ASSISTANT", job_grade: "NE3", date_joined: "07/06/2010",  },
  { fullname: "MARCOS ANAK WARREN SAOH", position: "ASSISTANT SUPERVISOR", job_grade: "NES4", date_joined: "01/08/2005",  },
  { fullname: "AZALIZA BINTI AMIN", position: "GENERAL CLERK", job_grade: "NE2", date_joined: "14/04/2005",  },
  { fullname: "HELMI BIN PUTEH", position: "PLANT SUPERVISOR", job_grade: "NES4", date_joined: "15/04/2005",  },
  { fullname: "NORBAYU BT MUKU", position: "QAQC SUPERVISOR", job_grade: "NES4", date_joined: "09/08/2021",  },
  { fullname: "NGUANG KIM KIONG", position: "SUPERVISOR", job_grade: "NES5", date_joined: "01/04/1994",  },
  { fullname: "JUDIRIC ALCAZAR RECIO", position: "PROJECT ENGINEER", job_grade: "E2", date_joined: "30/09/2022",  },
  { fullname: "SIM YUH SHIUN", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "03/01/2022",  },
  { fullname: "JENNIFFER JAWAI ANAK ANTHONEY LIM", position: "SITE CLERK", job_grade: "NE2", date_joined: "18/05/2020",  },
  { fullname: "KATHERINE YII JOU PEI", position: "ADMIN ASSISTANT - PROJECTS", job_grade: "NE2", date_joined: "01/08/2013",  },
  { fullname: "LIM WEI SING", position: "SENIOR QS", job_grade: "E2", date_joined: "25/05/2009",  },
  { fullname: "LAI CHEAU NEE", position: "SENOR QS", job_grade: "E2", date_joined: "16/01/2008",  },
  { fullname: "HASNIYATI HII BINTI ABDULLAH", position: "ADMIN ASSISTANT - PROJECTS", job_grade: "NE3", date_joined: "01/08/2007",  },
  { fullname: "MAGDALYNE LIZA UBANG", position: "ENVIRONMENTAL OFFICER", job_grade: "E1", date_joined: "01/01/2024",  },
  { fullname: "CAMILLA MARCIA KEDUAK ANAK UNYUK", position: "SITE SAFETY SUPERVISOR", job_grade: "NES4", date_joined: "27/07/2020",  },
  { fullname: "MUHAMMAD BIN SARIP", position: "SITE SAFETY SUPERVISOR", job_grade: "NES4", date_joined: "27/12/2016",  },
  { fullname: "TIFFANY TAMBONG ANAK MORGAN", position: "SITE SAFETY SUPERVISOR", job_grade: "NES4", date_joined: "04/10/2023",  },
  { fullname: "NICHOLAS VALEN ANAK RAGIB", position: "SITE SAFETY SUPERVISOR", job_grade: "NES4", date_joined: "09/05/2022",  },
  { fullname: "SITI ZALEHA BINTI ZEN", position: "OFFICER - SAFETY & HEALTH", job_grade: "NES6", date_joined: "19/10/2015",  },
  { fullname: "WELLINGTON ANAK JACK", position: "TRAFFIC MANAGEMENT OFFICER", job_grade: "NES4", date_joined: "14/03/2022",  },
  { fullname: "CALVIN ANAK MAWAR", position: "OFFICER - SAFETY & HEALTH", job_grade: "NES6", date_joined: "12/04/2021",  },
  { fullname: "SO HU WEI", position: "OFFICER - SAFETY & HEALTH", job_grade: "E3", date_joined: "02/04/2018",  },
  { fullname: "MOHAMAD HATTA BIN ROSIDI", position: "SITE SAFETY SUPERVISOR", job_grade: "NES4", date_joined: "01/09/1999",  },
  { fullname: "ALVIN LOH", position: "SITE SAFETY SUPERVISOR", job_grade: "NES4", date_joined: "05/06/2017",  },
  { fullname: "COLIN MATHEW", position: "OFFICER - SAFETY & HEALTH", job_grade: "NES6", date_joined: "03/01/2023",  },
  { fullname: "ERIC UNTAMD ANAK MARK", position: "SITE SAFETY SUPERVISOR", job_grade: "NES4", date_joined: "01/02/2022",  },
  { fullname: "IAN WALTER JUNIT", position: "SITE SAFETY SUPERVISOR", job_grade: "NES4", date_joined: "04/12/2023",  },
  { fullname: "TANG KIE CHIONG", position: "OFFICER - SAFETY & HEALTH", job_grade: "E3", date_joined: "02/01/2018",  },
  { fullname: "GARY MADANG ANAK UNDOM", position: "TECHNICIAN - MAINTENANCE", job_grade: "NES4", date_joined: "10/11/2022",  },
  { fullname: "AMIRUL SYAZWAN BIN SAMRA", position: "TECHNICIAN - MAINTENANCE", job_grade: "NES4", date_joined: "19/04/2021",  },
  { fullname: "PHILIBERT ROGES", position: "TECHNICIAN - MAINTENANCE", job_grade: "NES4", date_joined: "11/07/2023",  },
  { fullname: "ANGELINE ONG CHEAH JOO", position: "ASSISTANT BUILDING COORDINATOR", job_grade: "E1", date_joined: "03/06/2021",  },
  { fullname: "CLINTON JAWA ANAK RICHARDLY", position: "SENIOR OFFICER - BUILDING M & E, OPERATIONS & EVENTS", job_grade: "E2", date_joined: "01/07/2022",  },
  { fullname: "STEPHEN SUNNY ANAK RICHMOND", position: "EXECUTIVE - MARKETING & EVENT", job_grade: "NES4", date_joined: "03/04/2023",  },
  { fullname: "LAU SIE CHUNG", position: "MECHANIC", job_grade: "NES4", date_joined: "01/01/2020",  },
  { fullname: "TING SII KIONG", position: "STORE SUPERVISOR", job_grade: "NES4", date_joined: "01/01/2020",  },
  { fullname: "LEE JIE LING", position: "ADMIN ASSISTANT", job_grade: "NES4", date_joined: "05/04/2021",  },
  { fullname: "CHIN CHEE HAO", position: "SITE COORDINATOR", job_grade: "NES6", date_joined: "01/01/2021",  },
  { fullname: "LIM KIAN BENG", position: "SITE MANAGER", job_grade: "M1", date_joined: "05/07/2010",  },
  { fullname: "MUHAMMAD ALFIAN WIRA SEPTIAN", position: "ASSISTANT PROJECT MANAGER", job_grade: "E3", date_joined: "01/08/2019",  },
  { fullname: "SHIRLEY WONG LEE SAN", position: "ADMIN ASSISTANT - PROJECTS", job_grade: "E1", date_joined: "21/06/2021",  },
  { fullname: "LESTINLEY LUIS ANAK LAWAI", position: "SURVEYOR", job_grade: "NES5", date_joined: "15/03/2021",  },
  { fullname: "CHUA SIAW TED", position: "SITE MANAGER", job_grade: "M2", date_joined: "16/07/2012",  },
  { fullname: "OW WAN FOO, EDDIE", position: "ELECTRICAL ENGINEER", job_grade: "NES5", date_joined: "14/12/2015",  },
  { fullname: "MENISSA ANAK BUYONG", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "01/11/2023",  },
  { fullname: "CHANG FU BOON", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "03/01/2023",  },
  { fullname: "EVELYN ANAK ANAU", position: "CLEANER CUM TEA LADY", job_grade: "NE1", date_joined: "01/12/2022",  },
  { fullname: "ELSIE LEE SUK CHER", position: "OFFICER - PROJECT ADMIN / ASSISTANT PA", job_grade: "NES4", date_joined: "15/09/2021",  },
  { fullname: "LUI RUI YING", position: "PERSONAL ASSISTANT", job_grade: "NE2", date_joined: "04/07/2018",  },
  { fullname: "AU WAN SEE, YVONNE", position: "PERSONAL ASSISTANT CUM ADMIN OFFICER", job_grade: "NES4", date_joined: "03/03/2014",  },
  { fullname: "KO LIAN CHUENG, KENNETH", position: "SENIOR PROJECT ENGINEER", job_grade: "E2", date_joined: "01/03/2004",  },
  { fullname: "MAC AUBREY ANAK MORIS @ MICHAEL", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "08/05/2023",  },
  { fullname: "ALBERT CHUNG SWEE HENG", position: "CONSTRUCTION MANAGER", job_grade: "M2", date_joined: "03/09/2018",  },
  { fullname: "JOHAN ANAK JAWA", position: "ASSISTANT SURVEYOR", job_grade: "NES4", date_joined: "01/04/2011",  },
  { fullname: "NUR ASFIA BIN MOHAMAD DOLLAH", position: "SENIOR OFFICE ASSISTANT", job_grade: "NE2", date_joined: "16/03/2010",  },
  { fullname: "NG WAN LI, WENDY", position: "CLERK - TREASURY", job_grade: "NE2", date_joined: "18/05/2020",  },
  { fullname: "YUSUF BIN BAHARI", position: "OFFICE ASSISTANT", job_grade: "NE1", date_joined: "19/08/2016",  },
  { fullname: "DAUD BIN MAHMOR", position: "OFFICE ASSISTANT", job_grade: "NE1", date_joined: "01/10/2011",  },
  { fullname: "WEI SZE NEI", position: "ASSISTANT MANAGER - TREASURY", job_grade: "E3", date_joined: "01/09/2010",  },
  { fullname: "LU KA LANG @ LU KAH NANG", position: "OFFICER - GENERAL COORDINATOR", job_grade: "NES4", date_joined: "07/04/1997",  },
  { fullname: "LAW DUEN SIONG", position: "SENIOR CLERK - TREASURY", job_grade: "NE3", date_joined: "03/10/2005",  },
  { fullname: "WEE GUEK YIAN", position: "ASSISTANT MANAGER - TREASURY", job_grade: "NES6", date_joined: "08/10/1993",  },
  { fullname: "TAY GUAN PHING", position: "CLERK - TREASURY", job_grade: "NE3", date_joined: "05/05/2015",  },
  { fullname: "BONG JOO CHEE", position: "SENIOR OFFICER - TREASURY", job_grade: "NES5", date_joined: "05/06/2007",  },
  { fullname: "NANCY THU MEI SZE", position: "CLERK - TREASURY", job_grade: "NE2", date_joined: "01/09/2016",  },
  { fullname: "MELISA ANAK SURING", position: "SITE CLERK", job_grade: "NE1", date_joined: "10/05/2022",  },
  { fullname: "LEE PING CHIONG", position: "PROJECT MANAGER", job_grade: "M2", date_joined: "16/12/2021",  },
  { fullname: "ARIF BIN CHIK", position: "SITE COORDINATOR", job_grade: "NES4", date_joined: "17/05/2021",  },
  { fullname: "TING JIT JIE", position: "QS", job_grade: "E1", date_joined: "01/07/2020",  },
  { fullname: "TING YING SIEW", position: "ADMIN ASSISTANT - PROJECT", job_grade: "NE2", date_joined: "16/03/2012",  },
  { fullname: "TIBU ANAK DAUD", position: "SURVEYOR", job_grade: "NES5", date_joined: "16/07/2002",  },
  { fullname: "MUHAMMAD AMIRUL RIDHZUAN STANNY", position: "PROJECT ENGINEER", job_grade: "NES5", date_joined: "05/07/2010",  },
  { fullname: "SYLVIA TANG CHUNG TIEN", position: "QS", job_grade: "E1", date_joined: "03/01/2017",  },
  { fullname: "CALVIN GARAN ANAK GOHAN", position: "ASSISTANT QS", job_grade: "NES4", date_joined: "01/11/2023",  },
  { fullname: "ABDUL MAJID BIN KIPLI", position: "GRAPHIC DESIGNER", job_grade: "E1", date_joined: "18/07/2022",  },
  { fullname: "CHRISTOPHER CHAI KOH WEE", position: "GRAPHIC DESIGNER", job_grade: "E1", date_joined: "12/07/2022",  },
  { fullname: "VELDA CHANG CHIA YIN", position: "SENIOR GRAPHIC DESIGNER", job_grade: "E2", date_joined: "12/10/2020",  },
  { fullname: "ABDUL WARITH BIN MOHAMMAD PHILIP", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "01/05/2019",  },
  { fullname: "BONG KWONG NYAP", position: "ASSISTANT PROJECT MANAGER", job_grade: "E3", date_joined: "12/07/2022",  },
  { fullname: "LAI SIEW LIEN", position: "ADMIN OFFICER - PROJECTS (JASA)", job_grade: "E1", date_joined: "15/06/2020",  },
  { fullname: "LIM KAI HENG", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "15/04/2019",  },
  { fullname: "CHRIS HUDSON ANAK BUJANG", position: "DRAUGHTSMAN", job_grade: "NES4", date_joined: "08/01/2018",  },
  { fullname: "VIVIANA ONG ZHI JINN", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "03/09/2018",  },
  { fullname: "ROSELIND IKAH ANAK ROBERT @ KELIMBANG", position: "ADMIN ASSISTANT - PROJECTS (JASA)", job_grade: "NE2", date_joined: "11/12/2017",  },
  { fullname: "SIM POH KIAW, EMELYN", position: "ADMIN OFFICER - PROJECTS CUM PERSONAL ASSISTANT", job_grade: "E1", date_joined: "03/07/2017",  },
  { fullname: "TOH JAG HUI", position: "M & E COORDINATOR", job_grade: "NES5", date_joined: "19/09/2012",  },
  { fullname: "KELVIN CHAI HOCK JOI", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "06/08/2018",  },
  { fullname: "CHIANG HENG HO", position: "OFFICER - ADMIN", job_grade: "NES4", date_joined: "17/08/2009",  },
  { fullname: "TULIS ANAK CHANDANG", position: "SURVEYOR", job_grade: "NES5", date_joined: "03/06/2009",  },
  { fullname: "SELINBIT ANAK MOS", position: "SURVEYOR", job_grade: "NES6", date_joined: "16/06/2000",  },
  { fullname: "CHIN WEI KING, ERIC", position: "SITE COORDINATOR", job_grade: "NES6", date_joined: "15/02/1997",  },
  { fullname: "JESSICA ERIN ANAK BARING", position: "OFFICER - PROJECTS ADMIN", job_grade: "E1", date_joined: "25/04/2023",  },
  { fullname: "ARCHIBAL CATIBOG MANANSALA", position: "PROJECT MANAGER", job_grade: "M2", date_joined: "03/10/2011",  },
  { fullname: "ROBICA ANAK KALONG", position: "OFFICER - PROJECTS ADMIN CUM PURCHASING ASSISTANT", job_grade: "NES4", date_joined: "24/04/2019",  },
  { fullname: "DAPHNE DONNA BINTI MARTIN", position: "ADMIN ASSISTANT - PROJECTS", job_grade: "NE2", date_joined: "02/01/2018",  },
  { fullname: "CHONG YU CHIAU", position: "SITE SUPERVISOR", job_grade: "NES4", date_joined: "01/02/2015",  },
  { fullname: "LEE KUET LUNG", position: "SENIOR PROJECTS ENGINEER", job_grade: "E2", date_joined: "02/11/2009",  },
  { fullname: "CHAI CHANG LECK, ERIC", position: "ASSISTANT PROJECT MANAGER", job_grade: "NES6", date_joined: "01/04/2013",  },
  { fullname: "JIMENO JR. LUGUE JERUSALEM", position: "SENIOR PROJECT MANAGER", job_grade: "SM2", date_joined: "15/11/2019",  },
  { fullname: "SIM KAY CHIAN, STEPHEN", position: "LAB SUPERVISOR", job_grade: "NES5", date_joined: "02/05/1998",  },
  { fullname: "CHAI TZE LIK", position: "SENIOR MANAGER - CONTRACT / PROJECTS", job_grade: "SM1", date_joined: "01/11/2019",  },
  { fullname: "LIM KHIN YONG, JOHN", position: "SENIOR PROJECT MANAGER", job_grade: "SM2", date_joined: "01/05/2019",  },
  { fullname: "ALVIN CHONG TZE HUI", position: "SENIOR CONTRACTS MANAGER", job_grade: "SM1", date_joined: "01/08/2002",  },
  { fullname: "JONG SIAW FOONG, STEVEN", position: "CONTRACTS MANAGER", job_grade: "M2", date_joined: "02/04/1997",  },
  { fullname: "CHAN HOOD SOO", position: "CONTRACTS MANAGER", job_grade: "M2", date_joined: "01/11/1996",  },
  { fullname: "LAU YUK SIONG, PHILIP", position: "GENERAL MANAGER - PROJECTS", job_grade: "GM1", date_joined: "17/06/1996",  },
  { fullname: "KELVIN KANG KIAN LAI", position: "SENIOR PROJECT MANAGER", job_grade: "SM2", date_joined: "16/10/2023",  },
  { fullname: "CHIN YIAN CHON, ALSON", position: "SENIOR PROJECT MANAGER", job_grade: "SM1", date_joined: "15/05/2017",  },
  { fullname: "SIAW WEN YIAP, MICHEAL", position: "SENIOR PROJECT MANAGER", job_grade: "SM1", date_joined: "09/06/2008",  },
  { fullname: "HON JUN KHIONG", position: "PROJECT MANAGER", job_grade: "M2", date_joined: "20/11/2017",  },
  { fullname: "TING SING HWA", position: "PROJECT MANAGER", job_grade: "M2", date_joined: "17/08/2009",  },
  { fullname: "HENG MUI KEE", position: "MANAGER- OFFICE ADMIN & HEAD OF PA", job_grade: "M1", date_joined: "16/09/1993",  },
  { fullname: "CHINDAI ANAK JOSEPH", position: "DRIVER", job_grade: "NE2", date_joined: "08/06/2017",  },
  { fullname: "WONG ING SIU, THOMAS", position: "GM - COMMERCIAL", job_grade: "SM1", date_joined: "13/08/2001",  },
  { fullname: "LEE MING SWEE, JUSTIN", position: "SENIOR PROJECT MANAGER", job_grade: "SM2", date_joined: "04/04/2022",  },
  { fullname: "DIANA LEE MEI MEI", position: "SENIOR QS", job_grade: "E3", date_joined: "10/12/2018",  },
  { fullname: "SIM YIK CHWIN", position: "SENIOR PROJECT ENGINEER", job_grade: "E2", date_joined: "01/04/2002",  },
  { fullname: "GOH CHIN SING", position: "CONSTRUCTION MANAGER", job_grade: "M1", date_joined: "01/07/2020",  },
  { fullname: "VINCENT TAN KIM POH", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "03/07/2023",  },
  { fullname: "SYLVESTER MIKE LIEW CHO KEAT", position: "PROJECT ENGINEER", job_grade: "E2", date_joined: "01/04/2022",  },
  { fullname: "LAU KIEW HING", position: "OFFICER - ADMIN", job_grade: "NES4", date_joined: "12/10/2009",  },
  { fullname: "SIEW UO SIONG", position: "PROJECTS MANAGER", job_grade: "M2", date_joined: "09/02/2004",  },
  { fullname: "ANDYANNA ANAK ADRIS", position: "DRAUGHTSMAN", job_grade: "NES4", date_joined: "01/01/2023",  },
  { fullname: "MOHAMMAD AZEIR BIN UMIN", position: "SURVEYOR", job_grade: "NES5", date_joined: "02/06/1998",  },
  { fullname: "JASCICA ANAK BAJAK", position: "CUSTOMER SERVICE EXECUTIVE", job_grade: "NES4", date_joined: "15/02/2024",  },
  { fullname: "RONIE LAU HUI LIONG", position: "QS", job_grade: "E1", date_joined: "27/02/2020",  },
  { fullname: "KELVIN YII SENG HUA", position: "PROJECT ENGINEER CUM QAE", job_grade: "E1", date_joined: "02/07/2019",  },
  { fullname: "LIEW HOCK RUI", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "11/11/2019",  },
  { fullname: "KUNJAN ANAK ENGGONG", position: "SURVEYOR", job_grade: "NES5", date_joined: "01/12/2008",  },
  { fullname: "ANGELA KATHY ANAK KINTANG", position: "QUANTITY SURVEYOR CUM PROJECT EXEC.", job_grade: "E1", date_joined: "06/01/2014",  },
  { fullname: "LESLEY JENTANG ANAK JOHNNY", position: "CLERK - ACCOUNTS", job_grade: "NE2", date_joined: "05/06/2017",  },
  { fullname: "THON HUI HUI", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "19/04/2021",  },
  { fullname: "FOO HUI HUI, GRACE", position: "OFFICER - ACCOUNTS", job_grade: "NES4", date_joined: "18/05/2011",  },
  { fullname: "HELY ANAK SOTIN", position: "SENIOR CLERK - ACCOUNTS", job_grade: "NE3", date_joined: "08/03/2010",  },
  { fullname: "WEE MOI CHOO", position: "OFFICER - ACCOUNTS", job_grade: "NES4", date_joined: "22/04/1985",  },
  { fullname: "FOO HUI SIEN", position: "CLERK - ACCOUNTS CUM ADMIN", job_grade: "NE3", date_joined: "04/07/2016",  },
  { fullname: "JOSEPHINE FOO SIEW PHENG", position: "ASSISTANT ACCOUNTANT", job_grade: "E3", date_joined: "04/06/2003",  },
  { fullname: "ALIAA SYAMIMI BINTI JAMALI", position: "EXECUTIVE - ADMIN", job_grade: "E1", date_joined: "31/10/2022",  },
  { fullname: "OLIZARENA ANAK ENA", position: "GENERAL CLERK", job_grade: "NE2", date_joined: "24/10/2011",  },
  { fullname: "WAN MOHAMAD BIN WAN ALLI", position: "OFFICE ASSISTANT", job_grade: "NE1", date_joined: "16/04/2009",  },
  { fullname: "YONG SIEW TING, CONNIE", position: "ACCOUNTANT", job_grade: "M1", date_joined: "04/01/2011",  },
  { fullname: "TIONG SIEW LING", position: "ASSISTANT ACCOUNTANT", job_grade: "E3", date_joined: "01/03/2010",  },
  { fullname: "MOHD FIRAS BIN JUANDA", position: "M & E ENGINEER", job_grade: "E1", date_joined: "08/03/2023",  },
  { fullname: "PERNANDEZ TULIS", position: "M & E ENGINEER", job_grade: "NES4", date_joined: "20/09/2021",  },
  { fullname: "TANG SING HUI", position: "SITE MANAGER", job_grade: "M1", date_joined: "23/12/2003",  },
  { fullname: "JACKLYN ANAK JUGAH", position: "ADMIN ASSISTANT", job_grade: "E1", date_joined: "16/08/2023",  },
  { fullname: "FLONY MEREH ANAK DAVID", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "02/10/2023",  },
  { fullname: "DAVIDSON AK DAYUT", position: "SURVEYOR", job_grade: "NES5", date_joined: "24/06/1996",  },
  { fullname: "CHIENG SHAW YAW", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "01/08/2018",  },
  { fullname: "TEO TAI WEE, STEPHEN", position: "SITE SUPERVISOR", job_grade: "NES5", date_joined: "05/10/2006",  },
  { fullname: "CHIENG CHING KUOK", position: "SENIOR PROJECT ENGINEER", job_grade: "E2", date_joined: "01/10/2018",  },
  { fullname: "JONG JIA XIN, LYDIA", position: "SENIOR PROJECT ENGINEER", job_grade: "E2", date_joined: "12/06/2023",  },
  { fullname: "CHONG CHIA WEI", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "01/01/2024",  },
  { fullname: "HA HAW DING", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "27/06/2020",  },
  { fullname: "PRESCILLIA ANAK PHILIP", position: "ASSISTANT PROJECT PLANNER", job_grade: "E1", date_joined: "17/10/2016",  },
  { fullname: "EMILY ANAK USAM", position: "ADMIN OFFICER - PROJECTS", job_grade: "E1", date_joined: "06/03/2023",  },
  { fullname: "GIBSON BANYING ANAK KALIP", position: "OFFICER - FOOD, EVENTS & ADMIN", job_grade: "E1", date_joined: "28/12/2023",  },
  { fullname: "FIODA LEW SHIN YII", position: "ADMIN ASSISTANT", job_grade: "NES4", date_joined: "05/06/2023",  },
  { fullname: "TING SIAW WEE", position: "OFFICER - PROJECT ADMIN", job_grade: "E1", date_joined: "01/12/2010",  },
  { fullname: "MONA KEDAYA ANAK STORY", position: "ADMIN ASSISTANT - PROJECTS", job_grade: "NE2", date_joined: "10/08/2020",  },
  { fullname: "SONIA VIVIANI ANAK JANIS", position: "ADMIN ASSISTANT - PROJECTS", job_grade: "NE2", date_joined: "01/10/2018",  },
  { fullname: "SANDRA ANAK JAWING", position: "ADMIN OFFICER - PROJECTS", job_grade: "NES4", date_joined: "04/04/2016",  },
  { fullname: "DAYANG NUR AININA BINTI ABG MOHAMAD", position: "ADMIN ASSISTANT - PROJECTS", job_grade: "NE2", date_joined: "03/12/2013",  },
  { fullname: "LING FUNG ENG", position: "ADMIN OFFICER - PROJECTS", job_grade: "NES4", date_joined: "12/08/2013",  },
  { fullname: "HII CHUI YEN, IVY", position: "ADMIN ASSISTANT - PROJECTS", job_grade: "NE3", date_joined: "16/08/2010",  },
  { fullname: "VOON KHENG HUA, VALERIE", position: "ADMIN OFFICER - PROJECTS", job_grade: "NES4", date_joined: "03/07/2006",  },
  { fullname: "CHRISTIANA ROMINA ANAK JANIS", position: "ADMIN ASSISTANT", job_grade: "NE2", date_joined: "25/09/2023",  },
  { fullname: "FADILLAH BTE ABDULLAH", position: "ADMIN ASSISTANT - PROJECTS", job_grade: "NE2", date_joined: "01/10/1996",  },
  { fullname: "NAJA ANAK NYAUN", position: "OFFICER - PROJECT ADMIN", job_grade: "NES4", date_joined: "26/06/1995",  },
  { fullname: "LEE SHAW HIENG", position: "OFFICER - HR", job_grade: "E1", date_joined: "04/09/2017",  },
  { fullname: "TANG KUI SIEW, DEBBY", position: "SUPERVISOR - HR", job_grade: "E2", date_joined: "25/09/2012",  },
  { fullname: "IRWAN BIN BUJANG", position: "OFFICE ASSISTANT", job_grade: "NE1", date_joined: "25/05/2009",  },
  { fullname: "JOYCE LIM FAYE", position: "HR ASSISTANT", job_grade: "NE2", date_joined: "03/07/2023",  },
  { fullname: "VALERIE JEE JIA JIA", position: "OFFICER - HR", job_grade: "E1", date_joined: "08/01/2024",  },
  { fullname: "ADRIAN CHAI YOU QING", position: "OFFICER- HR", job_grade: "E1", date_joined: "01/03/2023",  },
  { fullname: "HO SIAW LEE, LILY", position: "OFFICER - HR", job_grade: "NES4", date_joined: "02/05/2017",  },
  { fullname: "IRENE KHO AI LING", position: "ASSISTANT MANAGER - HR", job_grade: "E3", date_joined: "20/08/2002",  },
  { fullname: "ANDRESS LANTUK ANAK MAJING", position: "ADMIN OFFICER - PROPERTY DEVELOPMENT", job_grade: "E1", date_joined: "17/05/2022",  },
  { fullname: "WINNIE LAI EE FEI", position: "OFFICER - PROPERTY DEVELOPMENT", job_grade: "E1", date_joined: "01/09/2022",  },
  { fullname: "EFFA HELENA BINTI MOHD ARIFFIN", position: "EXECUTIVE - SALES", job_grade: "E1", date_joined: "16/10/2023",  },
  { fullname: "SHARON NG KHE SWYN", position: "EXECUTIVE - SALES & MARKETING", job_grade: "E1", date_joined: "02/05/2018",  },
  { fullname: "PHANG MING JING, RAINIE", position: "EXECUTIVE - SALES", job_grade: "E1", date_joined: "12/09/2018",  },
  { fullname: "FARAHANI BINTI HAINI", position: "EXECUTIVE - SALES", job_grade: "E1", date_joined: "02/04/2012",  },
  { fullname: "TOMMY LEE SOON MING", position: "PROJECT ENGINEER", job_grade: "E1", date_joined: "01/12/2021",  },
  { fullname: "HII YII SHIN", position: "ASSISTANT SITE MANAGER", job_grade: "E3", date_joined: "14/07/2016",  },
  { fullname: "BRIAN CHIN YUNG LIANG", position: "QS", job_grade: "E1", date_joined: "01/11/2023",  },
  { fullname: "TIE SHIE LING", position: "OFFICER - PURCHASING", job_grade: "E1", date_joined: "15/11/2019",  },
  { fullname: "ABDUL AZIZ BIN HIPENI", position: "OFFICER - GENERAL COORDINATOR", job_grade: "NES4", date_joined: "01/08/2002",  },
  { fullname: "LINDA ANAK BUYONG", position: "CLERK - ADMIN", job_grade: "NE2", date_joined: "01/03/2016",  },
  { fullname: "VICTORIA ANAK UMPANG", position: "OFFICER - RECEPTION", job_grade: "NES4", date_joined: "15/04/1993",  },
  { fullname: "TAN YIEN LIN", position: "SENIOR OFFICER - ADMIN", job_grade: "NES5", date_joined: "05/06/2012",  },
  { fullname: "CHARLES REYNOLD ANAK NIGEL", position: "MANAGER - INSURANCE", job_grade: "M1", date_joined: "07/11/2022",  },
  { fullname: "CHARLES LEE WEE TECK", position: "MANAGER - SAFETY, SECURITY, HEALTH & ENVIRONMENT", job_grade: "M1", date_joined: "18/01/2016",  },
  { fullname: "TING KWAN HEE", position: "MANAGER - ADMIN CUM MALL OPERATION", job_grade: "M1", date_joined: "18/09/2000",  },
  { fullname: "NGO JIN JIN", position: "ASST MANAGER - PURCHASING", job_grade: "E3", date_joined: "09/09/2008",  },
  { fullname: "LESLIE LIM CHUNG LEONG", position: "MANAGER - OPERATIONS", job_grade: "M1", date_joined: "17/10/2005",  },
  { fullname: "ANGIE JOAN ANAK DANIEL", position: "ASSISTANT MANAGER - SALES & MARKETING", job_grade: "NES5", date_joined: "15/12/2008",  },
  { fullname: "JOSEPH CHAI MING KIEN", position: "MANAGER - PROPERTY DEVELOPMENT", job_grade: "M1", date_joined: "03/06/2021",  },
  { fullname: "TIEO SEOW LING, LYN", position: "SENIOR EXECUTIVE - SALES", job_grade: "NES5", date_joined: "14/11/2016",  },
  { fullname: "CHONG SIAW HUA", position: "ASSISTANT MANAGER - SALES ADMIN", job_grade: "NES6", date_joined: "16/02/2004",  },
  { fullname: "AARON AU SAU SIANG", position: "PROPERTY DEVELOPMENT EXECUTIVE", job_grade: "NES5", date_joined: "05/10/2023",  },
  { fullname: "LING SHEAU WEI", position: "QS", job_grade: "E1", date_joined: "09/06/2020",  },
  { fullname: "ALEXANDER LAI HORNG WEN", position: "ASSISTANT MANAGER - PROPERTY DEVELOPMENT", job_grade: "NES6", date_joined: "01/08/2016",  },
  { fullname: "LAI SIAW LING", position: "OFFICER - PURCHASING", job_grade: "E1", date_joined: "14/11/2022",  },
  { fullname: "YEO TANG TANG", position: "EXECUTIVE - OFFICE", job_grade: "NES5", date_joined: "15/05/1990",  },
  { fullname: "THIAN FUI CHIUN, KERRY", position: "SENIOR OFFICER - ADMIN", job_grade: "NES5", date_joined: "04/06/1997",  },
  { fullname: "ALBINUS PETRUS RATIM NGIBAN", position: "OFFICER - ADMIN", job_grade: "NES4", date_joined: "03/10/2011",  },
  { fullname: "BONNIE ANAK ANTHONY", position: "OFFICE ASSISTANT", job_grade: "NE1", date_joined: "11/01/2021",  },
  { fullname: "TUNI ANAK OUI JUAN", position: "OFFICE ASSISTANT", job_grade: "NE1", date_joined: "01/10/2019",  },
  { fullname: "ALI BIN SABRI", position: "OFFICE ASSISTANT", job_grade: "NE1", date_joined: "24/10/2020",  },
  { fullname: "HAIDI BIN MARANI", position: "OFFICE ASSISTANT", job_grade: "NE1", date_joined: "05/01/2015",  },
  { fullname: "ESTHER CH'NG PEI YAN", position: "OFFICER - ACCOUNTS", job_grade: "NES4", date_joined: "03/09/2019",  },
  { fullname: "CHUA YONG TIEN, EUNICE", position: "CREDIT CONTROL OFFICER - PROPERTY MANAGEMENT", job_grade: "E1", date_joined: "01/08/2022",  },
  { fullname: "NG KHOON CHIENG", position: "SENIOR OFFICER - ACCOUNTS", job_grade: "NES5", date_joined: "14/08/2003",  },
  { fullname: "AMANDA LAW LI KHING", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "18/04/2022",  },
  { fullname: "BELINDA TAN CHAI YING", position: "SENIOR CLERK - ACCOUNTS", job_grade: "NE3", date_joined: "22/07/2002",  },
  { fullname: "CHRISTABELLA DUIE ANAK CHRISTOPHER NYENGGANG", position: "CLERK - ACCOUNTS", job_grade: "NE2", date_joined: "17/02/2024",  },
  { fullname: "CHIN JIUN HUAH, ANGEL", position: "OFFICER - CORPORATE SECRETARIAL / LEGAL", job_grade: "E1", date_joined: "12/11/2018",  },
  { fullname: "TAN MIAW ENG, ANGELINE", position: "OFFICER - ACCOUNTS CUM PA TO GM", job_grade: "E1", date_joined: "10/11/2014",  },
  { fullname: "WONG WOAN MIING, RACHELLE", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "01/07/2014",  },
  { fullname: "YONG TING", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "09/07/2012",  },
  { fullname: "SIM SIAT FUNG, ALICE", position: "OFFICER - ACCOUNTS", job_grade: "NES4", date_joined: "22/08/2011",  },
  { fullname: "TAY YAI YING", position: "OFFICER - ACCOUNTS", job_grade: "NES4", date_joined: "10/10/2011",  },
  { fullname: "ANNIE LEE SIEW PHIN", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "15/01/2010",  },
  { fullname: "CHAI LI SWAN", position: "SENIOR OFFICER - ACCOUNTS", job_grade: "NES5", date_joined: "06/11/2006",  },
  { fullname: "JI CHOU KING", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "01/01/2024",  },
  { fullname: "JOYCE TEH YEE", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "15/02/2024",  },
  { fullname: "SIM PEI CHUN", position: "OFFICER - ACCOUNTS", job_grade: "NES4", date_joined: "01/04/2024",  },
  { fullname: "CHAI SIN HWEI", position: "MANAGER - ACCOUNTS", job_grade: "M1", date_joined: "03/05/2005",  },
  { fullname: "SIA SOON GUEI", position: "MANAGER - ACCOUNTS ADMIN", job_grade: "M1", date_joined: "01/11/2012",  },
  { fullname: "JACKSON CHAI KEN NAM", position: "CLERK - ACCOUNTS", job_grade: "NE2", date_joined: "09/05/2017",  },
  { fullname: "KHO BOON POH", position: "CLERK - ACCOUNTS", job_grade: "NE2", date_joined: "05/05/2011",  },
  { fullname: "ONG CHIEW LING", position: "SENIOR OFFICER - ACCOUNTS", job_grade: "E2", date_joined: "02/04/2024",  },
  { fullname: "ANG SOO HUI, SOPHIA", position: "ACCOUNTS & ADMIN ASSISTANT", job_grade: "NES4", date_joined: "06/06/2023",  },
  { fullname: "CHEN ANN NEE, ANNIE", position: "ACCOUNTS OFFICER", job_grade: "NES4", date_joined: "22/03/2023",  },
  { fullname: "CHAI PEI SHAN", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "19/09/2022",  },
  { fullname: "ONG CHIAW SIAN", position: "OFFICER - ACCOUNTS", job_grade: "NES4", date_joined: "20/10/2021",  },
  { fullname: "JACKUELINE ONG YUEN CHING", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "20/08/2022",  },
  { fullname: "LIM EK HUA", position: "MANAGER - ACCOUNTS ADMIN", job_grade: "M1", date_joined: "01/11/1992",  },
  { fullname: "JONG PEI PEI, ANNA", position: "OFFICER - ACCOUNTS", job_grade: "NES4", date_joined: "06/03/2023",  },
  { fullname: "TERRY THAM TZE PING", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "01/10/2018",  },
  { fullname: "LAW SIEW NI, TIFFANY", position: "OFFICER - ACCOUNTS", job_grade: "E1", date_joined: "03/01/2022",  },
  { fullname: "SIEW MING HUA", position: "PROJECT MANAGER", job_grade: "M2", date_joined: "01/11/2019",  },
  { fullname: "CHEE SIAW PAW", position: "OPERATIONS MANAGER", job_grade: "M1", date_joined: "01/01/2020",  },
  { fullname: "WONG MING", position: "PLANT MANAGER", job_grade: "M1", date_joined: "27/08/2001",  },
  { fullname: "DARREN JOSHUA LUNA ANAK MARVIN @ IMPI", position: "ASSISTANT OFFICER - SAFETY & HEALTH", job_grade: "NES4", date_joined: "19/09/2022",  },
  { fullname: "CHONG UU LEONG, ZACK", position: "SENIOR SALES & MARKETING EXECUTIVE", job_grade: "NES5", date_joined: "18/04/2019",  },
  { fullname: "LIM SOO HUI", position: "OFFICE ASSISTANT", job_grade: "NE3", date_joined: "22/05/2023",  },
  { fullname: "BILLY JONG HUNG ENG", position: "SALES & ADMIN COORDINATOR", job_grade: "NES4", date_joined: "10/10/2014",  },
  { fullname: "TEO KEE HUA, DORIS", position: "EXECUTIVE - MARKETING", job_grade: "NES4", date_joined: "01/09/2005",  },
  { fullname: "VOON SAY NYUK, SHIRLEY", position: "SENIOR SALES & MARKETING EXECUTIVE", job_grade: "NES5", date_joined: "05/05/2022",  },
  { fullname: "BONG IT FIAN, IVAN", position: "PLANT MANAGER - CONCRETE", job_grade: "M1", date_joined: "01/10/2021",  },
  { fullname: "CELESTINE TANG CHUNG KUI", position: "PRODUCTION ENGINEER", job_grade: "E2", date_joined: "09/10/2023",  },
  { fullname: "LING SHI HING, ERIC", position: "SITE ENGINEER", job_grade: "E1", date_joined: "02/03/2017",  },
  { fullname: "MARIA SU", position: "ADMIN ASSISTANT", job_grade: "NE1", date_joined: "02/03/2020",  },
  { fullname: "MARIA JATTI ANAK ENA", position: "ADMIN ASST / PA", job_grade: "NES4", date_joined: "26/11/2007",  },
  { fullname: "LIM WEN CHUNG, ALEX", position: "MANAGER - SALES & MARKETING", job_grade: "M1", date_joined: "18/10/2022",  },
  { fullname: "JOEL SU TAI TUNG", position: "MANAGER - QUALITY, SAFETY, HEALTH & ENVIRONMENTAL", job_grade: "M1", date_joined: "12/01/2010",  },
  { fullname: "REMY ANAK JOHEL", position: "OFFICER - QAQC CUM DOCUMENT CONTROL", job_grade: "E1", date_joined: "26/08/2013",  },
  { fullname: "LIEGAN ANAK MANGGUH", position: "ASSISTANT SUPERVISOR", job_grade: "NES4", date_joined: "05/05/2022",  },
  { fullname: "MACCOLLIN DEE DANNY ERICK", position: "QA TECHNICIAN", job_grade: "NE2", date_joined: "09/10/2017",  },
  { fullname: "PETRUS SCHENKER ANAK RABES", position: "ASSISTANT SUPERVISOR", job_grade: "NES4", date_joined: "02/10/2013",  },
  { fullname: "FABIANA ANAK CHAN", position: "GENERAL CLERK", job_grade: "NE2", date_joined: "02/07/2012",  },
  { fullname: "NOOR MARLIDAH HAI", position: "GENERAL CLERK", job_grade: "NE2", date_joined: "15/03/2011",  },
  { fullname: "JOHNNY HO KOK SIAM", position: "ASSISTANT SUPERVISOR", job_grade: "NES4", date_joined: "10/04/2023",  },
  { fullname: "SYLVESTER ANAK KENETH KANYAN", position: "QA TECHNICIAN", job_grade: "NES4", date_joined: "03/02/2022",  },
  { fullname: "BRIAN JOVY ANAK AHMEW", position: "PLANT SUPERVISOR", job_grade: "NES5", date_joined: "01/01/2018",  },
  { fullname: "MOHD. HAZRUL ALEX BIN ABDULLAH", position: "ASSISTANT SUPERVISOR", job_grade: "NES4", date_joined: "27/09/1996",  },
  { fullname: "VORNICA NAYAN ANAK NANANG", position: "GENERAL CLERK", job_grade: "NE2", date_joined: "03/01/2017",  },
  { fullname: "DIANA BIDAI ANAK MENDALI", position: "GENERAL CLERK", job_grade: "NE2", date_joined: "23/04/2007",  },
  { fullname: "CHENDANG ANAK THOMAS ALI", position: "QA TECHNICIAN", job_grade: "NE2", date_joined: "10/08/2004",  },
  { fullname: "JULLYANA GORILA ANAK JENOS", position: "ADMIN ASSISTANT", job_grade: "NE2", date_joined: "11/10/2016",  },
  { fullname: "NURUL HURAIRAH BINTI SARIP", position: "GENERAL CLERK", job_grade: "NE2", date_joined: "11/07/2016",  },
  { fullname: "BRIGETTE BRENDA BASE", position: "GENERAL CLERK", job_grade: "NE2", date_joined: "05/05/2010",  },
  { fullname: "CHIN LEE HUA", position: "ADMIN CLERK", job_grade: "NE2", date_joined: "16/05/1996",  },
  { fullname: "KHO HO SIA, GEORGE", position: "SUPERVISOR", job_grade: "NES5", date_joined: "01/09/2004",  },
  { fullname: "LESTER LUNA ANAK ULOK", position: "TECHNICAL ASSISTANT", job_grade: "NES4", date_joined: "04/06/2018",  },
  { fullname: "MAINA NICKOLA ANAK MICHILE", position: "GENERAL CLERK", job_grade: "NE2", date_joined: "05/06/2012",  },
  { fullname: "JONG KIM WONG", position: "QA TECHNICIAN", job_grade: "NE2", date_joined: "18/10/2010",  },
  { fullname: "VERONICA SULAN ANAK VINCENT", position: "QA TECHNICIAN", job_grade: "NE2", date_joined: "21/06/2010",  },
  { fullname: "NELVIN BRIAN ANAK NAWOT", position: "ASSISTANT SUPERVISOR", job_grade: "NES4", date_joined: "13/06/2023",  },
  { fullname: "EDWARD TENLEY AK FRANCIS KETIR", position: "OFFICER - SHIPPING", job_grade: "NES4", date_joined: "11/01/1995",  },
  { fullname: "YUNG MEI HUA, MANDY", position: "OFFICER - ADMIN & PURCHASING", job_grade: "E1", date_joined: "12/08/2013",  },
  { fullname: "AUDREY STECCY ANAK DOUGLAS", position: "OFFICER - SYSTEM ANALYST", job_grade: "E1", date_joined: "18/04/2022",  },
  { fullname: "GASTON QION EMMANUEL AK GEORGE", position: "SENIOR OFFICER - SOFTWARE DEVELOPER", job_grade: "NES5", date_joined: "12/12/2019",  },
  { fullname: "FAMELIA ROLLANIE ANAK RUFIE", position: "NETWORK TECHNICAL ASSISTANT", job_grade: "NES4", date_joined: "02/04/2024",  },
  { fullname: "YONG WEI TING", position: "SYSTEM ANALYST", job_grade: "E1", date_joined: "04/04/2024",  },
  { fullname: "LO JAK FATT, EVENCE", position: "SENIOR OFFICER - IT CUM ADMIN", job_grade: "NES5", date_joined: "06/06/2011",  },
  { fullname: "TAN KEENG LING", position: "OFFICER - SOFTWARE DEVELOPER", job_grade: "NES4", date_joined: "21/11/2016",  },
  { fullname: "YVONNE SHARON ANAK MIKOLDEN", position: "OFFICER - ADMIN / CORPORATE COMMUNICATIONS", job_grade: "E1", date_joined: "29/11/2021",  },
  { fullname: "ABRAHAM CHUANG-CHYI TIONG", position: "CORPORATE COMMUNICATIONS OFFICER", job_grade: "E1", date_joined: "28/08/2023",  },
  { fullname: "ROCHESTER BAGOL ANAK NICHOLAS NYALAU", position: "WRITER", job_grade: "NES4", date_joined: "02/05/2023",  },
  { fullname: "KATHERINE SONG", position: "MANAGER - DESIGN", job_grade: "M1", date_joined: "16/04/2018",  },
  { 
    fullname: "SYLVIA ANAK CHAONG", 
    position: "ADMIN OFFICER CUM PA", 
    job_grade: "NES4", 
    date_joined: "01/12/2020",  
  },

  {
    fullname: "AUGUSTINE LAW SEK HIAN",
    date_joined: "1/1/1996"
  },

  {
    fullname: "LAU KIING KANG, SIMON",
    date_joined: "1/1/1990"
  },
  
  {
    fullname: "LAWRENCE KONG",
    date_joined: "5/5/1999"
  },  


  {
    fullname: "TONY YU YUONG WEE",
    date_joined: "21/5/2003"
  },  

  {
    fullname: "WONG MING",
    date_joined: "27/8/2001"
  },  

  {
    fullname: "YU JI",
    date_joined: "11/4/2011"
  },    
];

export const COMPANY_STAFFS = [
  {staff: `JONG SUK FANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `NGO KEH TOH, ANNIE`, company: `HOCK SENG LEE BERHAD` },
{staff: `LIEW SZE YAH, NICOLE`, company: `HOCK SENG LEE BERHAD` },
{staff: `LENOR JOYCE SASOY LEGASPI-BANAWA`, company: `HOCK SENG LEE BERHAD` },
{staff: `FREDERICK CABUNGAN BANAWA`, company: `HOCK SENG LEE BERHAD` },
{staff: `SITI HAJAR BINTI MOHD YUSOP`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `DORIS ANAK WILSON ELAH`, company: `HOCK SENG LEE BERHAD` },
{staff: `NORSAMSUDDIN ANAK BANGUN`, company: `HOCK SENG LEE BERHAD` },
{staff: `HO AI CHIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `HENRY ANAK ATA`, company: `HOCK SENG LEE BERHAD` },
{staff: `TAN KOK SAN, NICOLE`, company: `HOCK SENG LEE BERHAD` },
{staff: `STEPHANIE KHO EE FUN`, company: `HOCK SENG LEE BERHAD` },
{staff: `YONG SERN PIAT`, company: `HOCK SENG LEE BERHAD` },
{staff: `TIONG TECK YIENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `JENNY YU PICK HAI`, company: `HOCK SENG LEE BERHAD` },
{staff: `TANG LING PING, JENNIFER`, company: `HOCK SENG LEE BERHAD` },
{staff: `TAY CHIOK KEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `TAY CHIOK KEE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `STEPHEN TAN SOON HONG`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: ` SABU`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `ELLY ANAK WILFRED`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `VERONICA A/P NOED`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `MARCOS ANAK WARREN SAOH`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `AZALIZA BINTI AMIN`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `HELMI BIN PUTEH`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `NORBAYU BT MUKU`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `NGUANG KIM KIONG`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `JUDIRIC ALCAZAR RECIO`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM YUH SHIUN`, company: `HOCK SENG LEE BERHAD` },
{staff: `JENNIFFER JAWAI ANAK ANTHONEY LIM`, company: `HOCK SENG LEE BERHAD` },
{staff: `KATHERINE YII JOU PEI`, company: `HOCK SENG LEE BERHAD` },
{staff: `LIM WEI SING`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAI CHEAU NEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `HASNIYATI HII BINTI ABDULLAH`, company: `HOCK SENG LEE BERHAD` },
{staff: `MAGDALYNE LIZA UBANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `CAMILLA MARCIA KEDUAK ANAK UNYUK`, company: `HOCK SENG LEE BERHAD` },
{staff: `MUHAMMAD BIN SARIP`, company: `HOCK SENG LEE BERHAD` },
{staff: `TIFFANY TAMBONG ANAK MORGAN`, company: `HOCK SENG LEE BERHAD` },
{staff: `NICHOLAS VALEN ANAK RAGIB`, company: `HOCK SENG LEE BERHAD` },
{staff: `SITI ZALEHA BINTI ZEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `WELLINGTON ANAK JACK`, company: `HOCK SENG LEE BERHAD` },
{staff: `CALVIN ANAK MAWAR`, company: `HOCK SENG LEE BERHAD` },
{staff: `SO HU WEI`, company: `HOCK SENG LEE BERHAD` },
{staff: `MOHAMAD HATTA BIN ROSIDI`, company: `HOCK SENG LEE BERHAD` },
{staff: `ALVIN LOH`, company: `HOCK SENG LEE BERHAD` },
{staff: `COLIN MATHEW`, company: `HOCK SENG LEE BERHAD` },
{staff: `ERIC UNTAMD ANAK MARK`, company: `HOCK SENG LEE BERHAD` },
{staff: `IAN WALTER JUNIT`, company: `HOCK SENG LEE BERHAD` },
{staff: `TANG KIE CHIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `GARY MADANG ANAK UNDOM`, company: `HSL LAND SDN BHD` },
{staff: `AMIRUL SYAZWAN BIN SAMRA`, company: `HSL LAND SDN BHD` },
{staff: `PHILIBERT ROGES`, company: `HSL LAND SDN BHD` },
{staff: `ANGELINE ONG CHEAH JOO`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `CLINTON JAWA ANAK RICHARDLY`, company: `HSL LAND SDN BHD` },
{staff: `STEPHEN SUNNY ANAK RICHMOND`, company: `HSL LAND SDN BHD` },
{staff: `LAU SIE CHUNG`, company: `PLUDEC TRANSPORT SDN BHD` },
{staff: `TING SII KIONG`, company: `PLUDEC TRANSPORT SDN BHD` },
{staff: `LEE JIE LING`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `CHIN CHEE HAO`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `LIM KIAN BENG`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `MUHAMMAD ALFIAN WIRA SEPTIAN`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `SHIRLEY WONG LEE SAN`, company: `HOCK SENG LEE BERHAD` },
{staff: `LESTINLEY LUIS ANAK LAWAI`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHUA SIAW TED`, company: `HOCK SENG LEE BERHAD` },
{staff: `OW WAN FOO, EDDIE`, company: `HOCK SENG LEE BERHAD` },
{staff: `MENISSA ANAK BUYONG`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `CHANG FU BOON`, company: `HOCK SENG LEE BERHAD` },
{staff: `EVELYN ANAK ANAU`, company: `HOCK SENG LEE BERHAD` },
{staff: `ELSIE LEE SUK CHER`, company: `HOCK SENG LEE BERHAD` },
{staff: `LUI RUI YING`, company: `HOCK SENG LEE BERHAD` },
{staff: `AU WAN SEE, YVONNE`, company: `HOCK SENG LEE BERHAD` },
{staff: `KO LIAN CHUENG, KENNETH`, company: `HOCK SENG LEE BERHAD` },
{staff: `MAC AUBREY ANAK MORIS @ MICHAEL`, company: `HOCK SENG LEE BERHAD` },
{staff: `ALBERT CHUNG SWEE HENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `JOHAN ANAK JAWA`, company: `HOCK SENG LEE BERHAD` },
{staff: `NUR ASFIA BIN MOHAMAD DOLLAH`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `NG WAN LI, WENDY`, company: `HOCK SENG LEE BERHAD` },
{staff: `YUSUF BIN BAHARI`, company: `HOCK SENG LEE BERHAD` },
{staff: `DAUD BIN MAHMOR`, company: `HOCK SENG LEE BERHAD` },
{staff: `WEI SZE NEI`, company: `HOCK SENG LEE BERHAD` },
{staff: `LU KA LANG @ LU KAH NANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAW DUEN SIONG`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `WEE GUEK YIAN`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `TAY GUAN PHING`, company: `KENDREDGE SDN BHD` },
{staff: `BONG JOO CHEE`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `NANCY THU MEI SZE`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `MELISA ANAK SURING`, company: `HOCK SENG LEE BERHAD` },
{staff: `LEE PING CHIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `ARIF BIN CHIK`, company: `HOCK SENG LEE BERHAD` },
{staff: `TING JIT JIE`, company: `HOCK SENG LEE BERHAD` },
{staff: `TING YING SIEW`, company: `HOCK SENG LEE BERHAD` },
{staff: `TIBU ANAK DAUD`, company: `HOCK SENG LEE BERHAD` },
{staff: `MUHAMMAD AMIRUL RIDHZUAN STANNY`, company: `MEGADUTA SDN BHD` },
{staff: `SYLVIA TANG CHUNG TIEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `CALVIN GARAN ANAK GOHAN`, company: `HOCK SENG LEE BERHAD` },
{staff: `ABDUL MAJID BIN KIPLI`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHRISTOPHER CHAI KOH WEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `VELDA CHANG CHIA YIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `ABDUL WARITH BIN MOHAMMAD PHILIP`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `BONG KWONG NYAP`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAI SIEW LIEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `LIM KAI HENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHRIS HUDSON ANAK BUJANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `VIVIANA ONG ZHI JINN`, company: `HOCK SENG LEE BERHAD` },
{staff: `ROSELIND IKAH ANAK ROBERT @ KELIMBANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM POH KIAW, EMELYN`, company: `HOCK SENG LEE BERHAD` },
{staff: `TOH JAG HUI`, company: `HOCK SENG LEE BERHAD` },
{staff: `KELVIN CHAI HOCK JOI`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIANG HENG HO`, company: `HOCK SENG LEE BERHAD` },
{staff: `TULIS ANAK CHANDANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `SELINBIT ANAK MOS`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIN WEI KING, ERIC`, company: `HOCK SENG LEE BERHAD` },
{staff: `JESSICA ERIN ANAK BARING`, company: `HOCK SENG LEE BERHAD` },
{staff: `ARCHIBAL CATIBOG MANANSALA`, company: `HOCK SENG LEE BERHAD` },
{staff: `ROBICA ANAK KALONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `DAPHNE DONNA BINTI MARTIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHONG YU CHIAU`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `LEE KUET LUNG`, company: `RED DOT TUNNELLING SDN BHD` },
{staff: `CHAI CHANG LECK, ERIC`, company: `HOCK SENG LEE BERHAD` },
{staff: `JIMENO JR. LUGUE JERUSALEM`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM KAY CHIAN, STEPHEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHAI TZE LIK`, company: `HOCK SENG LEE BERHAD` },
{staff: `LIM KHIN YONG, JOHN`, company: `HOCK SENG LEE BERHAD` },
{staff: `ALVIN CHONG TZE HUI`, company: `HOCK SENG LEE BERHAD` },
{staff: `JONG SIAW FOONG, STEVEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHAN HOOD SOO`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAU YUK SIONG, PHILIP`, company: `HOCK SENG LEE BERHAD` },
{staff: `KELVIN KANG KIAN LAI`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIN YIAN CHON, ALSON`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `SIAW WEN YIAP, MICHEAL`, company: `HOCK SENG LEE BERHAD` },
{staff: `HON JUN KHIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `TING SING HWA`, company: `HOCK SENG LEE BERHAD` },
{staff: `HENG MUI KEE`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `CHINDAI ANAK JOSEPH`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `WONG ING SIU, THOMAS`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `LEE MING SWEE, JUSTIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `DIANA LEE MEI MEI`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM YIK CHWIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `GOH CHIN SING`, company: `HOCK SENG LEE BERHAD` },
{staff: `VINCENT TAN KIM POH`, company: `HOCK SENG LEE BERHAD` },
{staff: `SYLVESTER MIKE LIEW CHO KEAT`, company: `HOCK SENG LEE BERHAD` },
{staff: `LAU KIEW HING`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIEW UO SIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `ANDYANNA ANAK ADRIS`, company: `HOCK SENG LEE BERHAD` },
{staff: `MOHAMMAD AZEIR BIN UMIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `JASCICA ANAK BAJAK`, company: `HOCK SENG LEE MANAGEMENT SERVICES SDN BHD` },
{staff: `RONIE LAU HUI LIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `KELVIN YII SENG HUA`, company: `HOCK SENG LEE BERHAD` },
{staff: `LIEW HOCK RUI`, company: `HOCK SENG LEE BERHAD` },
{staff: `KUNJAN ANAK ENGGONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `ANGELA KATHY ANAK KINTANG`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `LESLEY JENTANG ANAK JOHNNY`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `THON HUI HUI`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `FOO HUI HUI, GRACE`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `HELY ANAK SOTIN`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `WEE MOI CHOO`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `FOO HUI SIEN`, company: `PLUDEC TRANSPORT SDN BHD` },
{staff: `JOSEPHINE FOO SIEW PHENG`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `ALIAA SYAMIMI BINTI JAMALI`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `OLIZARENA ANAK ENA`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `WAN MOHAMAD BIN WAN ALLI`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `YONG SIEW TING, CONNIE`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `TIONG SIEW LING`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `MOHD FIRAS BIN JUANDA`, company: `HOCK SENG LEE BERHAD` },
{staff: `PERNANDEZ TULIS`, company: `HOCK SENG LEE BERHAD` },
{staff: `TANG SING HUI`, company: `HOCK SENG LEE BERHAD` },
{staff: `JACKLYN ANAK JUGAH`, company: `HOCK SENG LEE BERHAD` },
{staff: `FLONY MEREH ANAK DAVID`, company: `HOCK SENG LEE BERHAD` },
{staff: `DAVIDSON AK DAYUT`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIENG SHAW YAW`, company: `HOCK SENG LEE REALTY SDN BHD` },
{staff: `TEO TAI WEE, STEPHEN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `CHIENG CHING KUOK`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `JONG JIA XIN, LYDIA`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `CHONG CHIA WEI`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `HA HAW DING`, company: `HOCK SENG LEE BERHAD` },
{staff: `PRESCILLIA ANAK PHILIP`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `EMILY ANAK USAM`, company: `HOCK SENG LEE BERHAD` },
{staff: `GIBSON BANYING ANAK KALIP`, company: `HOCK SENG LEE BERHAD` },
{staff: `FIODA LEW SHIN YII`, company: `NEXT PHASE SDN BHD` },
{staff: `TING SIAW WEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `MONA KEDAYA ANAK STORY`, company: `HOCK SENG LEE BERHAD` },
{staff: `SONIA VIVIANI ANAK JANIS`, company: `HOCK SENG LEE BERHAD` },
{staff: `SANDRA ANAK JAWING`, company: `HOCK SENG LEE BERHAD` },
{staff: `DAYANG NUR AININA BINTI ABG MOHAMAD`, company: `HOCK SENG LEE BERHAD` },
{staff: `LING FUNG ENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `HII CHUI YEN, IVY`, company: `HOCK SENG LEE BERHAD` },
{staff: `VOON KHENG HUA, VALERIE`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHRISTIANA ROMINA ANAK JANIS`, company: `HOCK SENG LEE BERHAD` },
{staff: `FADILLAH BTE ABDULLAH`, company: `JENTERA CERIA SDN BHD` },
{staff: `NAJA ANAK NYAUN`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `LEE SHAW HIENG`, company: `HOCK SENG LEE BERHAD` },
{staff: `TANG KUI SIEW, DEBBY`, company: `HOCK SENG LEE BERHAD` },
{staff: `IRWAN BIN BUJANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `JOYCE LIM FAYE`, company: `HOCK SENG LEE BERHAD` },
{staff: `VALERIE JEE JIA JIA`, company: `KENDREDGE SDN BHD` },
{staff: `ADRIAN CHAI YOU QING`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `HO SIAW LEE, LILY`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `IRENE KHO AI LING`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `ANDRESS LANTUK ANAK MAJING`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `WINNIE LAI EE FEI`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `EFFA HELENA BINTI MOHD ARIFFIN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `SHARON NG KHE SWYN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `PHANG MING JING, RAINIE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `FARAHANI BINTI HAINI`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `TOMMY LEE SOON MING`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `HII YII SHIN`, company: `BORNEO PALEM LESTARI SDN BHD` },
{staff: `BRIAN CHIN YUNG LIANG`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `TIE SHIE LING`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `ABDUL AZIZ BIN HIPENI`, company: `HOCK SENG LEE BERHAD` },
{staff: `LINDA ANAK BUYONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `VICTORIA ANAK UMPANG`, company: `MEGADUTA SDN BHD` },
{staff: `TAN YIEN LIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHARLES REYNOLD ANAK NIGEL`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHARLES LEE WEE TECK`, company: `HOCK SENG LEE BERHAD` },
{staff: `TING KWAN HEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `NGO JIN JIN`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `LESLIE LIM CHUNG LEONG`, company: `BORNEO PALEM LESTARI SDN BHD` },
{staff: `ANGIE JOAN ANAK DANIEL`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `JOSEPH CHAI MING KIEN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `TIEO SEOW LING, LYN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `CHONG SIAW HUA`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `AARON AU SAU SIANG`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `LING SHEAU WEI`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `ALEXANDER LAI HORNG WEN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `LAI SIAW LING`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `YEO TANG TANG`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `THIAN FUI CHIUN, KERRY`, company: `MEGAKINA SHIPPING SDN BHD` },
{staff: `ALBINUS PETRUS RATIM NGIBAN`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `BONNIE ANAK ANTHONY`, company: `BORNEO GEOTECHNICAL ENGINEERING SDN BHD` },
{staff: `TUNI ANAK OUI JUAN`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `ALI BIN SABRI`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `HAIDI BIN MARANI`, company: `MEGAKINA SHIPPING SDN BHD` },
{staff: `ESTHER CH'NG PEI YAN`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHUA YONG TIEN, EUNICE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `NG KHOON CHIENG`, company: `KENDREDGE SDN BHD` },
{staff: `AMANDA LAW LI KHING`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `BELINDA TAN CHAI YING`, company: `MEGADUTA SDN BHD` },
{staff: `CHRISTABELLA DUIE ANAK CHRISTOPHER NYENGGANG`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHIN JIUN HUAH, ANGEL`, company: `HOCK SENG LEE BERHAD` },
{staff: `TAN MIAW ENG, ANGELINE`, company: `HOCK SENG LEE BERHAD` },
{staff: `WONG WOAN MIING, RACHELLE`, company: `HOCK SENG LEE BERHAD` },
{staff: `YONG TING`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM SIAT FUNG, ALICE`, company: `HOCK SENG LEE BERHAD` },
{staff: `TAY YAI YING`, company: `HOCK SENG LEE BERHAD` },
{staff: `ANNIE LEE SIEW PHIN`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHAI LI SWAN`, company: `HOCK SENG LEE BERHAD` },
{staff: `JI CHOU KING`, company: `HOCK SENG LEE BERHAD` },
{staff: `JOYCE TEH YEE`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIM PEI CHUN`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHAI SIN HWEI`, company: `HOCK SENG LEE BERHAD` },
{staff: `SIA SOON GUEI`, company: `PN CONSTRUCTION SDN BHD` },
{staff: `JACKSON CHAI KEN NAM`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `KHO BOON POH`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `ONG CHIEW LING`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `ANG SOO HUI, SOPHIA`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `CHEN ANN NEE, ANNIE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `CHAI PEI SHAN`, company: `HSL TRADING SDN BHD` },
{staff: `ONG CHIAW SIAN`, company: `JENTERA CERIA SDN BHD` },
{staff: `JACKUELINE ONG YUEN CHING`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `LIM EK HUA`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `JONG PEI PEI, ANNA`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `TERRY THAM TZE PING`, company: `RED DOT TUNNELLING SDN BHD` },
{staff: `LAW SIEW NI, TIFFANY`, company: `ULTRA MAJESTIC SDN BHD` },
{staff: `SIEW MING HUA`, company: `HOCK SENG LEE BERHAD` },
{staff: `CHEE SIAW PAW`, company: `PLUDEC TRANSPORT SDN BHD` },
{staff: `WONG MING`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `DARREN JOSHUA LUNA ANAK MARVIN @ IMPI`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `CHONG UU LEONG, ZACK`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `LIM SOO HUI`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `BILLY JONG HUNG ENG`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `TEO KEE HUA, DORIS`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `VOON SAY NYUK, SHIRLEY`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `BONG IT FIAN, IVAN`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `CELESTINE TANG CHUNG KUI`, company: `POH YONG CONCRETE SDN BHD` },
{staff: `LING SHI HING, ERIC`, company: `MEGAPRON ENGINEERING SDN BHD` },
{staff: `MARIA SU`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `MARIA JATTI ANAK ENA`, company: `PLUDEC STONES & CONSTRUCTION SDN BHD` },
{staff: `LIM WEN CHUNG, ALEX`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `JOEL SU TAI TUNG`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `REMY ANAK JOHEL`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `LIEGAN ANAK MANGGUH`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `MACCOLLIN DEE DANNY ERICK`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `PETRUS SCHENKER ANAK RABES`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `FABIANA ANAK CHAN`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `NOOR MARLIDAH HAI`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `JOHNNY HO KOK SIAM`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `SYLVESTER ANAK KENETH KANYAN`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `BRIAN JOVY ANAK AHMEW`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `MOHD. HAZRUL ALEX BIN ABDULLAH`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `VORNICA NAYAN ANAK NANANG`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `DIANA BIDAI ANAK MENDALI`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `CHENDANG ANAK THOMAS ALI`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `JULLYANA GORILA ANAK JENOS`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `NURUL HURAIRAH BINTI SARIP`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `BRIGETTE BRENDA BASE`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `CHIN LEE HUA`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `KHO HO SIA, GEORGE`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `LESTER LUNA ANAK ULOK`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `MAINA NICKOLA ANAK MICHILE`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `JONG KIM WONG`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `VERONICA SULAN ANAK VINCENT`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `NELVIN BRIAN ANAK NAWOT`, company: `POH YONG TILES INDUSTRIES SDN BHD` },
{staff: `EDWARD TENLEY AK FRANCIS KETIR`, company: `AF CONSTRUCTION SDN BHD` },
{staff: `YUNG MEI HUA, MANDY`, company: `HOCK SENG LEE BERHAD` },
{staff: `AUDREY STECCY ANAK DOUGLAS`, company: `HOCK SENG LEE BERHAD` },
{staff: `GASTON QION EMMANUEL AK GEORGE`, company: `HOCK SENG LEE BERHAD` },
{staff: `FAMELIA ROLLANIE ANAK RUFIE`, company: `HOCK SENG LEE BERHAD` },
{staff: `YONG WEI TING`, company: `HOCK SENG LEE BERHAD` },
{staff: `LO JAK FATT, EVENCE`, company: `HOCK SENG LEE CONSTRUCTION SDN BHD` },
{staff: `TAN KEENG LING`, company: `HOCK SENG LEE MACHINERY SDN BHD` },
{staff: `YVONNE SHARON ANAK MIKOLDEN`, company: `HOCK SENG LEE BERHAD` },
{staff: `ABRAHAM CHUANG-CHYI TIONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `ROCHESTER BAGOL ANAK NICHOLAS NYALAU`, company: `HOCK SENG LEE BERHAD` },
{staff: `KATHERINE SONG`, company: `HOCK SENG LEE BERHAD` },
{staff: `SYLVIA ANAK CHAONG`, company: `NEXT PHASE SDN BHD` },
];

export const COMPANIES = [
"AF CONSTRUCTION SDN BHD",
"BORNEO GEOTECHNICAL ENGINEERING SDN BHD",
"BORNEO PALEM LESTARI SDN BHD",
"HOCK SENG LEE BERHAD",
"HOCK SENG LEE CONSTRUCTION SDN BHD",
"HOCK SENG LEE MACHINERY SDN BHD",
"HOCK SENG LEE MANAGEMENT SERVICES SDN BHD",
"HOCK SENG LEE REALTY SDN BHD",
"HSL LAND SDN BHD",
"HSL TRADING SDN BHD",
"JENTERA CERIA SDN BHD",
"KENDREDGE SDN BHD",
"MEGADUTA SDN BHD",
"MEGAKINA SHIPPING SDN BHD",
"MEGAPRON ENGINEERING SDN BHD",
"NEXT PHASE SDN BHD",
"PLUDEC STONES & CONSTRUCTION SDN BHD",
"PLUDEC TRANSPORT SDN BHD",
"PN CONSTRUCTION SDN BHD",
"POH YONG CONCRETE SDN BHD",
"POH YONG TILES INDUSTRIES SDN BHD",
"RED DOT TUNNELLING SDN BHD",
"ULTRA MAJESTIC SDN BHD",
];
