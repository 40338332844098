import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import ImportList from "./ImportList";
import UserList from "./UserList";

import {
  updateImportedList,
  updateUserList,
} from "../../store/ImportUserSlice";
import { postError } from "../../store/ApplicationSlice";
import moment from "moment";
import "./ImportUserForm.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { Row, Col, Card, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const mstp = (state, ownProps) => {
  const list = state.importUserSlice.userList;
  return { userList: list };
};

const mdtp = (dispatch, ownProps) => {
  const _updateImportedList = (list) => dispatch(updateImportedList(list));

  const _postError = (e) => dispatch(postError(e));
  return {
    updateImportedList: _updateImportedList,

    postError: _postError,
  };
};

export default connect(
  mstp,
  mdtp
)((props) => {
  const navigate = useNavigate();
  const { userList, updateImportedList, postError } = props;
  const dispatch = useDispatch();
  const [showTip, setShowTip] = useState(false);
  const handleImport = (ev) => {
    axios
      .get(
        "https://eleave.hslnet.my/apiv2/public/index.php/employees/appraisal/listall"
      )
      .then((res) => {
        const arr = res.data
          .filter((r) => {
            return r.status?.id === 1;
          })
          .map((r) => {
            return {
              userid: r.id,
              username: r.username,
              fullname: r.fullname,
              sid: r.sid,
              // date_joined: moment(r.date_joined).valueOf(),
              date_joined: moment(r.date_joined).format('DD/MM/YYYY'),
            };
          });
        updateImportedList(arr);
      })
      .catch((e) => {
        console.log(e);
        postError(e);
      });
  };

  useEffect(() => {
    axios
      .get("https://appraisal.hslnet.my/api/users")
      .then((res) => {
        const arr = res.data;

        dispatch(updateUserList(arr));
      })
      .catch((e) => {
        postError(e);
      });
  }, []);
  const handleUpdate = (ev) => {
    if (window.confirm("Confirm update")) {
      axios
        .post("https://appraisal.hslnet.my/api/users/bulk", {
          users: userList,
        })
        .then((r) => {
          // console.log(r.data);
          alert("User(s) imported");
        })
        .catch((e) => postError(e));
    } else {
      alert("Aborted");
    }
  };
  const handleBack = (ev) => {
    navigate("../main");
  };
  return (
    <Row style={{ height: "100vh" }}>
      <Col className="d-none d-md-block col-md-1"></Col>
      <Col className="col-12 col-md-10 col-lg-9">
        <Card
          className="paper"
          style={{ margin: "0", padding: "0", border: "0" }}
        >
          <Card.Body style={{ margin: "0", padding: "0", border: "0" }}>
            <Row style={{ margin: "0" }}>
              <Card style={{ borderBottom: "1px solid black" }}>
                <Card.Body>
                  <div
                    className="d-flex justify-content-between"
                    style={{ margin: "0", padding: "0" }}
                  >
                    <div>
                      <h2>Import User</h2>
                    </div>

                    <div>
                      <button
                        className="btn btn-sm"
                        onClick={() => setShowTip(!showTip)}
                      >
                        ?
                      </button>
                    </div>
                  </div>

                  {showTip && (
                    <div>
                      <h5>Instruction</h5>
                      <ol>
                        <li>
                          Click on the{" "}
                          <Button className="btn-sm">import</Button> button in
                          the
                          <span style={{ backgroundColor: "lightgreen" }}>
                            green panel
                          </span>{" "}
                          on the bottom left to import staff lists
                        </li>
                        <li>
                          You can use the search input in the{" "}
                          <span style={{ backgroundColor: "lightgreen" }}>
                            green panel
                          </span>{" "}
                          to narrow your list
                        </li>
                        <li>
                          Select the staff in the{" "}
                          <span style={{ backgroundColor: "lightgreen" }}>
                            green panel
                          </span>{" "}
                          and click on the{" "}
                          <Button className="btn-sm" variant="info">
                            +
                          </Button>{" "}
                          button to import the staff into the system
                        </li>
                        <li>
                          You can use the{" "}
                          <Button variant="warning" className="btn-sm">
                            Add All
                          </Button>{" "}
                          to import the whole list
                        </li>

                        <li>
                          Use the User List in the{" "}
                          <span style={{ backgroundColor: "yellow" }}>
                            Yellow Panel
                          </span>{" "}
                          to remove name from the system
                        </li>

                        <li>
                          Click on the{" "}
                          <button className="btn btn-sm btn-primary">
                            Update
                          </button>{" "}
                          button to save changes.
                        </li>
                        <li>
                          Click on the{" "}
                          <button className="btn btn-sm btn-warning">
                            Back
                          </button>{" "}
                          button to return to the mainpage.
                        </li>
                      </ol>
                      <p className="text-danger">
                        Record will be uploaded to the server <u>only</u> after
                        clicking on the
                        <button className="btn btn-sm btn-primary">
                          Update
                        </button>{" "}
                        button. Any record not saved will be lost.
                      </p>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Row>
            <Row style={{ padding: "1.5em" }}>
              <Col xs={12} md={6}>
                <Card style={{ border: "1px solid black" }}>
                  <Card.Header style={{ backgroundColor: "lightgreen" }}>
                    <div className="d-flex justify-content-between">
                      <div>Imported List</div>
                      <div>
                        <Button onClick={handleImport}>import</Button>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <ImportList />
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={12} md={6}>
                <Card style={{ border: "1px solid black" }}>
                  <Card.Header style={{ backgroundColor: "yellow" }}>
                    User List
                  </Card.Header>
                  <Card.Body>
                    <UserList />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row
              style={{
                margin: "0em",
                border: "0em",
                padding: "0.5em",
                backgroundColor: "white",
                marginTop: "1em",
                borderTop: "1px solid black",
              }}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <button className="btn btn-warning" style={{ fontWeight: "bold" }} onClick={handleBack}>
                    BACK
                  </button>
                </div>
                <div>
                  <button className="btn btn-primary" style={{ fontWeight: "bold", color: "black" }} onClick={handleUpdate}>
                    UPDATE
                  </button>
                </div>
              </div>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
});
