import { QuestionNos } from "./Constants";
const Questions = [
  {
    questionNo: QuestionNos.One,
    questionTitle: "WORK QUALITY",
    question:
      "Effectively completes job assignments including accuracy, responsiveness, follow through, judgement, decision making, reliability, and compliance assurance",
    tips: [
      "Provides regular, accurate reports to management / directors.",
      "Effectively ensures quality control.",
      "Maintains efficient information/administration systems.",
    ],
  },
  {
    questionNo: QuestionNos.Two,
    questionTitle: "WORK EFFICIENCY",
    question:
      "Effectively produces the required amount of work including priority setting, productivity, and timeline.",
    tips: [
      "Sets and enforces realistic deadlines/targets, effectively priorities/organises workload ",
    ],
  },
  {
    questionNo: QuestionNos.Three,
    questionTitle: "JOB KNOWLEDGE",
    question:
      "Effectively applies professional/managerial/technical and/or non technical skills and knowledge to the job.",
    tips: [
      "Has a strong command of relevant technical skills and knowledge",
      "Possess the relevant ICT skills",
      "Keep abreast of new developments and relevant regulations",
      "Understand his role and position in the company / project organisation",
    ],
  },
  {
    questionNo: QuestionNos.Four,
    questionTitle: "COMMUNICATION SKILLS",
    question:
      "Effectively transmits information including confidentiality, facilitation / participation in sharing information, and oral and written expression.",
    tips: [
      "Provides clear directions and instructions to staffs",
      "Effectively presents ideas and obtains acceptance.",

      "Takes initiative to inform Management of any problems or potential issue that require action.",
      "Writes clearly and concisely",
    ],
  },
  {
    questionNo: QuestionNos.Five,
    questionTitle: "INTERPERSONAL SKILLS",
    question:
      "Effectively interacts with others, including co-workers, supervisor(s), customers, and/or others.",
    tips: [
      "Able to get along with colleague and others",
      "Liaises effectively at all levels, within and outside office.",
      "Is approachable by subordinates",
    ],
  },
  {
    questionNo: QuestionNos.Six,
    questionTitle: " ISO QUALITY MANAGEMENT SYSTEM (For applicable staff only)",
    question:
      "Complies to the procedures and Quality Management System within the company and as directed by the supervisor.",
    tips: [
      "Knows and understands the company's Quality Policy, overall processess and Quality Objectives relevant to own section",
      "Promotes and comply with the company's Quality Management System",
      "Contributes to the continual improvement process of the company",
    ],
  },
  {
    questionNo: QuestionNos.Seven,
    questionTitle: "ATTENDANCE",
    question: "Responsible attendance and punctuality record.",
    tips: [
      "Punctual to work",
      "Responsible use of leave entitlements",
      "Readily participates in company social activities / events",
    ],
  },
  {
    questionNo: QuestionNos.Eight,
    questionTitle:
      "SUPERVISORY SKILL (For Positions of Supervisors & Managers only)",
    question:
      "Effectively manages team, demonstrates leadership, provides direction and development.",
    tips: [
      "Ensures staff sustain productivity work",
      "Manages and motivates staff to maximise team performance",
      "Delegates fairly and effectively",
      "Identifies and develops staff capabilities",
      "Willing to share knowledge, offers guidance and counseling",
      "Is approachable by subordinates",
    ],
  },
];

export default Questions;
