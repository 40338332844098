import React, { useEffect, useState } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Button, Card, Row, Col, Table } from "react-bootstrap";

import StatusBall from "../../components/StatusBall";

// import "./DepartmentForm.css";

const SubmittedList = (props) => {
  const { name2, onBack, records } = props;

  const handleBack = (ev) => {
    onBack && onBack();
  };

  return (
    <Row>
      <Col md={1} className="d-none d-md-block"></Col>
      <Col xs={12} md={10} lg={9}>
        <Card style={{ border: "1px solid black", padding: "20px" }}>
          <Table striped style={{ border: "1px solid black" }}>
            <thead>
              <tr>
                <th rowSpan={2}>&nbsp;</th>
                <th className="h3" colSpan={4}>
                  {name2}
                </th>
              </tr>
              <tr>
                <th>&nbsp;</th>
                <th>Appraiser</th>
                <th>Proxy</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {records.map((item, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td className="h4">{item.appraisee?.fullname}</td>
                    <td>{item.appraiser?.fullname}</td>
                    <td>{item.proxy?.fullname}</td>
                    <td className="h4">
                      <StatusBall status={item.appraisal_doc?.status} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card>

        <Card>
          <Card.Body>
            <div className="d-flex justify-content-between">
              <div>
                <button style={{ fontWeight: "bold" }} className="btn btn-warning" onClick={handleBack}>
                  BACK
                </button>
              </div>
              <div></div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default SubmittedList;
